import React from "react";
import StyledTextBox from "../StyledTextBox";

const StyledCurrencyTextBox = (props: React.HTMLProps<HTMLInputElement>) => {
  let value =
    (props.value as string).replace(/^0+(?!$)/, "").replace(/^\./, "0.") ||
    "0.00";

  const onChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    let output =
      (e.currentTarget.value as string)
        .replace(/^0+(?!$)/, "")
        .replace(/^\./, "0.") || "0.00";
    e.currentTarget.value = output;
    props.onChange?.(e);
  };

  const onBlurHandler = (e: React.FormEvent<HTMLInputElement>) => {
    if (!props.value) {
      e.currentTarget.value = "0.00";
      onChangeHandler(e);
      return;
    }
    let output = props.value as string;
    if (output.match(/^\d+\.[\d\.]+$/)) {
      const decimal = output.split(".");
      output = `${decimal[0]}.${decimal[1].padEnd(2, "0").slice(0, 2)}`;
    } else if (output.match(/^\d+$/)) {
      output = `${output}.${"00"}`;
    }

    e.currentTarget.value = output;
    onChangeHandler(e);
  };

  return (
    <StyledTextBox
      {...(props as {})}
      type={"text"}
      value={value}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
    />
  );
};

export default StyledCurrencyTextBox;
