export const flattenObject = (obj: any) => {
  let output: any = {};

  for (let key in obj) {
    if (!obj.hasOwnProperty(key)) continue;

    if (typeof obj[key] == 'object' && obj[key] !== null) {
      let flatObject = flattenObject(obj[key]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        output[key + '.' + x] = flatObject[x];
      }
    } else {
      output[key] = obj[key];
    }
  }

  return output;
};

export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
