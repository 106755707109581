import React from 'react';
import styled from 'styled-components';
import * as constants from '../../constants/styledConstants';
import { useAppSelector } from '../../app/hooks';

type Statuses = 'approved' | 'canceled' | 'pending' | 'active' | 'disabled';
type StatusesMap = {
  [S in Statuses]: string;
};

const colors: StatusesMap = {
  approved: constants.colorApproved,
  active: constants.colorApproved,
  pending: constants.colorPending,
  canceled: constants.colorCanceled,
  disabled: constants.colorCanceled,
};

//These will go through the internationalization at some point

interface Props {
  $setAs: Statuses;
}
export const StyledStatus = styled.span<Props>`
  display: inline-block;
  padding: 3px 15px;
  //   border-radius: 0.3em;
  margin: 2px 2px;
  border: 2px solid rgba(0, 0, 0, 0.15);
  min-width: 10em;
  background-color: ${(props) => colors[props.$setAs]};
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
`;

interface StatusProps extends React.HTMLProps<HTMLSpanElement> {
  setAs: Statuses;
}

export function Status({ setAs, className }: StatusProps) {
  const strings = useAppSelector((state) => state.i18n.strings);

  const texts: StatusesMap = {
    approved: strings.approved,
    pending: strings.pending,
    canceled: strings.canceled,
    active: strings.active,
    disabled: strings.disabled,
  };

  return (
    <StyledStatus className={className} $setAs={setAs}>
      {texts[setAs]}
    </StyledStatus>
  );
}
