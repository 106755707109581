import styled from 'styled-components';
import * as constants from '../../constants/styledConstants';
import { useNavigate } from 'react-router-dom';

export interface MenuItemProps {
  caption: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  link?: string;
  action?: () => void;
  hide?: boolean;
  onClick?: () => void;
  iconViewBox?: string;
}

const MenuItemStyle = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  cursor: pointer;
  height: 2.5em;
  width: 100%;
  border: none;
  background-color: #fff;
  padding: 0;
  align-items: center;
  font-size: inherit;
  //   font-weight: bold;

  &:first-of-type {
    border-radius: 3px 3px 0 0;
  }
  &:last-of-type {
    border-radius: 0 0 3px 3px;
  }

  span {
    background: none;
    border: none;
    width: 100%;
    cursor: pointer;
    text-align: left;
    font-size: inherit;
    font-weight: inherit;
    padding: 1px 6px;
  }

  &:hover {
    background-color: ${constants.colorPrimary};

    .menu-item-icon svg * {
      fill: #fff;
      stroke: #fff;
    }

    span {
      color: #fff;
    }
  }

  .menu-item-icon {
    width: 20%;
    display: inline-block;
    margin-left: 5px;
    margin-right: 10px;
    height: 100%;

    svg {
      height: 100%;
      width: 100%;
      * {
        fill: ${constants.colorSecondary};
        stroke: ${constants.colorSecondary};
      }
    }
  }
`;

export default function MenuItem({ caption, link, action, onClick, icon: Icon, iconViewBox }: MenuItemProps) {
  const navigate = useNavigate();

  const clickHandler = () => {
    onClick?.();
    action?.();
    link && navigate(link);
  };

  return (
    <MenuItemStyle onClick={clickHandler}>
      <div className="menu-item-icon">{Icon && <Icon viewBox={iconViewBox || '0 0 210 220'} />}</div>
      <span>{caption}</span>
    </MenuItemStyle>
  );
}
