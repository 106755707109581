import React, { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { calEventsSelector, useAppSelector } from '../../../app/hooks';
import {
  ICalEvent,
  ITimeRange,
} from '../../../features/calendar/calendarInterfaces';
import {
  getPossibleEmptyRange,
  isDateInThePast,
} from '../../../utils/dateHelpers';
import CalItem from '../CalItem';
import HoursLabelsList from '../HoursLabelsList';
import './styles.scss';
import { isAdmin, isTrainer } from '../../../utils/accountHelpers';

export type HoursListProps = {
  workingHours: ITimeRange;
  range?: ITimeRange;
  hideHours?: boolean;
  onEmptyClick?: (range: ITimeRange) => void;
  onEventClick?: (event: ICalEvent) => void;
};

const HoursList = (props: HoursListProps) => {
  const calEvents = useAppSelector(calEventsSelector);
  const selectedTrainer = useAppSelector(
    (state) => state.calendar.selectedTrainer
  );
  const account = useAppSelector((state) => state.account);
  const { start, end } = props.workingHours;

  const startDisplay = (props.range?.start.hour() ?? start.hour()) - 2;
  const endDisplay = (props.range?.end.hour() ?? end.hour()) + 2;

  const findEvents = useCallback(
    (hour: number, calendarEvents: typeof calEvents) => {
      let timestamp = dayjs(start);
      timestamp = timestamp.hour(hour).minute(1);
      return calendarEvents.filter((ev) => timestamp.isSame(ev.start, 'hour'));
    },
    []
  );

  const eventsByHour = useMemo(() => {
    let output = [];
    for (let i = startDisplay; i <= endDisplay; i++) {
      output[i] = findEvents(i, calEvents);
    }
    return output;
  }, [calEvents, start]);

  const handleEmptyClick = (hour: number) => {
    const itemStartTimeStamp = dayjs(start)
      .hour(hour)
      .minute(0)
      .second(0)
      .millisecond(0);
    const itemEndTimeStamp = dayjs(end)
      .hour(hour)
      .minute(59)
      .second(59)
      .millisecond(999);
    const emptyRange = getPossibleEmptyRange(
      itemStartTimeStamp,
      itemEndTimeStamp,
      calEvents,
      props.workingHours
    );
    props.onEmptyClick?.(emptyRange);
  };

  const handleEventClick = (event: ICalEvent) => {
    props.onEventClick?.(event);
  };

  const hours = useMemo(() => {
    let output = [];
    for (let i = startDisplay; i <= endDisplay; i++) {
      let classes = ['hour-list-item'];
      const noWorkingHour = i < start.hour() || i > end.hour();
      const pastHour = isDateInThePast(start);
      const isUserAdmin = isAdmin(account.userData);
      const isUserTrainer =
        isTrainer(account.userData) && selectedTrainer === account.userData.id;
      const disabled =
        noWorkingHour || (!isUserAdmin && !isUserTrainer && pastHour);
      const noWorkingHalf = i === end.hour() && end.minute() < 59;
      if (disabled) {
        classes.push('disabled');
      }
      if (!disabled && noWorkingHalf) {
        classes.push('half-disabled');
      }
      let eventsInHour = eventsByHour[i];
      output.push(
        <li
          className={classes.join(' ')}
          key={`hl-${i}-${start.format('DD/MM/YYYY')}`}
          onClick={disabled ? undefined : () => handleEmptyClick(i)}
        >
          {eventsInHour.map((ev) => {
            return (
              <CalItem
                key={ev.id}
                event={ev}
                onClick={handleEventClick}
              ></CalItem>
            );
          })}
        </li>
      );
    }
    return output;
  }, [calEvents]);

  return (
    <>
      <div className="calendar-hours-wrapper">
        {!props.hideHours && (
          <HoursLabelsList
            range={props.range ?? props.workingHours}
          ></HoursLabelsList>
        )}
        <ul className="hours-list-wrapper">
          <li className="calendar-hours-title" key={Math.random().toString()}>
            {start.format('ddd DD MMMM')}
          </li>
          {hours}
        </ul>
      </div>
    </>
  );
};

export default HoursList;
