import dayjs, { Dayjs } from 'dayjs';
import { UserData } from '../features/account/accountInterfaces';
import {
  ICalEventResponse,
  IWorkingHoursData,
} from '../features/calendar/calendarInterfaces';
import { getWorkingHoursForDate } from './dateHelpers';
import type { LanguageStrings } from '../features/i18n/i18nInterfaces';
import { getStatus } from './accountHelpers';

export const validators: { [key: string]: Function } = {
  minStrlen: (str: string, expectedLen: number) => {
    return str.length >= +expectedLen;
  },
  strlen: (str: string, expectedLen: number) => {
    return str.length === +expectedLen;
  },
  isNumber: (str: string) => {
    return !isNaN(+str) && +str > 0;
  },
  regexTest: (str: string, pattern: string) => {
    return new RegExp(pattern).test(str || '');
  },
};

export const validationRulesErrorIds: { [key: string]: string } = {
  lang: 'invalidLang',
  phone: 'invalidPhone',
  address: 'invalidAdress',
  displayName: 'invalidName',
  email: 'invalidEmail',
  price: 'invalidPrice',
  'adminFields.price': 'invalidPrice',
  'userFields.phone': 'invalidPhone',
  'userFields.lang': 'invalidLang',
  'userFields.address': 'invalidAddress',
};

export const validationRules: { [key: string]: string } = {
  lang: 'strlen\t2',
  phone: 'isNumber',
  address: 'minStrlen\t3',
  price: 'isNumber',
  'adminFields.price': 'isNumber',
  'userFields.phone': 'isNumber',
  'userFields.address': 'minStrlen\t3',
  'userFields.lang': 'strlen\t2',
  displayName: 'minStrlen\t3',
  email: 'regexTest\t^[\\w-.]+@([\\w-]+.)+[\\w-]{2,4}$|^$',
};

export type Validator = (value: any) => Promise<{
  isValid: boolean;
  errorMessage: string;
}>;

export async function validateIsInWorkHours(
  timestamp: Dayjs,
  workingHours: IWorkingHoursData,
  strings: LanguageStrings
) {
  const workHours = getWorkingHoursForDate(timestamp, workingHours);
  const isValid =
    timestamp.isSame(workHours?.start) ||
    timestamp.isSame(workHours?.end) ||
    timestamp.isBetween(workHours?.start, workHours?.end);
  return {
    isValid,
    errorMessage: isValid ? '' : strings.errorOutsideWorkingHours,
  };
}

export async function validateIsUserActive(
  userData: UserData,
  strings: LanguageStrings
) {
  const isValid =
    !!userData && ['active', 'pending'].includes(getStatus(userData));
  return {
    isValid,
    errorMessage: isValid ? '' : strings.errorUserNotActive,
  };
}

export async function validateConflicts(
  start: Dayjs,
  end: Dayjs,
  allEvents: ICalEventResponse[],
  strings: LanguageStrings,
  ignoreIds?: number[]
): Promise<{
  isValid: boolean;
  errorMessage: string;
}> {
  const output = {
    isValid: true,
    errorMessage: '',
  };

  for (let i = 0; i < allEvents.length; i++) {
    const element = allEvents[i];
    if (ignoreIds?.includes(element.id)) {
      continue;
    }
    if (
      start.isSame(element.start) ||
      start.isSame(element.end) ||
      start.isBetween(element.start, element.end) ||
      dayjs(element.start).isBetween(start, end) ||
      dayjs(element.end).isBetween(start, end)
    ) {
      return {
        isValid: false,
        errorMessage: strings.errorConflictingEvent.replace(
          '{eventId}',
          element.id.toString()
        ),
      };
    }
  }

  return output;
}

export async function isNotNullish(object: any) {
  const test = object ?? false;
  return {
    isValid: !!test,
    errorMessage: !!test ? '' : 'object is nullish',
  };
}

export async function validatePriceNumber(
  value: string,
  strings: LanguageStrings
) {
  const isValid = value.match(/^[0-9]\d*(\.\d+)?$/);
  return {
    isValid: !!isValid,
    errorMessage: isValid ? '' : strings.invalidPrice,
  };
}
