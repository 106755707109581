import dayjs from 'dayjs';
import * as constants from '../../constants/styledConstants';
import { getWeekDays } from '../../utils/dateHelpers';

export const MonthStyling = `
  .datepicker-month {
    font-size: inherit;
    border-spacing: 0.1em;
    button {
      font-size: inherit;
      width: 3em;
      height: 2.5em;
      background-color: white;
      border: none;
      cursor: pointer;
      border-radius: 3px;

      &.not-current-month {
        color: lightgray;
      }

      &.today {
        color: ${constants.colorSecondary}
      }

      &.selected-date {
        background-color: ${constants.colorPrimary};
        color: white;
      }


      &:hover {
        background-color: ${constants.colorSecondary};
        color: white;
      }
    }
  }
`;

export default function Month({
  month,
  year,
  selectedDate,
  onClick,
}: {
  month: number;
  year: number;
  selectedDate: dayjs.Dayjs;
  onClick: (date: dayjs.Dayjs) => void;
}) {
  const beginsAt = new Date(year, month, 1).getDay() || 7;

  let date = dayjs(new Date(year, month, 1)).subtract(beginsAt - 1, 'days'); // getting the first date of the week the month starts in
  let days = [];
  const weeks = [];
  for (let i = 1; i <= 42; i++) {
    days.push(date);
    date = date.add(1, 'day');

    if (i % 7 === 0) {
      weeks.push(days);
      days = [];
    }
  }

  const weekdays = getWeekDays('monday');

  return (
    <table className="datepicker-month">
      <thead>
        <tr>
          {weekdays.map((day) => (
            <th>{day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {weeks.map((week: any, index: number) => {
          return (
            <tr key={index}>
              {week.map((day: dayjs.Dayjs, index: number) => {
                return (
                  <td key={index}>
                    {
                      <button
                        type="button"
                        onClick={() => onClick(day)}
                        className={
                          `${day.month() !== month ? 'not-current-month' : ''} ` +
                          `${day.isSame(selectedDate, 'day') ? 'selected-date' : ''} ` +
                          `${day.isSame(dayjs(), 'day') ? 'today' : ''} `
                        }
                      >
                        {day.date()}
                      </button>
                    }
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
