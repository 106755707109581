import { useEffect, useState } from 'react';
import { useAppSelector } from "../../app/hooks";
import { LanguageStrings } from "../../features/i18n/i18nInterfaces";

import Spinner from '../../components/Spinner';
import { Title } from '../../components/Title';

export default function Contacts({ pageKey } : { pageKey: keyof LanguageStrings }) {

  const { strings, chosenLang } = useAppSelector((state) => state.i18n);

  const [pageContent, setPageContent] = useState<string>('');

  const isLogged = !!(useAppSelector((state) => state.account) || {}).authToken;

  const loggedInClass =  isLogged ? ' logged-in' : '';

  useEffect(() => {
    fetch('/pages/' + pageKey + '.' + chosenLang + '.html')
      .then(response => response.text())
      .then(setPageContent)
      .catch(console.log);
  }, [pageKey, chosenLang]);

  return <div className={"static-page-container " + pageKey + "-page-container " + loggedInClass}>
    <Title size="mid">{strings[pageKey]}</Title>
    <Spinner show={pageContent === ''}>
      <div className={"static-page-content " + pageKey + "-page-content " + loggedInClass} dangerouslySetInnerHTML={{ __html: pageContent }} />
    </Spinner>
  </div>;
};
