import './Spinner.scss';

export default function Spinner({ show, children, asOverlay }: { show: boolean; children?: JSX.Element, asOverlay?: boolean, }) {

  const SpinnerHTML = () => (
    <div className={"spinner-container" + (asOverlay ? " as-overlay" : "")}>
      <div className="spinner-logo" />
      <div className="spinner-text" />
    </div>
  );

  if (asOverlay) {
    return (
      <>
        {children}
        {show && <SpinnerHTML />}
      </>
    );
  }

  return (
    <>
      {show ? (
        <SpinnerHTML />
      ) : (
        children
      )}
    </>
  );
}
