import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.css';

const container = document.getElementById('root')!;
const root = createRoot(container);

const env = window.location.host.split('.')[0];
const clientId =
  process.env.NODE_ENV === 'development' || env === 'dev' ? '405458099545-cqfm7v4d17ct4u14ua91j0fciqn5bbf9.apps.googleusercontent.com' : '405458099545-d2gpqmo6d7v7u9ji11jllaa1mbp49s8h.apps.googleusercontent.com';

root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={clientId}>
      <App />
    </GoogleOAuthProvider>
  </Provider>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
