import { createContext, ReactNode, useState, useContext } from 'react';
import dayjs from 'dayjs';

import Toast from './Toast';
import './styles.scss';
import { Notification, ToastType, ToastsContextType, ToastPosition } from './NotificationTypes';

const ToastsContext = createContext<ToastsContextType>(null);
interface ToastNotificationsProps {
  children: ReactNode;
  position: ToastPosition;
}

export default function ToastNotifications({ children, position }: ToastNotificationsProps) {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const pushNew = (message: string, type: ToastType) =>
    setNotifications((notifs: Notification[]) => [
      ...notifs,
      { id: dayjs().toString() + Math.random().toString(), message, type, position },
    ]);

  const removeToast = (id: string) => {
    setNotifications((notifs: Notification[]) => {
      const fnotifs = notifs.filter((notif: Notification) => notif.id !== id);

      return fnotifs.length !== notifs.length ? fnotifs : notifs;
    });
  };

  const positionToClassName = () => {
    return position.split('-').join(' ');
  };

  return (
    <>
      <ul className={`toast-notifications ${positionToClassName()}`}>
        {notifications.map((notif) => {
          return <Toast key={notif.id} notification={notif} onFaded={removeToast} />;
        })}
      </ul>
      <ToastsContext.Provider value={{ notifications, pushNew }}>{children}</ToastsContext.Provider>
    </>
  );
}

export function useToastNotifications() {
  const toastsContext = useContext(ToastsContext);
  if (!toastsContext) {
    throw new Error('useToastNotifications should be used within a ToastsNotifications wrapper');
  }
  return toastsContext;
}
