import { useState, useRef } from 'react';
import { useAsyncDebounce } from 'react-table';
import { useAppSelector } from '../../app/hooks';

export default function GlobalFilter({ table }) {
  const strings = useAppSelector((state) => state.i18n.strings);

  const {
    preGlobalFilteredRows,
    state: { globalFilter },
    setGlobalFilter,
  } = table;
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter || '');

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  const inputRef = useRef();

  const clear = () => {
    setValue('');
    onChange('');
    inputRef.current.focus();
  };

  return (
    //Should I extract this input with a clear button into its own component?
    <span className="users-table-filter" onClick={() => inputRef.current.focus()}>
      <input
        ref={inputRef}
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        onKeyDown={(e) => e.key === 'Escape' && clear()}
        placeholder={`${strings.filter} (${count} ${strings.records.toLowerCase()})`}
      />
      <button onClick={clear} hidden={value === ''}>
        X
      </button>
    </span>
  );
}
