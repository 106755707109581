import { createContext, useState, useContext, useRef } from 'react';
import { useAppSelector } from '../../app/hooks';

import Confirm from '../Modal/Confirm';

export type ConfirmationsContextType = {
  show: (dialogProps?: DialogProps) => void;
  setDefaults: (defaultProps: DialogProps) => void;
} | null;

const ConfirmationsContext = createContext<ConfirmationsContextType>(null);

interface ConfirmationDialogProps {
  children: React.ReactNode;
}

interface DialogProps {
  titleText?: string;
  buttonConfirmText?: string;
  buttonDeclineText?: string;
  onConfirm?: () => void;
  onDecline?: () => void;
}

export default function ConfirmationDialog({ children }: ConfirmationDialogProps) {
  const strings = useAppSelector((state) => state.i18n.strings);

  const [showDialog, setShowDialog] = useState(false);
  const [defaultDialogProps, setDefaultDialogProps] = useState<DialogProps>({
    titleText: 'Are you sure?',
    buttonConfirmText: strings.yes || 'Yes',
    buttonDeclineText: strings.no || 'No',
    onConfirm: () => {},
    onDecline: () => setShowDialog(false),
  });
  const currentDialogProps = useRef(defaultDialogProps);

  const setDefaults = (defaultProps: DialogProps) => {
    setDefaultDialogProps({
      ...defaultProps,
      onConfirm: () => {
        defaultProps.onConfirm?.();
        setShowDialog(false);
      },
    });
  };

  const show = (dialogProps?: DialogProps) => {
    currentDialogProps.current = { ...defaultDialogProps, ...dialogProps };
    const confirmFunc = currentDialogProps.current.onConfirm;
    currentDialogProps.current.onConfirm = () => {
      confirmFunc?.();
      setShowDialog(false);
    };

    setShowDialog(true);
  };

  return (
    <>
      <Confirm {...currentDialogProps.current} show={showDialog} />
      <ConfirmationsContext.Provider value={{ show, setDefaults }}>{children}</ConfirmationsContext.Provider>
    </>
  );
}

export function useConfirmationDialog() {
  const confirmationsContext = useContext(ConfirmationsContext);
  if (!confirmationsContext) {
    throw new Error('useConfirmationDialog should be used within a ConfirmationDialog wrapper');
  }

  return confirmationsContext;
}
