import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { FacebookLoginWithButton } from 'facebook-login-react';
import { OAuthProvider } from '../../features/account/accountInterfaces';
import { useAppSelector } from '../../app/hooks';
import { getStoredLanguage } from '../../features/i18n/i18nSlice';

interface LoginProvidersProps {
  providers: OAuthProvider[];
  page: 'login' | 'activate';
  onSuccess: (type: OAuthProvider, token: string) => void;
}

export default function LoginProviders({ providers, page, onSuccess }: LoginProvidersProps) {
  const strings = useAppSelector((state) => state.i18n.strings);

  const renderProvider = (provider: OAuthProvider) => {
    switch (provider) {
      case 'google':
        return (
          //Might replace this with a custom button similar to the facebook one.
          <GoogleLogin
            onSuccess={(credentialResponse) => onSuccess('google', credentialResponse.credential || '')}
            onError={() => {
              console.log('Login Failed');
            }}
            context={page === 'login' ? 'signin' : 'signup'}
            locale={getStoredLanguage()}
            text={'signin_with'}
            // type={'icon'}
            // shape={'square'}
            size={'large'}
          />
        );
      case 'facebook':
        return (
          <FacebookLoginWithButton
            appId="1845296315677449"
            autoLoad={false}
            fields="name,email,picture"
            onClick={() => console.log('click')}
            callback={(response: any) => {
              console.log('fb response', response);
              onSuccess('facebook', response.accessToken);
            }}
            size={'small'}
            textButton={`${page === 'login' ? strings.loginWith : strings.continueWith} Facebook`}
            icon="fa-facebook"
            buttonStyle={{ borderRadius: '3px', width: '200px', fontSize: '0.8rem' }}
          />
        );
    }
  };

  return (
    <>
      {providers.map((provider, index) => (
        <React.Fragment key={index}>{renderProvider(provider)}</React.Fragment>
      ))}
    </>
  );
}
