import { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '../Button';

const PaginationContainer = styled.div`
  bottom: 10px;
  display: block;
  align-items: center;
  padding: 0 0.5rem;
`;

export default function Pagination({ table }) {
  const {
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    state: { pageIndex },
    pageOptions,
    pageCount,
  } = table;

  useEffect(() => {
    const resizeHandler = () => {
      const container = document.querySelector('.tableWrap');
      const usableHeight = (container.clientHeight ?? 0) - 48;
      const cellHeight = 48;
      const rowsPerPage = Math.round(usableHeight / cellHeight) - 2;
      table.setPageSize(rowsPerPage);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', resizeHandler);
      resizeHandler();
      return () => window.removeEventListener('resize', resizeHandler);
    }
  }, [table]);

  return (
    <PaginationContainer>
      <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
        {'<<'}
      </Button>{' '}
      <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
        {'<'}
      </Button>{' '}
      <span>
        <strong>
          {pageIndex + 1} / {pageOptions.length}
        </strong>{' '}
      </span>
      <Button onClick={() => nextPage()} disabled={!canNextPage}>
        {'>'}
      </Button>{' '}
      <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        {'>>'}
      </Button>{' '}
      {/* <span>
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: '100px' }}
            />
          </span>{' '} */}
      {/* <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select> */}
    </PaginationContainer>
  );
}
