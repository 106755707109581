import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import * as constants from '../../constants/styledConstants';

const SlidePickerContainer = styled.div`
  position: relative;
  height: 16em;
  width: 100%;
  //   margin-left: 30px;
  font-size: 0.7em;
  -webkit-tap-highlight-color: transparent;

  &:not(:last-of-type) {
    border-right: 2px solid lightgray;
  }

  div:last-of-type {
    height: 100%;
    overflow-y: scroll;
    font-size: inherit;
    cursor: pointer;
    user-select: none;
    width: 100%;
    list-style: none;

    // Hiding Scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    // outline: 1px solid green;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
    padding: 0;
    margin: 0;
  }

  li {
    width: 100%;
    display: block;
    user-select: none;
    padding: 0;
    margin: 0 auto;
    font-size: inherit;
    height: 2em;

    display: flex;
    justify-content: center;
    align-items: center;

    @media (pointer: fine) {
      &:hover {
        background-color: ${constants.colorSecondary}55;
      }
    }
  }

  h5 {
    position: absolute;
    top: 0;

    width: 100%;
    font-weight: bold;
    background-color: #ebeaea;
    margin: 0;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Selection = styled.div`
  position: absolute;
  background-color: #ffa44529;
  border-top: 1px solid ${constants.colorPrimary};
  border-bottom: 1px solid ${constants.colorPrimary};
  height: 2.5em;
  width: 100%;
  left: 0;
  right: 0;
  top: calc(50%);
  transform: translateY(-50%);
  pointer-events: none;
`;

interface State {
  container: HTMLDivElement;
  list: HTMLElement;
  segmentHeight: number;
  moveToSegment: (index: number, smooth: boolean) => void;
}

interface Props {
  title: string;
  values: string[];
  defaultValue: string;
  onValueChange: (value: string) => void;
}

let timer: NodeJS.Timeout;
const onScrollStop = (delay: number, func: Function) => {
  if (timer !== null) {
    clearTimeout(timer);
  }
  timer = setTimeout(function () {
    func();
  }, delay);
};

export default function SlidePicker({ title, values, defaultValue, onValueChange }: Props) {
  const [state, setState] = useState<State | null>(null);
  const dragData = useRef<{ scrollTop: number; mouseY: number } | null>(null);
  const touchDown = useRef(false);
  const defaultIndex = Math.max(values.indexOf(defaultValue), 0);
  const isScrolling = useRef(false);

  const containerRef = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        const container = node;
        const list = container?.firstChild as HTMLElement;
        const segmentHeight = (list?.firstChild as HTMLElement)?.getBoundingClientRect().height;

        const moveToSegment = (index: number, smooth: boolean) => {
          if (smooth) container.style.scrollBehavior = 'smooth';

          container.scrollTop = segmentHeight * (index + 0.5);
          container.style.removeProperty('scroll-behavior');
        };

        setState({ container, list, segmentHeight, moveToSegment });

        list.style.height = `${(state?.segmentHeight || 0) * values.length + (state?.container?.clientHeight || 0)}px`;

        moveToSegment(defaultIndex, false);
      }
    },
    [defaultIndex, state?.container?.clientHeight, state?.segmentHeight, values.length]
  );

  const mouseDownHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) => {
    if (state?.container) {
      state?.container.style.removeProperty('scroll-behavior');

      dragData.current = {
        scrollTop: state?.container.scrollTop || 0,
        mouseY: 'clientY' in e ? e.clientY : e.touches[0].clientY,
      };

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
      document.addEventListener('touchend', mouseUpHandler);
    }
    touchDown.current = true;
  };

  const mouseUpHandler = (e: any) => {
    if (!state?.container) return;
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
    document.removeEventListener('touchend', mouseUpHandler);
    touchDown.current = false;
    if (e.target.nodeName === 'LI' && !isScrolling.current) {
      state?.moveToSegment(parseInt(e.target.dataset.index), true);
    }
    state.container.style.scrollBehavior = 'smooth';
    onScrollStop(100, snapToSegment);
  };

  const mouseMoveHandler = (e: MouseEvent | TouchEvent) => {
    if (!dragData.current || !state?.container) return;
    const clientY = 'clientY' in e ? e.clientY : e.touches[0].clientY;
    state.container.scrollTop = dragData.current?.scrollTop - (clientY - dragData.current?.mouseY);
  };

  const onScroll = (e: any) => {
    if (!state?.container) return;
    isScrolling.current = touchDown.current;

    onScrollStop(100, snapToSegment);
  };

  const snapToSegment = () => {
    if (!state?.container || touchDown.current) return;

    isScrolling.current = false;
    let index = Math.floor((state?.container.scrollTop || 0.1) / state?.segmentHeight);
    index = Math.min(Math.max(index, 0), values.length - 1);
    state.container.scrollTop = state?.segmentHeight * (index + 0.5);

    const newValue = values[index];
    onValueChange(newValue);
  };

  return (
    <>
      <SlidePickerContainer>
        <Selection style={{ height: state?.segmentHeight }} />
        <h5>{title}</h5>
        <div onScroll={onScroll} onMouseDown={mouseDownHandler} ref={containerRef} onTouchStart={mouseDownHandler}>
          <ul>
            {values.map((value, index) => (
              <li key={index} data-index={index}>
                {value}
              </li>
            ))}
          </ul>
        </div>
      </SlidePickerContainer>
    </>
  );
}
