import React from 'react';
import type { TabId } from '.';
import { useAppSelector } from '../../app/hooks';

export type Tab = {
  id: TabId;
};

export type TabControlsProps = {
  tabs: Tab[];
  selected: TabId;
  onTabClick: (tab: Tab) => void;
};

export default function TabControls(props: TabControlsProps) {
  const strings = useAppSelector((state) => state.i18n.strings);
  const { tabs, onTabClick, selected } = props;
  return (
    <div className="create-user-controls">
      <ul>
        {tabs.map((tab, index) => (
          <li
            className={tab.id === selected ? 'selected' : ''}
            key={`create-tab-${tab.id}`}
            onClick={() => onTabClick(tab)}
          >
            {strings[`tabName_${tab.id}` as keyof typeof strings]}
          </li>
        ))}
      </ul>
    </div>
  );
}
