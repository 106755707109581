import dayjs from 'dayjs';
import * as constants from '../../constants/styledConstants';

export const MonthSelectorStyling = `
  .datepicker-months{
    font-size: inherit;
    button {
      font-size: inherit;
      width: 7em;
      height: 4em;
      background-color: white;
      border: none;
      cursor: pointer;
      border-radius: 3px;

      &.selected-month {
        background-color: ${constants.colorPrimary};
        color: white;
      }

      &:hover {
        background-color: ${constants.colorSecondary};
        color: white;
      }
    }
  }  
`;

const getMonths = () => {
  const getMonthName = (ind: number) => dayjs(`2000.${ind}.01`).format('MMM');

  const monthsGrid: Array<string[]> = [];
  for (let y = 0; y < 4; y++) {
    const row: string[] = [];
    for (let x = 0; x < 3; x++) {
      row.push(getMonthName(x + 1 + y * 3));
    }
    monthsGrid.push(row);
  }
  return monthsGrid;
};

export default function MonthSelector({ workingMonth, onMonthSelect }: { workingMonth: string; onMonthSelect: (month: number) => void }) {
  const monthsGrid = getMonths();

  return (
    <table className="datepicker-months">
      <tbody>
        {monthsGrid.map((row, rowInd) => {
          return (
            <tr key={rowInd}>
              {row.map((month, ind) => {
                return (
                  <td key={ind}>
                    <button className={month === workingMonth ? 'selected-month' : ''} onClick={() => onMonthSelect(rowInd * 3 + ind)}>
                      {month}
                    </button>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
