import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  getUser,
  getUsers,
  updateUserStatus,
  resetPassword,
} from '../../features/account/accountSlice';
import { UserData } from '../../features/account/accountInterfaces';
import './Admin.css';
import UsersTable from '../../components/UsersTable';
import CreateUser from '../../components/CreateUserForm';
import EditUser from '../../components/EditUserForm';
import Modal from '../../components/Modal/Modal';
import SlideInComponent from '../../components/SlideInComponent';
import { Title } from '../../components/Title';
import { Fields } from '../../features/account/accountInterfaces';
import allPossibleUserFields from '../../constants/userFields.json';
import allPossibleAdminFields from '../../constants/adminFields.json';
import ViewUserProfile from '../../components/ViewUserProfile';
import { STATUS_IDS } from '../../constants/globalConstants';
import Confirm from '../../components/Modal/Confirm';
import { getRoles } from '../../features/metadata/metadataSlice';

export default function Admin() {
  const account = useAppSelector((state) => state.account);
  const strings = useAppSelector((state) => state.i18n.strings);
  const dispatch = useAppDispatch();
  const [userId, setUserId] = useState('');

  const [showNewUserDialog, setShowNewUserDialog] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showViewUserModal, setShowViewUserModal] = useState(false);
  const [confirmActionData, setConfirmActionData] = useState<{
    question: string;
    action: () => void;
  } | null>(null);

  const closeEditUserModalRef = useRef<Function | null>(null);

  const { selectedUser } = account;
  const closeEditUserModal = () => {
    if (closeEditUserModalRef && closeEditUserModalRef.current) {
      closeEditUserModalRef.current();
    }
  };

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getRoles());
  }, [dispatch]);

  useEffect(() => {
    if (!userId) {
      return;
    }
    setShowViewUserModal(true);
    dispatch(getUser({ userId }));
  }, [dispatch, userId]);

  const onUserSelected = (userId: string) => {
    setUserId(userId);
  };

  // when admin saves user settings successfully - close the edit user modal ..
  useEffect(() => {
    if (account.updateFieldsStatus === 'success' && userId) {
      closeEditUserModal();
    }
  }, [account.updateFieldsStatus, account.userData.userFields?.lang, dispatch]);

  // sum user & admin fields, so when we pass it to form - it'll show inputs for all of them ..
  let allUserFields: Fields = {};
  if (userId) {
    const allUserFieldsData: Fields = Object.assign(
      {},
      (selectedUser?.userFields || {}) as Fields,
      (selectedUser?.adminFields || {}) as Fields
    );
    [...allPossibleUserFields, ...allPossibleAdminFields].forEach(
      (onePossibleFieldName) => {
        allUserFields[onePossibleFieldName] =
          allUserFieldsData[onePossibleFieldName] || '';
      }
    );
  }

  const handleDelete = (userInfo: UserData) => {
    setConfirmActionData({
      question: strings.confirmAccountDisable,
      action: () => {
        dispatch(
          updateUserStatus({
            userId: userInfo.id || '',
            statusId: STATUS_IDS.disabled,
          })
        );
        setConfirmActionData(null);
        setShowViewUserModal(false);
      },
    });
  };

  const handleRestore = (userInfo: UserData) => {
    setConfirmActionData({
      question: strings.confirmAccountRestore,
      action: () => {
        dispatch(
          updateUserStatus({
            userId: userInfo.id || '',
            statusId:
              userInfo.authType === 'null'
                ? STATUS_IDS.pending
                : STATUS_IDS.active,
          })
        );
        setConfirmActionData(null);
        setShowViewUserModal(false);
      },
    });
  };

  const handleResetPass = (userInfo: UserData) => {
    setConfirmActionData({
      question: strings.confirmPasswordReset,
      action: () => {
        dispatch(
          resetPassword({
            userId: userInfo.id || '',
          })
        );
        setConfirmActionData(null);
        setShowViewUserModal(false);
      },
    });
  };

  return (
    <div className="admin-panel flex-col">
      <UsersTable onUserSelect={onUserSelected} />
      <SlideInComponent
        expanded={showNewUserDialog}
        onClose={setShowNewUserDialog}
      >
        <Title size="big">{strings.newUser}</Title>
        <CreateUser onSuccess={() => setShowNewUserDialog(false)} />
      </SlideInComponent>

      <SlideInComponent
        expanded={showEditUserModal}
        onClose={setShowEditUserModal}
      >
        <Title size="big">{strings.editUser}</Title>
        <EditUser
          onSuccess={() => setShowEditUserModal(false)}
          user={selectedUser || {}}
        />
      </SlideInComponent>

      <Modal
        show={showViewUserModal && !!selectedUser}
        titleText={selectedUser?.displayName}
        onClose={() => {
          setShowViewUserModal(false);
          setUserId('');
        }}
      >
        <ViewUserProfile
          userData={selectedUser as UserData}
          onEdit={(userdata) => {
            setShowEditUserModal(true);
            setShowViewUserModal(false);
            setUserId('');
          }}
          onDelete={handleDelete}
          onRestore={handleRestore}
          onResetPass={handleResetPass}
        />
      </Modal>

      {/* <Modal
        show={showEditUserModal}
        titleText={
          account.userList.find((user) => user.id === userId)?.displayName
        }
        onClose={() => {
          setShowEditUserModal(false);
          setUserId('');
        }}
        referenceToCloseModal={closeEditUserModalRef}
      >
        <Spinner
          show={!selectedUser || account?.updateFieldsStatus === 'pending'}
        >
          <CustomFieldsForm
            customFields={allUserFields}
            onSubmit={(inputValues: { [key: string]: string }) => {
              const userFields: Fields = {},
                adminFields: Fields = {};
              allPossibleUserFields.forEach((oneUserField) => {
                userFields[oneUserField] = inputValues[oneUserField];
              });
              allPossibleAdminFields.forEach((oneAdminField) => {
                adminFields[oneAdminField] = inputValues[oneAdminField];
              });
              dispatch(
                updateFields({
                  userFields,
                  adminFields,
                  idOfUser: userId,
                })
              );
              // closeEditUserModal();
            }}
            strings={strings}
          />
        </Spinner>
      </Modal> */}
      {/* <NewUserDialog show={showNewUserDialog} onClose={() => setShowNewUserDialog(false)} /> */}
      <button
        onClick={() => setShowNewUserDialog(true)}
        className="new-user-button"
      >
        +
      </button>

      <Confirm
        show={!!confirmActionData}
        onDecline={() => setConfirmActionData(null)}
        titleText={confirmActionData?.question}
        onConfirm={confirmActionData?.action || (() => {})}
        buttonConfirmText={strings.yes}
        buttonDeclineText={strings.no}
      />
    </div>
  );
}
