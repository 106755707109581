import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Modal from '../Modal/Modal';
import CustomFieldsForm from './CustomFieldsForm';
import { updateFields } from '../../features/account/accountSlice';
import Spinner from '../Spinner';
import { useEffect, useRef } from 'react';
import { setLanguage } from '../../features/i18n/i18nSlice';
import { SupportedLanguages } from '../../features/i18n/i18nInterfaces';
import allPossibleFormFields from '../../constants/userFields.json';
import { Fields } from '../../features/account/accountInterfaces';

interface CustomFieldsModalProps {
  onClose?: () => void;
  title: string;
  show: boolean;
}

export default function CustomFieldsModal({ onClose, title, show }: CustomFieldsModalProps) {
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.account);
  const strings = useAppSelector((state) => state.i18n.strings);
  const referenceToCloseModal = useRef<Function | null>(null);
  const closeModalFromParent = () => {
    if (referenceToCloseModal && referenceToCloseModal.current) {
      referenceToCloseModal.current();
    }
  };

  // when user saves settings successfully - close the modal ..
  useEffect(() => {
    if (account.updateFieldsStatus === 'success' && account.userData.userFields?.lang) {
      dispatch(setLanguage(account.userData?.userFields.lang as SupportedLanguages));
      closeModalFromParent();
    }
  }, [account.updateFieldsStatus, account.userData.userFields?.lang, dispatch]);

  // prepare the final object with all possible fields, with the data inside ..
  // so that CustomFieldsForm can directly use it ..
  let allUserFields: Fields = {};

  if (show) {
    allPossibleFormFields.forEach((onePossibleFieldName) => {
      allUserFields[onePossibleFieldName] = (account.userData.userFields as any)[onePossibleFieldName] || '';
    });
  }

  return (
    <Modal onClose={() => onClose?.()} titleText={title} referenceToCloseModal={referenceToCloseModal} show={show}>
      <CustomFieldsForm
        customFields={allUserFields}
        onSubmit={(inputValues: { [key: string]: string }) => {
          dispatch(
            updateFields({
              userFields: inputValues,
              idOfUser: account?.userData?.id,
            })
          );
        }}
        strings={strings}
      />
      <Spinner show={account.updateFieldsStatus === 'pending'} asOverlay={true}></Spinner>
    </Modal>
  );
}
