import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { clearError } from '../../features/error/errorSlice';
import { Button } from '../Button';
import Modal from '../Modal/Modal';
import styled from 'styled-components';
import * as constants from '../../constants/styledConstants';

const ErrorDialogContainer = styled.div`
  .modal-bg {
    z-index: 1000;
  }
  .modal {
    z-index: 1001;
    position: 'fixed';
    pointer-events: 'none';
    border: 2px solid ${constants.colorDanger};
    box-shadow: 0 0 5px 1px ${constants.colorDanger};
  }

  .modal-content {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    white-space: pre-wrap;
    overflow: hidden;
  }

  .error-dialog-btn {
    width: 5em;
  }
`;

export default function ErrorDialog() {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const strings = useAppSelector((state) => state.i18n.strings);
  const error = useAppSelector((state) => state.error.data);
  const dispatch = useAppDispatch();

  useEffect(() => {
    function isOfType(value: string): value is keyof typeof strings {
      return Object.keys(strings).includes(value);
    }

    if (error) {
      setShow(true);
      dispatch(clearError());

      const titleString = `${error.errorCode}-Title`;
      if (isOfType(titleString)) {
        setTitle(strings[titleString]);
      } else {
        setTitle('Error'); //Fallback if we don't have the error title in the language strings
      }

      const messageString = `${error.errorCode}-Message`;
      if (isOfType(messageString)) {
        setMessage(strings[messageString]);
      } else {
        setMessage(`Error "${error.errorCode}" - ${error.errorMessage}`); //Fallback if we don't have the error message in the language strings
      }
    }
  }, [dispatch, error, strings]);

  const closeHandler = () => {
    setShow(false);
  };

  return (
    <ErrorDialogContainer className="error-dialog-container">
      <Modal titleText={title} show={show} onClose={closeHandler} noCloseIcon={true}>
        {message}
        <Button kind="outlined" onClick={closeHandler} className="error-dialog-btn">
          OK
        </Button>
      </Modal>
    </ErrorDialogContainer>
  );
}
