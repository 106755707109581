import { useEffect, useState } from 'react';
import { useLocalStorage } from '../../app/hooks';
import { pwa } from '../../utils/pwa';
import { Button } from '../Button';
import { useAppSelector } from '../../app/hooks';
import './styles.scss';
import PWAPrompt from 'react-ios-pwa-prompt';

export default function PWABanner() {
  const [dismissed, setDismissed] = useLocalStorage('pwa-dismissed', false); // This is only for non-iOS. The iOS is handled by PWAPrompt component
  const [canAppInstall, setCanAppInstall] = useState(pwa.canInstall());
  const [forceShow, setForceShow] = useState(false);
  const strings = useAppSelector((state) => state.i18n.strings);

  useEffect(() => {
    pwa.init(() => setCanAppInstall(pwa.canInstall()));

    const onInstall = (e: PWAInstallEvent) => {
      if (e.detail.status === 'installed') {
        setCanAppInstall(false);
      }

      if (e.detail.status === 'initiated' && pwa.environment() === 'iOS') {
        setForceShow(true);
      }
    };

    window.addEventListener('install', onInstall);

    return () => {
      window.removeEventListener('install', onInstall);
    };
  }, []);

  if (dismissed || !canAppInstall) return null;

  const install = () => {
    pwa.install();
  };

  const dismiss = () => {
    setDismissed(true);
  };

  const renderPrompt = () => {
    switch (pwa.environment()) {
      case 'Android':
        return (
          <div className="pwa-banner">
            <span>{strings.installPrompt}</span>
            <div className="pwa-buttons">
              <Button onClick={install}>{strings.install}</Button>
              <Button onClick={dismiss}>{strings.notNow}</Button>
            </div>
          </div>
        );
      case 'iOS':
        return (
          <PWAPrompt
            debug={forceShow}
            onClose={() => setForceShow(false)}
            copyTitle={strings.installTitle}
            copyBody={strings.installPrompt}
            copyShareButtonLabel={strings.installStep1}
            copyAddHomeButtonLabel={strings.installStep2}
            copyClosePrompt={strings.cancel}
          />
        );
      default:
        return null;
    }
  };

  return renderPrompt();
}
