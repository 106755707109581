import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { Link } from "react-router-dom";
import './styles.scss';

export default function Footer({isLogged}: { isLogged: boolean }) {

  const navigate = useNavigate();

  const footerDiv = useRef<HTMLDivElement>(null);

  const { strings } = useAppSelector((state) => state.i18n);

  const toggleLinksOnMobile = (e: React.MouseEvent<HTMLButtonElement>) => ((e.currentTarget as HTMLButtonElement).closest('.global-footer') as HTMLElement).classList.toggle('visible-links');

  const collapseMobileFooter = () => {
    if (window.innerWidth < 768 && footerDiv.current) {
      footerDiv.current.classList.remove('visible-links');
    }
  }

  const onSettingButtonClick = () => {
    if (isLogged) {
      window.dispatchEvent(new CustomEvent('show-settings-modal'));
    } else {
      navigate('/');
    }
    collapseMobileFooter();
  };

  return <div className="global-footer" ref={footerDiv}>
    {isLogged && (
    <div className="links-wrapper">
      <Link to="/" onClick={collapseMobileFooter}>{strings['home']}</Link>
      <Link to="/my-appointments" onClick={collapseMobileFooter}>{strings['myAppointments']}</Link>
      <button className="visible" onClick={onSettingButtonClick}>{strings['settings']}</button>
    </div>
    )}
    {!isLogged && (
    <Link to="/" onClick={collapseMobileFooter}>{strings['home']}</Link>
    )}
    <Link to="/privacy" onClick={collapseMobileFooter}>{strings['privacy']}</Link>
    <Link to="/contacts" onClick={collapseMobileFooter}>{strings['contacts']}</Link>
    {isLogged && (
    <button onClick={toggleLinksOnMobile}>{strings['links']}</button>
    )}
    <span>AwesomeSolutions @ 2022</span>
  </div>;
}
