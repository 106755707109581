import dayjs, { Dayjs } from 'dayjs';
import duration, { Duration } from 'dayjs/plugin/duration';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getUsers } from '../../features/account/accountSlice';
import { copyDatePart } from '../../utils/dateHelpers';
import { getStatus } from '../../utils/eventHelpers';
import { Button } from '../Button';
import Checkbox from '../Checkbox';
import DatePicker from '../DatePicker';
import DropDown from '../DropDown';
import { Status } from '../Status';
import StyledCurrencyTextBox from '../StyledCurrencyTextBox';
import StyledTextBox from '../StyledTextBox';
import TimePicker from '../TimePicker';
import { Title } from '../Title';
import './styles.scss';
import { isAdmin, isTrainer } from '../../utils/accountHelpers';

dayjs.extend(duration);

export type formFieldName =
  | 'start'
  | 'end'
  | 'forUser'
  | 'status'
  | 'disableNotifications'
  | 'duration'
  | 'price'
  | 'oneTimeDisableNotifications';

export type CalendarFieldErrors = {
  [fieldName: string]: string;
};

export type CalendarEventFormProps = {
  title?: string;
  btnText?: string;
  fields: { [fieldNme: string]: any };
  fieldErrors: CalendarFieldErrors;
  disabledFields?: string[];
  onChange?: (newItem: { [field: string]: any }) => void;
  onSubmit?: (newItem: { [field: string]: any }) => void;
};

const durations = [
  { name: '30 minutes', value: '29' },
  { name: '1 hour', value: '59' },
  { name: '2 hours', value: '119' },
  { name: '3 hours', value: '179' },
];

const CalendarEventForm = (props: CalendarEventFormProps) => {
  const [dateAnchor, setDateAnchor] = useState<HTMLElement | false>(false);
  const { title, disabledFields, fields, btnText, fieldErrors } = props;
  const account = useAppSelector((state) => state.account);
  const strings = useAppSelector((state) => state.i18n.strings);
  const dispatch = useAppDispatch();
  const [userList, setUserList] = useState(account.userList);

  const isAvailable = (name: formFieldName): boolean => {
    return (
      typeof fields[name] !== 'undefined' || typeof fields[name] === 'boolean'
    );
  };

  const changeHandler = (fieldName: formFieldName, value: any) => {
    let output = { ...fields, [fieldName]: value };
    props.onChange?.(output);
  };

  const isDisabled = (name: formFieldName): boolean => {
    return (disabledFields ?? []).includes(name);
  };

  const submitHandler = () => {
    props.onSubmit?.(fields);
  };

  const dateChangeHandler = (date: Dayjs) => {
    const newEventStart = copyDatePart(date, fields['start']);
    changeHandler('start', newEventStart);
    setDateAnchor(false);
  };

  useEffect(() => {
    if (
      isAvailable('forUser') &&
      !isDisabled('forUser') &&
      !account.userList.length
    ) {
      dispatch(getUsers());
    }
  }, [fields['forUser']]);

  const findDefaultDuration = () => {
    let output = { name: '1 hour', value: '60' };
    if (isAvailable('duration')) {
      const fieldValue = fields['duration'] as Duration;
      const minuteValue = Math.abs(fieldValue.asMinutes());
      const foundDurationValue = durations.find(
        (item) => parseInt(item.value) === minuteValue
      );
      output = foundDurationValue || output;
    }
    return output;
  };

  useEffect(() => {
    let availableUsers = [...account.userList];
    if (isTrainer(account.userData) && !isAdmin(account.userData)) {
      availableUsers = availableUsers.filter(
        (user) =>
          user.trainerId === account.userData.id ||
          user.id === account.userData.id
      );
    }
    const activeUsers = availableUsers.filter((user) =>
      ['ACTIVE', 'PENDING'].includes(user.status?.name || '')
    );
    const sortedUsers = activeUsers.sort((user1, user2) =>
      (user1.displayName?.toLowerCase() || '') >
      (user2.displayName?.toLowerCase() || '')
        ? 1
        : -1
    );
    setUserList(sortedUsers);
  }, [account.userList]);

  return (
    <>
      <Title size="mid">{title ?? strings.createNewEvent}</Title>
      {isAvailable('status') && (
        <Status
          setAs={getStatus(fields['status'])}
          className="create-event-status"
        />
      )}
      <div className="create-event-container">
        {isAvailable('forUser') && (
          <div className="create-event-for-user">
            <DropDown
              className={fieldErrors?.['forUser'] ? 'hasErrors' : undefined}
              kind={'standard'}
              items={userList.map((user) => ({
                name: user.displayName || '',
                value: user.id || '',
              }))}
              defaultItem={{
                name: fields['forUser']?.displayName || '',
                value: fields['forUser']?.id || '',
              }}
              placeholder={strings.forUser}
              autoWidth={false}
              disabled={isDisabled('forUser')}
              onChange={(selected) => {
                const userData =
                  userList.find((userData) => userData.id === selected.value) ??
                  fields['forUser'];
                changeHandler('forUser', userData);
              }}
            />
          </div>
        )}
        {isAvailable('start') && (
          <>
            {dateAnchor && (
              <DatePicker
                date={fields['start']}
                anchor={dateAnchor}
                onDateSelected={dateChangeHandler}
                onClose={() => setDateAnchor(false)}
              />
            )}
            <div className="create-event-date">
              <StyledTextBox
                className={fieldErrors?.['start'] ? 'hasErrors' : ''}
                placeholder={strings.date}
                value={fields['start'].format('DD.MM.YYYY')}
                readOnly
                onFocus={(e) => setDateAnchor(e.target)}
              />
            </div>
            <TimePicker
              title={strings.from}
              time={fields['start']}
              className={`create-event-start ${
                fieldErrors?.['start'] ? 'hasErrors' : ''
              }`}
              onTimePick={(time) => {
                changeHandler('start', time);
              }}
            />
          </>
        )}
        {isAvailable('end') && (
          <>
            <span>-</span>
            <TimePicker
              title={strings.to}
              time={fields['end']}
              disabled
              className={`create-event-end ${
                fieldErrors?.['start'] ? 'hasErrors' : undefined
              }`}
            />
          </>
        )}
        {isAvailable('duration') && (
          <div className="create-event-duration">
            <DropDown
              className={fieldErrors?.['duration'] ? 'hasErrors' : undefined}
              kind={'standard'}
              items={durations}
              defaultItem={findDefaultDuration()}
              placeholder={strings.duration}
              autoWidth={false}
              disabled={isDisabled('duration')}
              onChange={(selected) => {
                const duration = dayjs.duration({
                  minutes: parseInt(selected.value),
                });
                changeHandler('duration', duration);
              }}
            />
          </div>
        )}
        {isAvailable('disableNotifications') && (
          <>
            <div className="create-event-notif-chb">
              <Checkbox
                onChange={(e) => {
                  changeHandler(
                    'disableNotifications',
                    (e.target as any).checked
                  );
                }}
                checked={fields['disableNotifications']}
              />
              <span>{strings.dontSendNotifications} </span>
            </div>
          </>
        )}
        {isAvailable('price') && (
          <>
            <div className="create-event-price-chb">
              <StyledCurrencyTextBox
                value={fields['price']}
                onChange={(e) => {
                  changeHandler('price', e.currentTarget.value);
                }}
                placeholder={strings.price}
              />
            </div>
          </>
        )}
        <div className="create-event-footer">
          {Object.keys(fieldErrors || {}).length > 0 && (
            <span>{Object.values(fieldErrors || {})[0]}</span>
          )}
          {isAvailable('oneTimeDisableNotifications') && (
            <>
              <div className="create-event-onetime-notif">
                <Checkbox
                  onChange={(e) => {
                    changeHandler(
                      'oneTimeDisableNotifications',
                      (e.target as any).checked
                    );
                  }}
                  checked={fields['oneTimeDisableNotifications']}
                />
                <span>{strings.oneTimeDontSendNotifications} </span>
              </div>
            </>
          )}
          <Button
            kind="standard"
            onClick={submitHandler}
            disabled={Object.keys(fieldErrors || {}).length > 0}
          >
            {btnText || strings.create}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CalendarEventForm;
