import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { activateUser } from '../../features/account/accountSlice';
import StyledTextBox from '../../components/StyledTextBox';
import { Button } from '../../components/Button';
import { Title } from '../../components/Title';
import './styles.scss';
import Logo from '../../img/Logo_MEDA_bold.svg';
import Spinner from '../../components/Spinner';
import LoginProviders from './LoginProviders';
import LanguagePicker from '../../components/LanguagePickerFormField';
import {
  setLanguage,
  getStoredLanguage,
  possibleLanguages,
} from '../../features/i18n/i18nSlice';

export default function Main() {
  const account = useAppSelector((state) => state.account);
  const strings = useAppSelector((state) => state.i18n.strings);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [queryParams] = useSearchParams();
  const form = useRef<HTMLFormElement>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState(getStoredLanguage());

  useEffect(() => {
    const passedLang = queryParams.get('lang');
    if (passedLang && possibleLanguages.includes(passedLang)) {
      dispatch(setLanguage(passedLang));
      setLang(passedLang);
    }
  }, []);

  useEffect(() => {
    if (account.activateStatus === 'success') {
      navigate('/');
      setLoading(false);
    } else if (account.activateStatus === 'failed') {
      setErrorMessage(account.errorMessage);
      setLoading(false);
    }
  }, [account.activateStatus, account.errorMessage, navigate]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const password: string = form.current?.password.value;
    const confirm: string = form.current?.confirm.value;

    if (password !== confirm) {
      setErrorMessage(strings.errorPasswordMatch);
    } else if (password.length < 4) {
      //Obviously this will change later on
      setErrorMessage(strings.errorPasswordRequirements);
    } else if (userId) {
      setLoading(true);
      dispatch(activateUser({ userId, password, type: 'local' }));
    }
  };

  return (
    <div className="login-page-wrapper">
      <Spinner show={loading} asOverlay={true}>
        <div className="login-wrapper">
          <div className="language-picker">
            <LanguagePicker
              key={lang}
              selectedLang={lang}
              onChanged={(lang) => dispatch(setLanguage(lang))}
            />
          </div>
          <div className="login-logo-wrapper">
            <img className="logo" src={Logo} />
          </div>
          <div className="login-form-wrapper">
            <form className="form" ref={form} onSubmit={handleSubmit}>
              <div className="form-header">
                <Title size="small">
                  {strings.accountActivation.toUpperCase()}
                </Title>
              </div>
              <div className="form-content">
                <StyledTextBox
                  type="password"
                  placeholder={strings.password}
                  name="password"
                />
                <StyledTextBox
                  type="password"
                  placeholder={strings.confirmPassword}
                  name="confirm"
                />
                <br />
                <span className="error-box">{errorMessage}</span>
                <Button kind="standard" type="submit">
                  {strings.activate}
                </Button>
                <br />
                <Title size="small">{strings.or.toUpperCase()}</Title>
                <br />
                <div className="login-providers">
                  <LoginProviders
                    providers={['google', 'facebook']}
                    page="activate"
                    onSuccess={(type, password) =>
                      dispatch(activateUser({ type, userId, password }))
                    }
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Spinner>
    </div>
  );
}
