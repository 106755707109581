import React from "react";
import { ITimeRange } from "../../../features/calendar/calendarInterfaces";
import './styles.scss';

export type HoursListProps = {
  range: ITimeRange;
}

const HoursLabelsList = (props: HoursListProps) => {
  const start = props.range.start.hour() - 1;
  const end = props.range.end.hour() + 2;

  const labels = [];

  for (let i = start; i <= end; i++) {
    labels.push(
      <li className="hour-labels-list-label" key={`hl-${i}`}>
        <label>{`${i}:00`}</label>
      </li>
    )
  }

  return (
    <ul className="hours-labels-list-wrapper">
      <li className="hour-labels-list-label" key={'hl-0'}>{`H`}</li>
      {labels}
    </ul>
  )
}

export default HoursLabelsList;
