import { useMemo } from 'react';
import { useSortBy, useTable, useFilters, useGlobalFilter, usePagination } from 'react-table';
import styled from 'styled-components';
import { useAppSelector } from '../../app/hooks';
import './style.scss';
import { Status } from '../Status';
import Pagination from './Pagination';
import GlobalFilter from './GlobalFilter';
import * as constants from '../../constants/styledConstants';
import { ReactComponent as SortAsc } from '../../img/sort-asc.svg';
import { ReactComponent as SortDesc } from '../../img/sort-desc.svg';
import Spinner from '../../components/Spinner';

const Styles = styled.div`
  table {
    width: 80%;
    margin: auto;
    border-spacing: 0;
    box-shadow: 0px 0px 8px 2px #00000087;
    border-radius: 10px 10px 0 0;
    background-color: var(--colorPrimary);
    height: 50px;
    overflow: auto;

    thead {
      border-radius: inherit;
      height: 4em;
      background-color: var(--colorPrimary);

      th {
        border-radius: inherit;
        border: 0;
        color: white;
        font-size: 1.2rem;
        font-weight: bold;
        position: relative;

        svg {
          width: 15px;
          height: 15px;
          fill: #00000057;
          stroke: #00000000;
          vertical-align: bottom;
          padding-bottom: 2px;
        }
      }
    }

    tr {
      border-radius: inherit;
    }

    th,
    td {
      margin: 0;
      padding: 0.3em 10px;
      cursor: pointer;
      border-bottom: 1px solid #eee;
      border-right: 0;
    }

    tbody tr {
      background: white;

      &:hover {
        background-color: var(--colorSecondary);
        color: white;
      }

      &.table-row-even:not(:hover) {
        background-color: rgb(240 240 240);
      }
    }
  }

  ${constants.mediaLarge} {
    table {
      width: 100%;
      box-shadow: none;
      border-radius: 0;
      background-color: var(--colorPrimary);
    }

    .user-pending:first-of-type {
      border-left: 5px solid #ffa445; // ${constants.colorPending}; <= not enough contrast. Could change che pending color
    }
  }
`;

export default function UsersTable({ onUserSelect }) {
  const account = useAppSelector((state) => state.account);
  const strings = useAppSelector((state) => state.i18n.strings);
  const loadingUsers = useAppSelector((state) => state.account.getUsersStatus === 'pending');

  const columns = useMemo(
    () => [
      {
        Header: strings.displayName,
        accessor: 'displayName',
        alwaysShow: true,
        style: {
          width: '10%',
          textAlign: 'left',
        },
      },
      {
        Header: strings.email,
        accessor: 'email',
        alwaysShow: true,
        style: {
          width: '5%',
          textAlign: 'left',
        },
      },
      {
        Header: strings.roles,
        accessor: ({ roles }) => roles?.map((role) => role.name).join(', '),
        style: {
          width: '10%',
          textAlign: 'center',
        },
      },
      {
        Header: 'StatusText',
        accessor: ({ status }) => status?.name,
        style: {
          width: '1%',
          textAlign: 'center',
          display: 'none',
        },
      },
      {
        Header: strings.status,
        accessor: ({ status }) => <Status setAs={status?.name.toLowerCase()} />,
        style: {
          width: '1%',
          textAlign: 'center',
        },
      },
      {
        Header: 'User ID',
        accessor: 'id',
        style: {
          width: '1%',
          textAlign: 'left',
          display: 'none',
        },
      },
    ],
    []
  );

  const initialState = {
    pageSize: 12,
    sortBy: [
      {
        id: 'displayName',
        desc: false,
      },
    ],
  };

  const usersTable = useTable(
    { columns, data: account.userList, initialState, autoResetGlobalFilter: false },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = usersTable;

  const showColumn = (column) => {
    return column.alwaysShow ? 'fixed' : '';
  };

  return (
    <Styles>
      <Spinner asOverlay={true} show={loadingUsers}>
        <div className="tableWrap">
          <GlobalFilter table={usersTable} />
          <table {...getTableProps()}>
            <thead className="table-header">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`table-column ${showColumn(column)} ${
                        column.isSorted ? (column.isSortedDesc ? 'sort-desc' : 'sort-asc') : ''
                      }`}
                      style={column.style}
                    >
                      {column.render('Header')}
                      <span>{column.isSorted ? column.isSortedDesc ? <SortDesc /> : <SortAsc /> : ''}</span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, rowNumber) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className={`table-row ${rowNumber % 2 === 0 ? 'table-row-even' : 'table-row-odd'}`}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`table-cell ${showColumn(cell.column)} ${`user-${cell.row?.values?.StatusText.toLowerCase()}`}`}
                          style={cell.column.style}
                          onClick={() => onUserSelect(row.values.id)}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Pagination table={usersTable} />
      </Spinner>
    </Styles>
  );
}
