import React, { useEffect, useState } from 'react';
import StyledTextBox from '../StyledTextBox';
import { useAppSelector } from '../../app/hooks';
import Checkbox from '../Checkbox';
import { UserData } from '../../features/account/accountInterfaces';
import WorkingTimeComponent from '../WorkingTimeComponent';

const requiredRoles = ['CUSTOMER'];
const rolesOrder = ['CUSTOMER', 'TRAINER', 'ADMIN'];

type LoginInfoTabFragmentProps = {
  userData?: UserData;
};

export default function LoginInfoTabFragment({
  userData,
}: LoginInfoTabFragmentProps) {
  const roles = useAppSelector((state) => state.metadata.userRolesAvailable);
  const strings = useAppSelector((state) => state.i18n.strings);
  const [workingHours, setWorkingHours] = useState(
    userData?.workingHours || {}
  );
  const [enableWorkingHours, setEnableWorkingHours] = useState(
    userData?.roles?.some((role) => role.name === 'TRAINER') || false
  );

  const roleElements = [...roles]
    .sort((a, b) => rolesOrder.indexOf(a.name) - rolesOrder.indexOf(b.name))
    .map((role) => {
      const required = requiredRoles.includes(role.name);
      let requiredProps = {};
      if (required) {
        requiredProps = {
          checked: true,
          disabled: true,
        };
      }
      const label =
        strings[`role_${role.name.toLowerCase()}` as keyof typeof strings];
      return (
        <li key={role.name}>
          <Checkbox
            id={`create-user-role-${role.name}`}
            {...requiredProps}
            value={role.id}
            onChange={(e) => {
              if (e.currentTarget.checked && role.name === 'TRAINER') {
                setEnableWorkingHours(true);
              } else if (!e.currentTarget.checked && role.name === 'TRAINER') {
                setEnableWorkingHours(false);
              }
            }}
          />
          <label htmlFor={`create-user-role-${role.name}`}>{label}</label>
        </li>
      );
    });

  useEffect(() => {
    if (userData?.roles) {
      userData.roles.forEach((role) => {
        const checkbox = document.getElementById(
          `create-user-role-${role.name}`
        ) as HTMLInputElement;
        if (checkbox) {
          checkbox.checked = true;
        }
      });
    }
  }, [userData]);

  return (
    <>
      <div className="create-user-text-box">
        <StyledTextBox
          type="text"
          placeholder={`${strings.email}`}
          name="email"
          defaultValue={userData?.email || ''}
        />
      </div>
      <div className="create-user-text-box">
        <ul className="create-user-roles-list">{roleElements}</ul>
      </div>
      <input
        type="hidden"
        name="workingHours"
        value={JSON.stringify(workingHours)}
      />
      {enableWorkingHours && (
        <WorkingTimeComponent
          workingHours={userData?.workingHours ?? {}}
          onChange={(wh) => {
            setWorkingHours(wh);
          }}
        />
      )}
      <div className="create-user-text-box"></div>
    </>
  );
}
