import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as constants from '../../constants/styledConstants';
import uniqueId from 'uniqueid';
import StyledTextBox from '../StyledTextBox';

const Backdrop = styled.div`
  background-color: black;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  ${constants.mediaSmall} {
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }
`;

interface ContainerProps {
  $autoWidth: boolean;
  width: string;
  isdisabled?: boolean;
}

const DropDownContainer = styled.div<ContainerProps>`
  border: 2px solid ${constants.colorSecondary};
  display: inline-flex;
  border-radius: 0.25em;
  padding: 0;
  cursor: pointer;
  align-items: center;
  justify-content: right;
  position: relative;
  min-width: 60px;
  height: 2.5em;
  width: ${(props) => (props.$autoWidth ? 'max-content' : props.width)};

  &.dropdown-focused {
    ${constants.mediaSmall} {
      position: fixed;
      top: 20px;
      padding: 0.2em 0;
      left: 0;
      z-index: 10;
      background-color: white;
      width: calc(100% - 20px) !important;
      min-width: calc(100% - 20px) !important;
      left: 10px;
      right: 10px;

      //[I]Removing the placeholder because it looks bad. Will re-enable it later when I figure a proper design.
      & > div > span {
        display: none;
      }
    }
  }

  &[data-isdisabled='true'] {
    border-color: ${constants.colorSecondary}77 !important;
    cursor: no-drop !important;
    * {
      cursor: inherit !important;
    }
  }

  &:focus-within {
    border-color: ${constants.colorPrimary};
  }

  & > div {
    width: 100%;
    max-width: 100%;
    min-width: 0;
    // display: block;

    input {
      width: 100%;
      border: none;
      background-color: transparent;
      outline: none;
      min-width: 0;

      &:read-only {
        cursor: pointer;
      }
    }
  }

  ${constants.mediaLarge} {
    min-width: 100%;

    & > div {
      min-width: initial;
    }
  }

  &:hover {
    & #dd-svg path {
      stroke: ${constants.colorPrimary};
    }
  }
`;

const DropDownGlyph = styled.label`
  display: inline-flex;
  margin: 0;
  padding: 0.25em 0.2em;
  cursor: pointer;
  width: 1.8em;
  align-items: center;
  justify-content: center;
  min-width: 1.8em;
  max-width: 1.8em;
  transition: transform 100ms ease-out;

  svg {
    vertical-align: middle;
    padding-bottom: 0.1em;
  }
`;

type Kind = 'standard' | 'popup';

interface ListProps {
  kind: Kind;
}

const DropDownList = styled.ul<ListProps>`
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  border: 2px solid ${constants.colorPrimary};
  position: absolute;
  z-index: 10;
  background-color: white;
  padding: 0.2em 0;
  border-radius: 0.2em;
  min-width: inherit;
  max-height: 20em;
  overflow: auto;
  animation: popupAnimation 200ms ease-in;
  left: ${(props) => (props.kind === 'standard' ? '-2px' : '50%')};
  top: ${(props) => (props.kind === 'standard' ? '1.3em' : '0')};
  transform: ${(props) => (props.kind === 'standard' ? '' : 'translate(-50%, -50%)')};

  @keyframes popupAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${constants.mediaSmall} {
    margin: 10px 10px;
    position: fixed;
    font-size: 2rem;
    border: 3px solid ${constants.colorSecondary};
    max-height: 70vh;
    left: 0;
    right: 0;
    top: 2em;
    min-width: initial;
    /* transform: translate(0, -50%); */

    ${Backdrop} {
      visibility: visible;
    }
  }
`;

const DropDownListItem = styled.li`
  padding: 0.3em 2em;
  margin: 0;
  width: max-content;
  max-width: 20em;
  min-width: 100%;
  text-align: center;

  color: black;

  &:hover,
  &.list-item-selected {
    color: white;
    background-color: ${constants.colorSecondary};
  }
`;

interface DropDownItem {
  name: string;
  value: string;
}

interface DropDownProps {
  kind: Kind;
  items: DropDownItem[];
  defaultItem: DropDownItem;
  placeholder?: string;
  autoWidth: boolean;
  width?: string;
  readOnly?: boolean;
  className?: string;
  disabled?: boolean;
  onChange?: (item: DropDownItem) => void;
}

const uid = uniqueId('dropdown');

export default function DrownDown(props: DropDownProps) {
  const [showList, setShowList] = useState(false);
  const [inputValue, setInputValue] = useState(props.defaultItem.name);
  const [selIndex, setSelIndex] = useState(-1);
  const ddbutton = useRef(uid());
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const onSelectValue = (e: React.MouseEvent) => {
    if (!(e.target instanceof HTMLLIElement) || !e.target.dataset.name) {
      return;
    }

    selectItem({ name: e.target.dataset.name, value: e.target.dataset.value || '' });
  };

  const selectItem = (item: DropDownItem | null) => {
    setShowList(false);
    dropdownRef.current?.classList.remove('dropdown-focused');
    if (item) {
      props.onChange && props.onChange(item);
      setInputValue(item.name);
    }
  };

  useEffect(() => {
    const onOutsideClick = (e: MouseEvent) => {
      const element = e.target as HTMLElement;
      if (element && !dropdownRef.current?.contains(element)) {
        selectItem(null);
      }
    };

    document.addEventListener('mousedown', onOutsideClick);
    return () => {
      document.removeEventListener('mousedown', onOutsideClick);
    };
  }, []);

  const filteredItems = props.items.filter((item) => item?.name?.toLowerCase().includes(inputValue.toLowerCase()));

  useEffect(() => {
    if (!showList && inputValue !== filteredItems[selIndex]?.name) {
      const foundItem = props.items.find((item) => item.name === inputValue);
      selectItem(foundItem || props.defaultItem || { name: '', value: '' });
    }
  }, [showList, props.defaultItem.name]);

  useEffect(() => {
    const selectedItem = dropdownRef.current?.getElementsByClassName('list-item-selected')[0];
    selectedItem?.scrollIntoView({ block: 'nearest' });
  }, [selIndex]);

  const focusDropDown = (e: React.FocusEvent<HTMLInputElement>) => {
    setInputValue('');
    setShowList(true);
    !props.readOnly && e.target.select();
    dropdownRef.current?.classList.add('dropdown-focused');
  };

  const buttonHandler = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (!showList) setShowList(true);
        setSelIndex(Math.min(selIndex + 1, filteredItems.length - 1));
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelIndex(Math.max(selIndex - 1, 0));
        break;
      case 'Enter':
        if (!showList) setShowList(true);
        if (filteredItems[selIndex]) {
          selectItem({ name: filteredItems[selIndex]?.name, value: filteredItems[selIndex]?.value });
        }
        break;
    }
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setSelIndex(-1);
  };

  return (
    <>
      {showList && <Backdrop />}
      <DropDownContainer
        $autoWidth={props.autoWidth}
        width={props.width || '7em'}
        className={props.className}
        ref={dropdownRef}
        data-isdisabled={props.disabled}
      >
        <StyledTextBox
          autoComplete="off"
          readOnly={!!props.readOnly}
          id={ddbutton.current}
          placeholder={props.placeholder}
          value={inputValue}
          onChange={changeHandler}
          onFocus={focusDropDown}
          onKeyDown={buttonHandler}
          onBlur={(e) => (showList ? (e.target.placeholder = '') : null)}
          disabled={props.disabled}
        />
        <DropDownGlyph
          htmlFor={ddbutton.current}
          style={showList ? { transform: 'rotate(180deg)' } : {}}
          onClick={(e) => {
            if (showList) {
              e.preventDefault();
              setShowList(false);
            }
          }}
        >
          <svg id="dd-svg" xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 29 29" xmlSpace="preserve">
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="2"
              d="M20.5 11.5l-6 6-6-6"
            />
          </svg>
        </DropDownGlyph>
        {showList && (
          <DropDownList onClick={onSelectValue} kind={props.kind}>
            {filteredItems.map((item, index) => (
              <DropDownListItem
                key={item.name + index}
                data-name={item.name}
                data-value={item.value}
                className={`${index === selIndex ? 'list-item-selected' : ''}`}
              >
                {item.name}
              </DropDownListItem>
            ))}
          </DropDownList>
        )}
      </DropDownContainer>
    </>
  );
}
