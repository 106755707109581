import MenuItem, { MenuItemProps } from './MenuItem';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import SlideInComponent from '../SlideInComponent';

interface PopupMenuStyleProps {
  show: boolean;
}

const animTime = 200;
const PopupMenuStyle = styled.div<PopupMenuStyleProps>`
  width: 13em;
  border-radius: 3px;
  box-shadow: 0px 0 10px 3px #00000026;
  font-size: 1rem;
  background-color: white;
  position: absolute;
  transform: translateX(-40%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;

  animation: ${(props) => (props.show ? 'fadeIn' : 'fadeOut')} ${animTime}ms forwards linear;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const MobileMenuStyle = styled.div`
  z-index: 1000;
  width: 100%;
  height: 100%;
  font-size: 1.4rem;
`;

interface PopupMenuProps {
  items: MenuItemProps[];
  show: boolean;
  mobileView?: boolean;
  onItemClicked?: () => void;
  onOutsideClick?: () => void;
}

export default function PopupMenu({ show, items, onItemClicked, onOutsideClick, mobileView = false }: PopupMenuProps) {
  const [showMenu, setShowMenu] = useState(show);

  useEffect(() => {
    const outsideClick = (e: MouseEvent) => {
      if (!(e.target as Element).closest('.user-menu')) {
        onOutsideClick?.();
      }
    };
    document.addEventListener('click', outsideClick);

    return () => document.removeEventListener('click', outsideClick);
  }, [onOutsideClick]);

  useEffect(() => {
    if (show) {
      setShowMenu(true);
    } else {
      setTimeout(() => setShowMenu(false), animTime);
    }
  }, [show]);

  const MenuItems = () => {
    return (
      <>
        {items.map(
          (item, index) =>
            !item.hide && (
              <MenuItem
                key={index}
                action={item.action}
                link={item.link}
                caption={item.caption}
                icon={item.icon}
                iconViewBox={item.iconViewBox}
                onClick={onItemClicked}
              />
            )
        )}
      </>
    );
  };

  return (
    <>
      <MobileMenuStyle className="popup-menu">
        <SlideInComponent expanded={show && mobileView} onClose={onOutsideClick}>
          <MenuItems />
        </SlideInComponent>
      </MobileMenuStyle>
      {showMenu && !mobileView && (
        <PopupMenuStyle show={show} className="popup-menu">
          <MenuItems />
        </PopupMenuStyle>
      )}
    </>
  );
}
