import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { ICalEvent } from '../../features/calendar/calendarInterfaces';
import Confirm from '../Modal/Confirm';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import StyledCurrencyTextBox from '../StyledCurrencyTextBox';
import Checkbox from '../Checkbox';
import {
  approveCalEvent,
  cancelCalEvent,
  editCalEventPrice,
  rejectCalEvent,
} from '../../features/calendar/calendarSlice';
import { validatePriceNumber } from '../../utils/validations';
import { isMine, isTrainerFor } from '../../utils/eventHelpers';
import './styles.scss';
import { isAdmin } from '../../utils/accountHelpers';
import { Button } from '../Button';

export type ConfirmAction = 'cancel' | 'reject' | 'approve' | false;

type CalendarItemActionConfirmProps = {
  action: ConfirmAction;
  item: ICalEvent;
  show: boolean;
  onConfirm?: (action: ConfirmAction, item: ICalEvent) => void;
  onDecline?: (action: ConfirmAction, item: ICalEvent) => void;
};

export default function CalendarItemActionConfirm(
  props: CalendarItemActionConfirmProps
) {
  const strings = useAppSelector((state) => state.i18n.strings);
  const account = useAppSelector((state) => state.account);
  const { action, item, show, onConfirm, onDecline } = props;
  const [deleteFlag, setDeleteFlag] = useState(false);
  const myItem = isMine(item, account.userData);
  const isUserAdmin = isAdmin(account.userData);
  const isTrainerOfItem = isTrainerFor(item, account.userData);

  const withDelete =
    (isUserAdmin || isTrainerOfItem) &&
    (action === 'cancel' || action === 'reject');

  useEffect(() => {
    setDeleteFlag(false);
    setPriceError('');
    setPrice(item.price?.toFixed(2));
    setOneTimeDisableNotifications(false);
  }, []);

  const actionContent = () => {
    if (!isUserAdmin && !isTrainerOfItem) {
      return <></>;
    }
    switch (action) {
      case 'cancel':
      case 'reject':
        return (
          <>
            <div className="action-onetime-notif">
              <Checkbox
                onChange={(e) =>
                  setOneTimeDisableNotifications((e.target as any).checked)
                }
                checked={oneTimeDisableNotifications}
                id="onetime-notif"
              />
              <label htmlFor="onetime-notif">
                {strings.oneTimeDontSendNotifications}
              </label>
            </div>
          </>
        );
      case 'approve':
        return (
          <>
            <div>
              <StyledCurrencyTextBox
                value={price}
                onChange={handlePriceChange}
                placeholder={strings.price}
              />
            </div>
            <div>{priceError && <span>{priceError}</span>}</div>
            <div>
              <div className="action-onetime-notif">
                <Checkbox
                  onChange={(e) =>
                    setOneTimeDisableNotifications((e.target as any).checked)
                  }
                  checked={oneTimeDisableNotifications}
                  id="onetime-notif"
                />
                <label htmlFor="onetime-notif">
                  {strings.oneTimeDontSendNotifications}
                </label>
              </div>
            </div>
          </>
        );
    }
  };

  const titleText = useMemo(() => {
    switch (action) {
      case 'cancel':
        return myItem
          ? strings.confirmAppointmentCancel
          : strings.confirmAdminAppointmentCancel;
      case 'reject':
        return strings.rejectAppointment;
      case 'approve':
        return strings.confirmAppointment;
      default:
        return '';
    }
  }, [action]);

  const dispatch = useAppDispatch();

  const [priceError, setPriceError] = useState('');
  const [price, setPrice] = useState(item.price?.toFixed(2));
  const [oneTimeDisableNotifications, setOneTimeDisableNotifications] =
    useState(false);

  const handleApproveConfirm = async (event: ICalEvent) => {
    if (price && parseFloat(price) !== item.price) {
      await dispatch(
        editCalEventPrice({
          eventId: event.id,
          start: event.start,
          price: parseFloat(price),
        })
      );
    }

    await dispatch(
      approveCalEvent({
        eventId: event.id,
        start: event.start,
        oneTimeDisableNotifications: oneTimeDisableNotifications,
      })
    );
    onConfirm?.(action, item);
  };

  const handleRejectConfirm = async (event: ICalEvent) => {
    await dispatch(
      rejectCalEvent({
        eventId: event.id,
        start: event.start,
        oneTimeDisableNotifications: oneTimeDisableNotifications,
        deleteOnReject: deleteFlag,
      })
    );
    onConfirm?.(action, item);
  };

  const handleCancelConfirm = async (event: ICalEvent) => {
    await dispatch(
      cancelCalEvent({
        eventId: event.id,
        start: event.start,
        oneTimeDisableNotifications: oneTimeDisableNotifications,
        deleteOnCancel: deleteFlag,
      })
    );
    onConfirm?.(action, item);
  };

  const handlePriceChange = async (e: FormEvent<HTMLInputElement>) => {
    const newPrice = e.currentTarget.value;
    setPrice(newPrice);
    const validation = await validatePriceNumber(newPrice, strings);
    if (validation.isValid) {
      setPriceError('');
    } else {
      setPriceError(validation.errorMessage);
    }
  };

  const handleConfirm = () => {
    switch (action) {
      case 'cancel':
        handleCancelConfirm(item);
        break;
      case 'reject':
        handleRejectConfirm(item);
        break;
      case 'approve':
        handleApproveConfirm(item);
        break;
      default:
        break;
    }
  };
  const handleDecline = () => {
    onDecline?.(action, item);
  };

  return (
    <Confirm
      show={show}
      onConfirm={handleConfirm}
      onDecline={handleDecline}
      buttonConfirmText={strings.yes}
      buttonDeclineText={strings.no}
      titleText={titleText}
      content={actionContent()}
      buttonsOverride={(props) => (
        <>
          <div>
            <Button
              onClick={() => {
                props.onConfirm?.();
              }}
            >
              {props.buttonConfirmText}
            </Button>
            {withDelete && (
              <div className="action-delete-item">
                <Checkbox
                  onChange={(e) => setDeleteFlag((e.target as any).checked)}
                  checked={deleteFlag}
                  id="delete-item"
                  $iconSize="s"
                />
                <label htmlFor="delete-item">{strings.delete}</label>
              </div>
            )}
          </div>
          <Button
            onClick={() => {
              props.onDecline?.();
            }}
          >
            {props.buttonDeclineText}
          </Button>
        </>
      )}
    />
  );
}
