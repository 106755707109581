import React, { useMemo } from 'react';
import LanguagePicker from '../LanguagePickerFormField';
import StyledTextBox from '../StyledTextBox';
import supportedLanguages from '../../constants/supportedLanguages.json';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { UserData } from '../../features/account/accountInterfaces';
import DropDown from '../DropDown';
import { getUsers } from '../../features/account/accountSlice';

export type BasicInfoTabFragmentProps = {
  userData?: UserData;
};

export default function BasicInfoTabFragment({
  userData,
}: BasicInfoTabFragmentProps) {
  const dispatch = useAppDispatch();
  const strings = useAppSelector((state) => state.i18n.strings);
  const selectedLang = userData?.userFields?.lang || supportedLanguages[0];
  const account = useAppSelector((state) => state.account);
  const [selectedTrainer, setSelectedTrainer] = React.useState<
    string | undefined
  >(userData?.trainerId);

  const trainers = useMemo<{ value: string; name: string }[]>(() => {
    if (!account.userList.length) {
      dispatch(getUsers());
      return [];
    }
    return account.userList
      .filter((user) => user.roles?.some((role) => role.name === 'TRAINER'))
      .map((user) => ({
        value: user.id as string,
        name: user.displayName as string,
      }));
  }, [account.userList]);

  const currentTrainer =
    trainers.find((t) => t.value === selectedTrainer) || trainers[0];

  return (
    <>
      <div className="create-user-text-box">
        <StyledTextBox
          type="text"
          placeholder={`${strings.displayName}*`}
          name="displayName"
          defaultValue={userData?.displayName || ''}
        />
      </div>
      <div className="create-user-text-box">
        <input type="hidden" name="trainerId" value={currentTrainer.value} />
        <DropDown
          items={trainers}
          kind="standard"
          defaultItem={currentTrainer}
          autoWidth={false}
          className='trainer-dropdown'
          onChange={(item) => setSelectedTrainer(item.value)}
          placeholder={strings.role_trainer}
        />
      </div>
      <div className="create-user-text-box">
        <StyledTextBox
          type="text"
          placeholder={`${strings.phone}*`}
          name="phone"
          defaultValue={userData?.userFields?.phone || ''}
        />
      </div>
      <div className="create-user-text-box">
        <StyledTextBox
          type="text"
          placeholder={`${strings.address}*`}
          name="address"
          defaultValue={userData?.userFields?.address || ''}
        />
      </div>
      <div className="create-user-text-box">
        <StyledTextBox
          type="text"
          placeholder={`${strings.price}*`}
          name="price"
          defaultValue={userData?.adminFields?.price || ''}
        />
      </div>
      <div className="create-user-text-box">
        <LanguagePicker selectedLang={selectedLang} id={'lang'} />
      </div>
    </>
  );
}
