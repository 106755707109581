import { useMemo, useState } from 'react';
import './styles.scss';
import { ICalEvent } from '../../features/calendar/calendarInterfaces';
import { Title } from '../Title';
import { ActionButton } from '../ActionButton';
import { Label } from '../Label';
import { Status } from '../Status';
import Checkbox from '../Checkbox';
import { useAppSelector } from '../../app/hooks';
import { getStatus, isMine } from '../../utils/eventHelpers';
import { isAdmin, isTrainer } from '../../utils/accountHelpers';
import { isDateInThePast } from '../../utils/dateHelpers';
import CalendarItemActionConfirm, { ConfirmAction } from '../CalendarItemActionConfirm';

export type CalendarItemViewProps = {
  item: ICalEvent;
  onReschedule?: (event: ICalEvent) => void;
  onReject?: (event: ICalEvent) => void;
  onApprove?: (event: ICalEvent) => void;
  onCancel?: (event: ICalEvent) => void;
};

const CalendarItemView = (props: CalendarItemViewProps) => {
  const { item } = props;
  const account = useAppSelector((state) => state.account);
  const strings = useAppSelector((state) => state.i18n.strings);
  const [actionConfirm, setActionConfirm] = useState<false | ConfirmAction>(
    false
  );
  const formattedDate = item.start.format('DD MMM YYYY');
  const formattedStart = item.start.format('HH:mm');
  const formattedEnd = item.end.format('HH:mm');

  const [isUserAdmin, isEventMine, isEventInThePast, isTrainerForEvent] =
    useMemo(() => {
      return [
        isAdmin(account.userData),
        isMine(item, account.userData),
        isDateInThePast(item.start),
        isTrainer(account.userData) && item.trainer?.id === account.userData.id,
      ];
    }, [account.userData, item]);

  const enableActions = isUserAdmin || isTrainerForEvent || !isEventInThePast;
  const enableAdminActions =
    (isUserAdmin || isTrainerForEvent) && getStatus(item) === 'pending';
  const enableDetails = isEventMine || isTrainerForEvent || isUserAdmin;

  return (
    <>
      <div className="cal-item-view-wrapper">
        <div className="cal-item-date-status">
          <Label className="date-label">{formattedDate}</Label>
          <Status setAs={getStatus(item)} />
        </div>
        <Title size="mid">
          {formattedStart} - {formattedEnd}
        </Title>
        {enableDetails && (
          <>
            <div className="cal-item-view-details">
              <div>
                {strings.customer}: <b>{item.forUser?.displayName}</b>
              </div>
              <div>
                {strings.role_trainer}: <b>{item.trainer?.displayName}</b>
              </div>
              <div className="cal-item-notifications">
                <span>{strings.notificationsEnabled}:</span>{' '}
                <Checkbox
                  checked={!item.disableNotifications}
                  disabled={true}
                ></Checkbox>
              </div>
              <div className="cal-item-metadata">
                {strings.price}: <i>{item.price?.toFixed(2)}</i>
                <br />
                {strings.createdBy}:{' '}
                <i>
                  {item.createdBy?.displayName} -{' '}
                  {item.created?.format('DD.MM.YYYY - HH:mm')}
                </i>
                <br />
                {strings.lastUpdatedBy}:{' '}
                <i>
                  {item.updatedBy?.displayName} -{' '}
                  {item.updated?.format('DD.MM.YYYY - HH:mm')}
                </i>
                <br />
              </div>
            </div>
            <div className="cal-item-view-actions-wrapper">
              {enableActions && (
                <div>
                  <ActionButton
                    action="delete"
                    onClick={() => setActionConfirm('cancel')}
                  ></ActionButton>
                  <ActionButton
                    action="edit"
                    onClick={() => props.onReschedule?.(item)}
                  ></ActionButton>
                </div>
              )}
              {enableAdminActions && (
                <div>
                  <ActionButton
                    action="reject"
                    onClick={() => setActionConfirm('reject')}
                  ></ActionButton>
                  <ActionButton
                    action="approve"
                    onClick={() => setActionConfirm('approve')}
                  ></ActionButton>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <CalendarItemActionConfirm
        onConfirm={(action, item) => {
          setActionConfirm(false);
          if (action === 'approve') props.onApprove?.(item);
          if (action === 'reject') props.onReject?.(item);
          if (action === 'cancel') props.onCancel?.(item);
        }}
        onDecline={() => setActionConfirm(false)}
        item={item}
        show={!!actionConfirm}
        action={actionConfirm}
      />
    </>
  );
};

export default CalendarItemView;
 