import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import accountReducer from '../features/account/accountSlice';
import calendarReducer from '../features/calendar/calendarSlice';
import errorReducer from '../features/error/errorSlice';
import i18nReducer from '../features/i18n/i18nSlice';
import metadataReducer from '../features/metadata/metadataSlice';

export const store = configureStore({
  reducer: {
    account: accountReducer,
    calendar: calendarReducer,
    error: errorReducer,
    i18n: i18nReducer,
    metadata: metadataReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
