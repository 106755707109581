import { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getCalEvents } from '../../features/calendar/calendarSlice';
import { ICalEvent } from '../../features/calendar/calendarInterfaces';
import {
  useAppDispatch,
  useAppSelector,
  calEventsSelector,
} from '../../app/hooks';
import { isAdmin, isTrainer } from '../../utils/accountHelpers';
import dayjs from 'dayjs';

import { Title } from '../../components/Title';
import Appointment from '../../components/Appointment';
import Spinner from '../../components/Spinner';
import CalendarEditItem from '../../components/CalendarEventEditItem';
import SlideInComponent from '../../components/SlideInComponent';
import CalendarItemActionConfirm, {
  ConfirmAction,
} from '../../components/CalendarItemActionConfirm';

import './styles.scss';

export default function MyAppointments() {
  const today = dayjs();
  const { strings } = useAppSelector((state) => state.i18n);
  const account = useAppSelector((state) => state.account);
  const calEvents = useAppSelector(calEventsSelector);
  const calEventsStatus = useAppSelector(
    (state) => state.calendar.calEventsStatus
  );
  const dispatch = useAppDispatch();

  // we'll use this to remember the next appointment ID ..
  const [nextAppointmentID, setNextAppointmentID] = useState(0);
  const [appointmentAction, setAppointmentAction] = useState<
    { action: ConfirmAction; item: ICalEvent } | false
  >(false);
  const smoothlyScrollToNextAppointment = () => {
    const nextAppointmentElement: HTMLElement | null =
      document.getElementById('next-appointment');
    if (nextAppointmentElement) {
      nextAppointmentElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // the following two are so we can remember the last list of appointments ..
  // cause when slide-in component opens - we re-get the events from backend to validate the eventual new event start&end ..
  // which will cause the appointments list to change - which is wrong ..
  const [lastAppointmentsValue, setLastAppointmentsValue] = useState<
    ICalEvent[]
  >([]);
  const [isRescheduling, setIsRescheduling] = useState(false);

  const isUserAdmin = useMemo(() => {
    return isAdmin(account.userData);
  }, []);

  const isUserTrainer = useMemo(() => {
    return isTrainer(account.userData);
  }, []);

  const userId = account?.userData?.id;

  const appointmentsToShow = useMemo(() => {
    // if the slide-in is opened with rescheduling form - do not use the CalEvents from the state ..
    // cause the ones there are used for validation of the rescheduling form ..
    // instead - use the last remembered appointments from before the rescheduling ..
    if (isRescheduling) {
      return lastAppointmentsValue;
    }
    let nextAppointmentID_forMemoFunction = nextAppointmentID;
    const newestCalEvents = calEvents.map((oneEvent) => {
      // next appointment is the one immediately following current time; also - being active or pending ..
      if (
        dayjs(oneEvent.start).diff(today) > 0 &&
        !nextAppointmentID_forMemoFunction &&
        [1, 2].indexOf(oneEvent.status.id) > -1
      ) {
        nextAppointmentID_forMemoFunction = oneEvent.id;
        // smoothly scroll to the next appointment element ..
        setTimeout(smoothlyScrollToNextAppointment, 500);
      }

      return oneEvent;
    });
    setNextAppointmentID(nextAppointmentID_forMemoFunction);
    setLastAppointmentsValue(newestCalEvents);
    return newestCalEvents;
  }, [calEvents, isRescheduling]);

  // regarding reschedule and canceling appointment ..
  const [editData, setEditData] = useState<ICalEvent | boolean>(false);

  const handleAppointmentCancel = (appointment: ICalEvent) => {
    const actionData: typeof appointmentAction = {
      action: 'cancel',
      item: appointment,
    };
    if (isUserAdmin) {
      actionData.action = 'reject';
    }
    setAppointmentAction(actionData);
  };

  const onReschedulingClose = () => {
    setIsRescheduling(false);
    setEditData(false);
    getAppointments();
  };

  // calculate the start end end days of these three weeks we'll get the appointments about ..
  const startDay = today.startOf('week').subtract(7, 'day');
  const endDay = today.endOf('week').add(7, 'day');

  const getAppointments = () => {
    setNextAppointmentID(0);
    let filters: { [key: string]: unknown } | undefined = { forUser: userId };
    if (isUserAdmin) {
      filters = undefined;
    }
    if (isUserTrainer) {
      filters = { trainer: userId };
    }
    dispatch(
      getCalEvents({
        start: startDay,
        end: endDay,
        filters,
        doNotUseCache: true,
      })
    );
  };

  useEffect(() => {
    if (!userId) {
      return;
    }
    // fetch the appointments of the logged in user ..
    getAppointments();
  }, [dispatch, userId]);

  return (
    <div className="flex-col">
      <Title size="mid">{strings['myAppointments']}</Title>
      <Spinner show={calEventsStatus === 'pending'}>
        <>
          {appointmentsToShow.length > 0 && (
            <>
              {startDay.format('DD-MM-YYYY') +
                ' - ' +
                endDay.format('DD-MM-YYYY')}
              <div className="appointments-header desktop-grid">
                <div>{strings['startTime']}</div>
                <div>{strings['endTime']}</div>
                <div>{strings['status']}</div>
                <div>{strings['actions']}</div>
              </div>
            </>
          )}
          <div className="appointments-wrapper desktop-grid">
            {appointmentsToShow.length < 1 && (
              <p>
                {isUserAdmin && strings['noAppointmentsAdmin']}
                {!isUserAdmin && (
                  <>
                    {strings['noAppointmentsUser']}
                    <Link to="/calendar">{strings['here']}</Link>
                  </>
                )}
              </p>
            )}
            {appointmentsToShow.length > 0 &&
              appointmentsToShow.map((oneEvent) => {
                return (
                  <Appointment
                    key={oneEvent.id}
                    appointment={oneEvent}
                    isNext={nextAppointmentID === oneEvent.id}
                    onCancelClick={handleAppointmentCancel}
                    onRescheduleClick={(appointment: ICalEvent) => {
                      setIsRescheduling(true);
                      setEditData(appointment);
                    }}
                    onApproveClick={(appointment: ICalEvent) =>
                      setAppointmentAction({
                        action: 'approve',
                        item: appointment,
                      })
                    }
                  />
                );
              })}
          </div>
        </>
      </Spinner>

      {!!appointmentAction && (
        <CalendarItemActionConfirm
          item={appointmentAction.item}
          onConfirm={() => {
            setAppointmentAction(false);
            getAppointments();
          }}
          show={!!appointmentAction.item}
          onDecline={() => setAppointmentAction(false)}
          action={appointmentAction.action}
        />
      )}

      <SlideInComponent onClose={onReschedulingClose} expanded={!!editData}>
        <CalendarEditItem
          item={editData as ICalEvent}
          onSuccess={onReschedulingClose}
        />
      </SlideInComponent>
    </div>
  );
}
