import React from 'react';
import { useAppSelector } from '../../app/hooks';
import { UserData } from '../../features/account/accountInterfaces';
import { LanguageStrings } from '../../features/i18n/i18nInterfaces';
import { isAdmin } from '../../utils/accountHelpers';
import { getStatus } from '../../utils/accountHelpers';
import { flattenObject } from '../../utils/common';
import { ActionButton } from '../ActionButton';
import { Status } from '../Status';
import { Title } from '../Title';
import { STATUS_IDS } from '../../constants/globalConstants';
import './styles.scss';

export type ViewUserProfileProps = {
  userData: UserData;
  onEdit?: (userData: UserData) => void;
  onDelete?: (userData: UserData) => void;
  onRestore?: (userData: UserData) => void;
  onResetPass?: (userData: UserData) => void;
};

const fieldsInfo = [
  { name: 'displayName', prop: 'displayName' },
  { name: 'email', prop: 'email' },
  { name: 'phone', prop: 'userFields.phone' },
  { name: 'lang', prop: 'userFields.lang' },
  { name: 'address', prop: 'userFields.address' },
];

const ViewUserProfile = (props: ViewUserProfileProps) => {
  const account = useAppSelector((state) => state.account);
  const strings = useAppSelector((state) => state.i18n.strings);
  const isUserAdmin = isAdmin(account.userData);

  const flatValues = flattenObject(props.userData);

  const deleteRestoreButton = () => {
    const isDisabled = props.userData.status?.id === STATUS_IDS.disabled;

    return (
      <ActionButton
        action={isDisabled ? 'restore' : 'delete'}
        onClick={() => (isDisabled ? props.onRestore?.(props.userData) : props.onDelete?.(props.userData))}
      ></ActionButton>
    );
  };

  return (
    <div className="user-profile-container">
      {isUserAdmin && (
        <div className="user-profile-actions">
          <ActionButton action="resetPass" onClick={() => props.onResetPass?.(props.userData)}></ActionButton>
          {deleteRestoreButton()}
          <ActionButton action="edit" onClick={() => props.onEdit?.(props.userData)}></ActionButton>
        </div>
      )}

      <div className="user-profile-details">
        <div className="avatar-container">
          <div className="avatar-image"></div>
          <Status setAs={getStatus(props.userData)} />
        </div>
        <div className="info-container">
          <Title size="small">{strings.userInfo}</Title>
          <ul>
            {fieldsInfo.map((item, index) => {
              let content = (
                <span>
                  <b>{strings[item.name as keyof LanguageStrings]}:</b> {flatValues[item.prop]}
                </span>
              );

              if (item.name === 'lang') {
                content = (
                  <span>
                    <b>{strings.lang}: </b> <label className={'lang-flag ' + props.userData.userFields?.lang}></label>
                  </span>
                );
              }

              return <li key={index}>{content}</li>;
            })}
          </ul>
          <Title size="small">{strings.adminData}</Title>
          <div className="user-profile-metadata">
            {strings.userId}: <i>{props.userData.id}</i>
            <br />
            {strings.authType}: <i>{props.userData.authType}</i>
            <br />
            {strings.roles}: <i>{props.userData.roles?.map((r) => r.name).join(', ')}</i>
            <br />
            {strings.price}: <i>{parseFloat(props.userData.adminFields?.price || '0').toFixed(2)}</i>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUserProfile;
