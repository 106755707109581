import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { useAnchorElement, useAppSelector } from '../../app/hooks';
import * as constants from '../../constants/styledConstants';
import { Button } from '../Button';
import Month, { MonthStyling } from './Month';
import MonthSelector, { MonthSelectorStyling } from './MonthSelector';
import YearSelector, { YearSelectorStyling } from './YearSelector';

const DatePickerBackdrop = styled.div`
  position: fixed;
  background: #00000077;
  visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  ${constants.mediaLarge} {
    visibility: visible;
  }
`;

const DatePickerContainer = styled.div`
  visibility: visible;
  width: 23em;
  height: max-content;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: min(4vw, 1rem);
  box-shadow: 0 0 5px 1px #00000070;
  position: fixed;
  border-radius: 0.3em;
  border-radius: 0.3em;

  ${constants.mediaLarge} {
    position: relative;
    top: initial !important;
    left: initial !important;
    bottom: initial !important;
  }
`;

const DatePickerHeader = styled.div`
  height: 2.5em;
  background-color: ${constants.colorPrimary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
  border-radius: 0.3em 0.3em 0 0;

  button {
    font-size: inherit;
    color: white;
    font-weight: 800;
    border: none;
    background-color: inherit;
    cursor: pointer;

    &:not(:disabled):hover {
      color: ${constants.colorSecondary};
    }
  }

  .datepicker-year-selector {
    width: 100%;
  }
`;

const DatePickerFooter = styled.div`
  font-size: inherit;
  height: max-content;
  display: flex;
  justify-content: flex-end;
  padding-right: 0.5em;
  button {
    font-size: inherit;
    width: 4em;
    min-width: max-content;
    height: 3em;
    padding: 5px 10px;
    border-radius: 0.3em;
    margin-right: 5px;
    &:last-child {
      border-color: ${constants.colorPrimary};
      background-color: ${constants.colorPrimary};
    }
  }
`;
const DatePickerContent = styled.div`
  font-size: inherit;
  height: 20em;
  width: 100%;
  display: flex;
  justify-content: center;

  ${MonthStyling}

  ${MonthSelectorStyling}
  ${YearSelectorStyling}
`;

interface DatePickerProps {
  date: Dayjs;
  onDateSelected: (selectedDate: Dayjs) => void;
  onClose: () => void;
  anchor: HTMLElement;
}

export default function DatePicker({ date, onDateSelected, onClose, anchor }: DatePickerProps) {
  const [pickedDate, setPickedDate] = useState(date);
  const [workingDate, setWorkingDate] = useState(date);
  const [showYearSelector, setShowYearSelector] = useState(false);
  const [showMonthSelector, setShowMonthSelector] = useState(false);
  const pickerRef = useRef<HTMLDivElement>(null);
  const pickerPos = useAnchorElement(pickerRef, anchor);
  const strings = useAppSelector((state) => state.i18n.strings);

  const changeWorkingDateBy = (value: number, unit: ManipulateType) => {
    setWorkingDate((workingDate) => workingDate.add(value, unit));
  };

  const next = () => {
    if (showYearSelector) {
      changeWorkingDateBy(10, 'years');
    } else {
      changeWorkingDateBy(1, 'month');
    }
  };

  const prev = () => {
    if (showYearSelector) {
      changeWorkingDateBy(-10, 'years');
    } else {
      changeWorkingDateBy(-1, 'month');
    }
  };

  const showWorkingPeriod = () => {
    if (showYearSelector) {
      return `${workingDate.year() - 9} - ${workingDate.year() + 14}`;
    } else {
      return workingDate.format('MMMM (YYYY)');
    }
  };

  const changeView = () => {
    if (showYearSelector) {
      setShowMonthSelector(false);
      setShowYearSelector(false);
    } else if (showMonthSelector) {
      setShowYearSelector(true);
    } else {
      setShowMonthSelector(true);
    }
  };

  const handleYearSelect = (year: number) => {
    setWorkingDate((workingDate) => workingDate.year(year));
    setShowYearSelector(false);
  };
  const handleMonthSelect = (month: number) => {
    setWorkingDate((workingDate) => workingDate.month(month));
    setShowMonthSelector(false);
  };

  return (
    <DatePickerBackdrop>
      <DatePickerContainer style={pickerPos} ref={pickerRef}>
        <DatePickerHeader>
          <button type="button" onClick={() => changeWorkingDateBy(-1, 'year')} hidden={showMonthSelector || showYearSelector}>
            {'<<'}
          </button>
          <button type="button" onClick={() => prev()} hidden={showMonthSelector && !showYearSelector}>
            {'<'}
          </button>
          <button className="datepicker-year-selector" onClick={changeView}>
            {showWorkingPeriod()}
          </button>
          <button type="button" onClick={() => next()} hidden={showMonthSelector && !showYearSelector}>
            {'>'}
          </button>
          <button type="button" onClick={() => changeWorkingDateBy(1, 'year')} hidden={showMonthSelector || showYearSelector}>
            {'>>'}
          </button>
        </DatePickerHeader>
        <DatePickerContent>
          {showYearSelector ? (
            <YearSelector workingYear={workingDate.year()} onYearSelect={handleYearSelect} />
          ) : showMonthSelector ? (
            <MonthSelector workingMonth={workingDate.format('MMM')} onMonthSelect={handleMonthSelect} />
          ) : (
            <Month
              year={workingDate.year()}
              month={workingDate.month()}
              onClick={(date: dayjs.Dayjs) => setPickedDate(date)}
              selectedDate={pickedDate}
            />
          )}
        </DatePickerContent>
        <DatePickerFooter>
          {/* Replace later */}
          <Button type="button" onClick={() => onClose()} kind="outlined">
            {strings.cancel}
          </Button>
          <Button type="button" onClick={() => onDateSelected(pickedDate)}>
            {strings.ok}
          </Button>
        </DatePickerFooter>
      </DatePickerContainer>
    </DatePickerBackdrop>
  );
}
