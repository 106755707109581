import { ReactNode, useEffect } from 'react';

import { Button } from '../Button';
import { Title } from '../Title';
import Modal from './Modal';

import './Confirm.scss';

export type ConfirmProps = {
  show: boolean;
  titleText?: string;
  content?: ReactNode;
  buttonConfirmText?: string;
  buttonDeclineText?: string;
  onConfirm?: Function;
  onDecline?: Function;
  buttonsOverride?: (props: Omit<ConfirmProps, 'buttonsOverride'>) => ReactNode;
};

export default function Confirm(props: ConfirmProps) {
  const {
    show,
    titleText,
    content,
    buttonConfirmText,
    buttonDeclineText,
    onConfirm,
    onDecline,
    buttonsOverride,
  } = props;

  // we want the user to have ability to confirm it by pressing ENTER ..
  useEffect(() => {
    const listenOnKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        onConfirm?.();
      }
    };

    if (show) {
      document.addEventListener('keyup', listenOnKeyPress);
    }
    return () => {
      document.removeEventListener('keyup', listenOnKeyPress);
    };
  }, [onConfirm, show]);

  if (!show) {
    return null;
  }

  return (
    <div className="confirmation-dialog">
      <Modal
        onClose={() => onDecline?.()}
        show={show}
        cssClass="confirm-modal"
        noCloseIcon={true}
      >
        <Title size="small">{titleText}</Title>
        {content ? <div className="confirmation-content">{content}</div> : null}
        <div className="confirmation-buttons">
          {typeof buttonsOverride === 'function' ? (
            buttonsOverride(props)
          ) : (
            <>
              <Button
                onClick={() => {
                  onConfirm?.();
                }}
              >
                {buttonConfirmText}
              </Button>
              <Button
                onClick={() => {
                  onDecline?.();
                }}
              >
                {buttonDeclineText}
              </Button>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}
