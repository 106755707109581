import './App.css';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Calendar from './pages/Calendar';
import Header from './components/Header/Header';
import Admin from './pages/Admin/Admin';
import Activate from './pages/Login/Activate';
import { useEffect, useState } from 'react';
import { refreshToken } from './features/account/accountSlice';
import { setLanguage, getStoredLanguage } from './features/i18n/i18nSlice';
import Playground from './pages/Playground';
import MyAppointments from './pages/MyAppointments';
import StaticPage from './pages/Static';
import ErrorDialog from './components/ErrorDialog';
import Footer from './components/Footer/';
import ReportingPage from './pages/Reporting';
import PWABanner from './components/PWABanner';
import ToastNotifications from './components/ToastNotifications';
import ConfirmationDialog from './components/ConfirmationDialog/ConfirmationDialog';

function App() {
  const account = useAppSelector((state) => state.account);
  const dispatch = useAppDispatch();
  const [isLogged, setIsLogged] = useState(!!account.authToken);
  const isAdmin = account?.userData?.roles?.find((role) => role.id === 1);

  useEffect(() => {
    if (!account.authToken) {
      setIsLogged(false);
    } else {
      setIsLogged(true);

      // ensure the UI uses the language, set in the logged in user settings ..
      dispatch(setLanguage(getStoredLanguage()));

      dispatch(refreshToken());
    }
  }, [account.authToken, dispatch]);

  const getCommonRoutes = () => (
    <>
      <Route path="/privacy" element={<StaticPage pageKey="privacy" />} />
      <Route path="/contacts" element={<StaticPage pageKey="contacts" />} />
    </>
  );

  return (
    <>
      <div className="App flex-col">
        <ErrorDialog />
        <ConfirmationDialog>
          <ToastNotifications position="top">
            <Router>
              {isLogged && (
                <>
                  <Header />
                  <div className="page-container">
                    <Routes>
                      <Route path="/" element={<Navigate to="/calendar" />} />
                      <Route path="/calendar" element={<Calendar />} />
                      <Route path="/playground" element={<Playground />} />
                      <Route path="/my-appointments" element={<MyAppointments />} />

                      {isAdmin ? <Route path="/admin" element={<Admin />} /> : null}
                      {isAdmin ? <Route path="/report" element={<ReportingPage />} /> : null}

                      {getCommonRoutes()}

                      <Route path="/*" element={<Navigate to="/" />} />
                    </Routes>
                  </div>
                </>
              )}
              {!isLogged && (
                <Routes>
                  <Route path="/activate/:userId" element={<Activate />} />
                  {getCommonRoutes()}
                  <Route path="*" element={<Login />} />
                </Routes>
              )}
              <Footer isLogged={isLogged} />
              <PWABanner />
            </Router>
          </ToastNotifications>
        </ConfirmationDialog>
      </div>
    </>
  );
}

export default App;
