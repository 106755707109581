import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ReactComponent as HeaderLogo } from '../../img/Logo_MEDA_bold.svg';
import { ReactComponent as LogoutIcon } from '../../img/logout3.svg';
import './Header.scss';
import UserMenu from '../UserMenu';
import { logout } from '../../features/account/accountSlice';
import { useConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';

export default function Header() {
  const account = useAppSelector((state) => state.account);
  const confirm = useConfirmationDialog();

  const { strings } = useAppSelector((state) => state.i18n);

  const dispatch = useAppDispatch();

  return (
    <>
      <div className="header">
        <div className="header-inner">
          <Link to="/" title={strings['home']} className="logo-link">
            <HeaderLogo />
          </Link>
          <div>
            {account?.userData?.displayName && <h1 className="not-mobile">{strings['hello'] + ', ' + account.userData.displayName}</h1>}
            <UserMenu />
            <button
              className="icon-btn not-mobile logout"
              onClick={() => confirm.show({ titleText: strings.areYouSure, onConfirm: () => dispatch(logout()) })}
            >
              <LogoutIcon />
              <span>{strings['logout']}</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
