import dayjs, { Dayjs } from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "../../components/Button";
import DatePicker from "../../components/DatePicker";
import ReportingTotals from "../../components/ReportingTotals";
import StyledTextBox from "../../components/StyledTextBox";
import { Title } from "../../components/Title";
import { getUsers } from "../../features/account/accountSlice";
import {
  ICalEventsReportData,
  ITimeRange,
} from "../../features/calendar/calendarInterfaces";
import {
  clearReportData,
  getCalReportData,
} from "../../features/calendar/calendarSlice";
import {
  prepareDataReporting,
  prepareExcelData,
  ExcelBuffer,
  fieldDisplayHelpers,
} from "../../utils/eventHelpers";
import { saveAs } from "file-saver";
import "./styles.scss";

export type ReportingPageProps = {};

const ReportingPage = (props: ReportingPageProps) => {
  const dispatch = useAppDispatch();
  const strings = useAppSelector((state) => state.i18n.strings);
  const rawData = useAppSelector((state) => state.calendar.reportData);
  const usersData = useAppSelector((state) => state.account.userList);
  const [excelData, setExcelData] = useState<ExcelBuffer | null>(null);
  const [excelFilename, setExcelFileName] = useState("");
  const [range, setRange] = useState<ITimeRange>({
    start: dayjs().startOf("month"),
    end: dayjs().endOf("month"),
  });
  const [startDateAnchor, setStartDateAnchor] = useState<HTMLElement | false>(
    false
  );
  const [endDateAnchor, setEndDateAnchor] = useState<HTMLElement | false>(
    false
  );
  const [breakdown, setBreakdown] = useState<"day" | "week" | "month">("week");

  const report: ICalEventsReportData | null = useMemo(() => {
    if (!rawData?.length || !usersData.length) return null;
    return prepareDataReporting(range, rawData, usersData);
  }, [rawData, usersData]);

  useEffect(() => {
    if (!report) return;
    prepareExcelData(report, strings).then((data) => {
      const filename = `Report ${dayjs(report.period.start).format(
        "DD.MM.YYYY"
      )} - ${dayjs(report.period.end).format("DD.MM.YYYY")}`;
      setExcelData(data);
      setExcelFileName(`${filename}.xlsx`);
    });
  }, [report]);

  useEffect(
    () => () => {
      dispatch(clearReportData());
    },
    []
  );

  const dateChangeHandler = (date: Dayjs, field: "start" | "end") => {
    const dateItem =
      field === "start" ? date.startOf("date") : date.endOf("date");
    setRange({
      ...range,
      [field]: dateItem,
    });
    setEndDateAnchor(false);
    setStartDateAnchor(false);
  };

  const generateHandler = () => {
    dispatch(getCalReportData(range));
    dispatch(getUsers());
  };

  const displayReport = useCallback(
    (reportData: ICalEventsReportData | null) => {
      if (!reportData) return null;
      return (
        <>
          {excelData && (
            <Button
              kind="standard"
              onClick={() => {
                saveAs(new Blob([excelData]), excelFilename);
              }}
            >
              {strings.reportExportExcel}
            </Button>
          )}
          <ReportingTotals
            title={`${strings.totalsFor} ${dayjs(
              reportData.period.start
            ).format("DD.MMM.YYYY")} - ${dayjs(reportData.period.end).format(
              "DD.MMM.YYYY"
            )}`}
            className={"report-totals-for-period"}
            data={{ totals: { totals: reportData.totals } }}
            captions={[{ key: "totals", display: () => strings.totals }]}
          />
          <hr></hr>
          <ReportingTotals
            title={strings.perUserBreakdown}
            className={"report-totals-for-period"}
            data={reportData.byCustomer}
            captions={fieldDisplayHelpers.customerBreakdownFields(reportData)}
          />
          <hr></hr>
          <div className="report-breakdown-controls">
            <Button
              kind="standard"
              onClick={() => setBreakdown("day")}
              disabled={breakdown === "day"}
            >
              {strings.days}
            </Button>
            <Button
              kind="standard"
              onClick={() => setBreakdown("week")}
              disabled={breakdown === "week"}
            >
              {strings.weeks}
            </Button>
            <Button
              kind="standard"
              onClick={() => setBreakdown("month")}
              disabled={breakdown === "month"}
            >
              {strings.months}
            </Button>
          </div>

          {breakdown === "day" && (
            <ReportingTotals
              title={strings.perDateBreakdown}
              className={"report-totals-for-period"}
              data={reportData.byDate}
              captions={fieldDisplayHelpers.dayBreakdownFields(reportData)}
              filterCols={["avgAmountPerDay", "avgHoursPerDay"]}
            />
          )}
          {breakdown === "week" && (
            <ReportingTotals
              title={strings.perWeekBreakdown}
              className={"report-totals-for-period"}
              data={reportData.byWeek}
              captions={fieldDisplayHelpers.weekBreakdownFields(reportData)}
            />
          )}
          {breakdown === "month" && (
            <ReportingTotals
              title={strings.perMonthBreakdown}
              className={"report-totals-for-period"}
              data={reportData.byMonth}
              captions={fieldDisplayHelpers.monthBreakdownFields(reportData)}
            />
          )}
        </>
      );
    },
    [breakdown, strings, excelData, excelFilename]
  );

  return (
    <div className="report-page-wrapper">
      <Title size={"big"}>{strings.reporting}</Title>
      <div className="report-page-date-range-controls">
        {startDateAnchor && (
          <DatePicker
            date={range?.start}
            anchor={startDateAnchor}
            onDateSelected={(date) => dateChangeHandler(date, "start")}
            onClose={() => setStartDateAnchor(false)}
          />
        )}
        <div className="start-report-date">
          <StyledTextBox
            placeholder={strings.startDate}
            value={range.start.format("DD.MM.YYYY")}
            readOnly
            onFocus={(e) => setStartDateAnchor(e.target)}
          />
        </div>
        <Title size={"small"}>-</Title>
        {endDateAnchor && (
          <DatePicker
            date={range.end}
            anchor={endDateAnchor}
            onDateSelected={(date) => dateChangeHandler(date, "end")}
            onClose={() => setEndDateAnchor(false)}
          />
        )}
        <div className="end-report-date">
          <StyledTextBox
            placeholder={strings.endDate}
            value={range.end.format("DD.MM.YYYY")}
            readOnly
            onFocus={(e) => setEndDateAnchor(e.target)}
          />
        </div>
        <Button kind="standard" onClick={generateHandler}>
          {strings.generate}
        </Button>
      </div>
      <hr color="#0fd8d2" />
      <div className="report-page-content">{displayReport(report)}</div>
    </div>
  );
};

export default ReportingPage;
