import React, { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { LanguageStrings } from "../../features/i18n/i18nInterfaces";
import { Title } from "../Title";
import "./styles.scss";

export type ReportingTotalsProps = {
  title?: string;
  className?: string;
  filterCols?: string[];
  captions: { key: string; display: () => string }[];
  data: { [key: string]: any };
};

type ReportRecordItemProps = {
  caption: string;
  data: {
    [header: string]: string;
  };
  className: string;
  index: number;
  filterCols: string[];
};

const isWrapped = (itemIndex: number, elementsLen: number): boolean => {
  const parent = () =>
    window.document.querySelectorAll(`[data-key="${`r-${itemIndex}`}"]`)[0];
  const elementsCount = elementsLen;
  const elementWidth = 140;
  const captionWidth = 0.2;
  const parentWidth = parent().clientWidth;
  return (
    parentWidth - parentWidth * captionWidth < elementsCount * elementWidth
  );
};

const ReportRecordItem = (props: ReportRecordItemProps) => {
  const { caption, data, filterCols, className, index: itemIndex } = props;
  const strings = useAppSelector((state) => state.i18n.strings);
  const [wrapped, setWrapped] = useState(
    window.document.body.clientWidth < 1440
  );
  const dataElements = useMemo(() => {
    const output: React.ReactElement[] = [];

    Object.keys(data)
      .filter((h) => !filterCols.includes(h))
      .forEach((header, index, arr) => {
        const classFirst = itemIndex < 1 ? "first" : "";
        const headerCell = (
          <div
            className={`record-header-cell ${className} ${classFirst} ${
              wrapped ? "show" : ""
            }`}
          >
            {strings[header as keyof LanguageStrings] || header}
          </div>
        );
        const dataCell = (
          <div className={`record-data-cell ${className}`}>{data[header]}</div>
        );
        const dataItem = (
          <div
            key={`${caption}-col-${index}`}
            className={`record-item-view ${className}`}
          >
            {headerCell}
            {dataCell}
          </div>
        );
        output.push(dataItem);
      });

    return output;
  }, [props.data, wrapped, strings]);

  const resizeHandler = () => {
    if (isWrapped(itemIndex, dataElements.length)) {
      setWrapped(true);
    } else {
      setWrapped(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    setWrapped(isWrapped(itemIndex, dataElements.length));

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return (
    <>
      <div
        key={`${caption}-row-${itemIndex}-caption`}
        className={`record-row-caption ${className} ${
          wrapped ? "wrapped" : ""
        }`}
      >
        {caption}
      </div>
      <div
        key={`${caption}-row-${itemIndex}-data`}
        className={`record-data-row ${className}`}
      >
        {dataElements}
      </div>
    </>
  );
};

const ReportingTotals = (props: ReportingTotalsProps) => {
  const { title, className, filterCols, captions, data } = props;

  return (
    <div
      className={`reporting-totals-wrapper${className ? ` ${className}` : ""}`}
    >
      {title && (
        <Title size={"small"} color={"secondary"}>
          {title}
        </Title>
      )}

      <div>
        {captions.map((caption, index) => (
          <div
            key={`r-${index}`}
            className="reporting-totals-container"
            {...{ "data-key": `r-${index}` }}
          >
            <ReportRecordItem
              className={index % 2 === 0 ? "even" : "odd"}
              index={index}
              caption={caption.display()}
              data={data[caption.key].totals}
              filterCols={filterCols || []}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportingTotals;
