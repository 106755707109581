import React, { useCallback, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { changePassword } from '../../features/account/accountSlice';
import { Button } from '../Button';
import StyledTextBox from '../StyledTextBox';
import { Title } from '../Title';
import './styles.scss';

type ChangePassordFormProps = {
  onSuccess: () => void;
};

const ChangePassordForm = (props: ChangePassordFormProps) => {
  const dispatch = useAppDispatch();
  const errorResult = useAppSelector((state) => state.account.errorMessage);
  const account = useAppSelector((state) => state.account);
  const strings = useAppSelector((state) => state.i18n.strings);
  const form = useRef<HTMLFormElement>(null);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const validateNew = useCallback((newPass: string, confirmPass: string, currentPass: string) => {
    let error = '';
    if (newPass !== confirmPass) {
      error = strings.errorPasswordMatch;
    } else if (newPass === currentPass) {
      error = strings.errorPasswordSame;
    } else {
      error = '';
    }
    setValidationError(error);
    setDisableSubmit(!form.current?.password.value || !form.current?.newPassword.value || !!error);
  }, []);

  const onNewChange = () => {
    validateNew(form.current?.newPassword.value, form.current?.confirmPassword.value, form.current?.password.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const payload = {
      userId: account.userData.id || '',
      oldPassword: form.current?.password.value,
      password: form.current?.newPassword.value,
    };

    await dispatch(changePassword(payload));
    if (!errorResult) {
      props.onSuccess();
    }
  };

  const errorMessage = validationError || errorResult;

  return (
    <div className="change-password-form-wrapper">
      <form className="form" ref={form} onSubmit={handleSubmit} onChange={onNewChange}>
        <div className="form-header">
          <Title size="small">{strings.changePassword} </Title>
        </div>
        <div className="form-content">
          <StyledTextBox type="password" placeholder={strings.currentPassword} name="password" />
          <StyledTextBox type="password" placeholder={strings.newPassword} name="newPassword" />
          <StyledTextBox type="password" placeholder={strings.confirmPassword} name="confirmPassword" />
          <br />
          <span className="error-box">{errorMessage}</span>
          <Button kind="standard" type="submit" disabled={disableSubmit}>
            {strings.change}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassordForm;
