import { useMemo } from 'react';
import { ICalEvent } from '../../features/calendar/calendarInterfaces';
import { getStatus } from '../../utils/eventHelpers';
import { useAppSelector } from "../../app/hooks";
import { isAdmin, isTrainer } from '../../utils/accountHelpers';
import { Status } from '../Status';
import { ActionButton } from '../ActionButton';
import './styles.scss';

interface AppointmentEvent {
  appointment: ICalEvent,
  isNext: boolean,
  onCancelClick: Function,
  onRescheduleClick: Function,
  onApproveClick?: Function,
};

export default function Appointment(props: AppointmentEvent) {

  const { strings } = useAppSelector((state) => state.i18n);
  const account = useAppSelector((state) => state.account);
  const { appointment, isNext, onCancelClick, onRescheduleClick, onApproveClick } = props;
  const { start, end, status } = appointment;

  const isUserAdmin = useMemo(() => {
    return isAdmin(account.userData);
  }, []);
  const isTrainerForEvent = useMemo(() => {
    return isTrainer(account.userData) && appointment.trainer?.id === account.userData.id;
  }, []);

  const userDisplayName = (isUserAdmin || isTrainerForEvent) && appointment.forUser?.displayName;
  const appointmentDate = start.format('DD-MM-YYYY');
  const appointmentIsPending = getStatus(appointment) === 'pending';
  const isNextString = isNext ? ' - (' + strings.next + ')' : '';

  const startTime = start.format('hh-mm');
  const endTime = end.format('hh-mm');

  const nextAppointmentIdObj = isNext ? {id: "next-appointment"} : {};

  return (
    <div className="one-appointment" {...nextAppointmentIdObj}>
      <div className="title-line">{appointmentDate} {userDisplayName ?  ' - ' + userDisplayName : ''} {isNextString}</div>
      <div className="main-section">
        <div className="time-and-status">
          <div className="start-and-end-times">
            <b>{startTime}</b>
            <b>{endTime}</b>
          </div>
          <div className="status">
            <Status setAs={getStatus(status.id)} />
          </div>
        </div>
        <div className="appointment-actions">
          {!isUserAdmin && !isTrainerForEvent && <>
            <ActionButton action="edit" onClick={() => onRescheduleClick(appointment)}>
              {strings['reschedule']}
            </ActionButton>
            {getStatus(appointment) !== 'canceled' && 
              <ActionButton action="delete" onClick={() => onCancelClick(appointment)}>
                {strings['cancel']}
              </ActionButton>
            }
          </>}
          {(isUserAdmin || isTrainerForEvent) && <>
            <ActionButton action="reject" onClick={() => onCancelClick(appointment)}>
              {strings['reject']}
            </ActionButton>
            {appointmentIsPending && (
            <ActionButton action="approve" onClick={() => onApproveClick ? onApproveClick(appointment) : ()=>{}}>
              {strings['approve']}
            </ActionButton>
            )}
          </>}
        </div>
      </div>
    </div>
  );
}
