import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { useState, useEffect, useLayoutEffect } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { ICalEvent, ICalEventResponse } from '../features/calendar/calendarInterfaces';
import type { RootState, AppDispatch } from './store';
import { isJsonString } from '../utils/common';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const calendarSelector = (state: any) => state.calendar.calEvents;

export const calEventsSelector = createSelector(calendarSelector, (calEvents: ICalEventResponse[]) =>
  calEvents.map(
    (ev: ICalEventResponse): ICalEvent => ({
      ...ev,
      start: dayjs(ev.start),
      end: dayjs(ev.end),
      created: ev.created ? dayjs(ev.created) : undefined,
      updated: ev.created ? dayjs(ev.updated) : undefined,
    })
  )
);

export const useLocalStorage = (storageKey: string, fallbackState: any) => {
  const [value, setValue] = useState(() => {
    const retrievedValue = localStorage.getItem(storageKey) || fallbackState;
    if (isJsonString(retrievedValue)) {
      return JSON.parse(retrievedValue);
    }
    return retrievedValue;
  });

  useEffect(() => {
    if (typeof value === 'string') {
      localStorage.setItem(storageKey, value);
    } else {
      localStorage.setItem(storageKey, JSON.stringify(value));
    }
  }, [value, storageKey]);

  return [value, setValue];
};

export const useAnchorElement = (elementRef: React.RefObject<HTMLDivElement>, anchor: HTMLElement | null) => {
  const [elementPos, setElementPos] = useState({ left: 0, top: 0 });
  useLayoutEffect(() => {
    const onResize = () => {
      if (!elementRef.current) return;
      const { left: anchorLeft, bottom: anchorBottom } = anchor?.getBoundingClientRect() || { left: 0, bottom: 0 };
      const elementWidth = elementRef.current.clientWidth;
      const elementHeight = elementRef.current.clientHeight;
      setElementPos({
        left: Math.min(window.innerWidth - elementWidth, anchorLeft),
        top: Math.min(window.innerHeight - elementHeight, anchorBottom),
      });
    };
    onResize();
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [anchor, elementRef]);

  return elementPos;
};
