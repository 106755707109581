import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { STATUS_IDS } from '../../constants/globalConstants';
import { getUser } from '../../features/account/accountSlice';
import { ITimeRange } from '../../features/calendar/calendarInterfaces';
import {
  addCalEvent,
  getCalEvents,
  getWorkingHours,
} from '../../features/calendar/calendarSlice';
import { isAdmin, isTrainer } from '../../utils/accountHelpers';
import { getWeekRange } from '../../utils/dateHelpers';
import { calculatePrice } from '../../utils/eventHelpers';
import {
  validateConflicts,
  validateIsInWorkHours,
  validateIsUserActive,
  validatePriceNumber,
  Validator,
} from '../../utils/validations';
import CalendarEventForm, {
  CalendarFieldErrors,
  formFieldName,
} from '../CalendarEventForm';
import Spinner from '../Spinner';
import { useSearchParams } from 'react-router-dom';

dayjs.extend(duration);

export type CalendarCreateItemProps = {
  timeRange: ITimeRange;
  onSuccess?: (range: ITimeRange) => void;
  duration?: number;
};

const defaultDuration = dayjs.duration({ minutes: 59 });

const CalendarCreateItem = (props: CalendarCreateItemProps) => {
  const dispatch = useAppDispatch();
  const calEvents = useAppSelector((state) => state.calendar.calEvents);
  const selectedTrainerId = useAppSelector(
    (state) => state.calendar.selectedTrainer
  );
  const strings = useAppSelector((state) => state.i18n.strings);
  const loading = useAppSelector(
    (state) => state.calendar.addCalEventStatus === 'pending'
  );
  const [, setSearchParams] = useSearchParams();
  const { timeRange } = props;
  const account = useAppSelector((state) => state.account);
  const [eventStart, setEventStart] = useState(timeRange.start);
  const [eventEnd, setEventEnd] = useState(timeRange.start);
  const [eventForUser, setForUser] = useState(account.userData);
  const [eventDuration, setEventDuration] = useState(defaultDuration);
  const [eventPrice, setEventPrice] = useState(
    eventForUser?.adminFields?.price || '0'
  );
  const [disableNotifications, setDisableNotifications] = useState(false);
  const [fieldErrors, setFieldsErrors] = useState<CalendarFieldErrors>({});
  const [formFields, setFields] = useState<{ [name: string]: any }>({});
  const fieldValidators: { [index: string]: Validator[] } = useMemo(
    () => ({
      start: [
        async (value: Dayjs) => {
          const range = getWeekRange(value);
          setSearchParams({ date: value.format('YYYY-MM-DD') });
          const wh = await dispatch(
            getWorkingHours({
              start: range.start,
              end: range.end,
              trainer: selectedTrainerId,
            })
          ).unwrap();
          return validateIsInWorkHours(value, wh, strings);
        },
        async (value: Dayjs) => {
          const range = getWeekRange(value);
          await dispatch(
            getCalEvents({
              start: range.start,
              end: range.end,
              filters: {
                statusIds: [STATUS_IDS.active, STATUS_IDS.pending],
                trainer: selectedTrainerId,
              },
            })
          );
          return validateConflicts(value, eventEnd, calEvents, strings);
        },
      ],
      end: [
        async (value: Dayjs) => {
          const range = getWeekRange(value);
          setSearchParams({ date: value.format('YYYY-MM-DD') });
          const wh = await dispatch(
            getWorkingHours({
              start: range.start,
              end: range.end,
              trainer: selectedTrainerId,
            })
          ).unwrap();
          return validateIsInWorkHours(value, wh, strings);
        },
        async (value: Dayjs) => {
          const range = getWeekRange(value);
          await dispatch(
            getCalEvents({
              start: range.start,
              end: range.end,
              filters: {
                statusIds: [STATUS_IDS.active, STATUS_IDS.pending],
                trainer: selectedTrainerId,
              },
            })
          );
          return validateConflicts(eventStart, value, calEvents, strings);
        },
      ],
      disableNotifications: [],
      duration: [],
      forUser: [(value) => validateIsUserActive(value, strings)],
      price: [(value) => validatePriceNumber(value, strings)],
    }),
    [eventStart, eventEnd]
  );

  const onChangeHandler = (newFields: typeof formFields) => {
    setEventStart(newFields['start']);
    setDisableNotifications(newFields['disableNotifications']);
    setForUser(newFields['forUser'] || eventForUser);
    setEventDuration(newFields['duration'] || eventDuration);
    setEventPrice(newFields['price']);
  };

  const onSubmitHandler = async (data: { [name: string]: any }) => {
    const { disableNotifications, start, end, oneTimeDisableNotifications } =
      data;
    const payload = {
      oneTimeDisableNotifications,
      disableNotifications,
      start,
      end,
      forUser: eventForUser?.id || '',
      price: isAdmin(account.userData) ? parseFloat(eventPrice) : undefined,
      trainerId: selectedTrainerId,
    };
    await dispatch(addCalEvent(payload));
    props.onSuccess?.(payload);
  };

  const validateField = async (
    fieldName: formFieldName,
    value: any,
    errorOutput: any
  ) => {
    const validators = fieldValidators[fieldName] || [];
    const validated = await Promise.all(
      validators.map((validator) => validator(value))
    );
    const error = validated.reduce((result, valid) => {
      if (result) {
        return result;
      }
      const validationResult = valid;
      if (!validationResult.isValid) {
        return (result = validationResult.errorMessage);
      }
      return result;
    }, '');
    if (error) {
      errorOutput[fieldName] = error;
    } else {
      delete errorOutput[fieldName];
    }
  };

  useEffect(() => {
    setEventEnd(eventStart.add(eventDuration));
  }, [eventStart, eventDuration]);

  useEffect(() => {
    if (eventForUser && eventForUser.id !== account.selectedUser?.id) {
      const userId = eventForUser ? eventForUser.id : account.userData.id;

      dispatch(getUser({ userId: userId || '' }));
    } else {
      if (account.selectedUser) {
        setForUser(account.selectedUser);
        // recalculate price
        const pricePerHour = parseFloat(
          account.selectedUser.adminFields?.price || '0'
        );
        const newPrice = calculatePrice(pricePerHour, eventDuration);
        setEventPrice(newPrice.toFixed(2));
      }
    }
  }, [account.selectedUser, eventForUser, eventDuration]);

  useEffect(() => {
    const output: { [name: string]: any } = {};
    const errorOutput: { [index: string]: string } = {};
    const isUserAdmin = isAdmin(account.userData);
    const isTrainerForEvent =
      isTrainer(account.userData) && selectedTrainerId === account.userData.id;
    output['start'] = eventStart;
    output['end'] = eventEnd;
    output['disableNotifications'] = disableNotifications;

    if (isUserAdmin || isTrainerForEvent) {
      output['forUser'] = eventForUser;
      output['duration'] = eventDuration;
      output['price'] = eventPrice;
    }
    setFields(output);

    const validators = [
      validateField('start', eventStart, errorOutput),
      validateField('end', eventEnd, errorOutput),
      validateField('disableNotifications', disableNotifications, errorOutput),
    ];

    if (isUserAdmin || isTrainerForEvent) {
      validators.push(
        validateField('forUser', eventForUser, errorOutput),
        validateField('duration', eventDuration, errorOutput),
        validateField('price', eventPrice, errorOutput)
      );
    }

    Promise.all(validators).then(() => {
      setFieldsErrors(errorOutput);
    });
  }, [
    eventStart,
    eventEnd,
    eventForUser,
    disableNotifications,
    eventDuration,
    eventPrice,
  ]);

  return (
    <Spinner show={loading} asOverlay={true}>
      <CalendarEventForm
        fields={formFields}
        fieldErrors={fieldErrors}
        onChange={onChangeHandler}
        onSubmit={onSubmitHandler}
      />
    </Spinner>
  );
};

export default CalendarCreateItem;
