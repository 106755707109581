import React, { useMemo } from 'react';
import './styles.scss';
import { ICalEvent } from '../../../features/calendar/calendarInterfaces';
import { useAppSelector } from '../../../app/hooks';
import {
  isTrainer,
  isAdmin as isUserAdmin,
} from '../../../utils/accountHelpers';
import { getStatus, isMine } from '../../../utils/eventHelpers';

export type CalItemProps = {
  event: ICalEvent;
  onClick?: (ev: ICalEvent) => void;
};

const CalItem: React.FC<CalItemProps> = (props: CalItemProps) => {
  const account = useAppSelector((state) => state.account);
  const strings = useAppSelector((state) => state.i18n.strings);
  const { status, start, end, forUser } = props.event;
  const minsOff = start.minute();
  const offSet = (2.5 / 60) * minsOff;
  const isAdmin = useMemo(() => {
    return isUserAdmin(account.userData);
  }, []);
  const isTrainerForEvent = useMemo(() => {
    return (
      isTrainer(account.userData) &&
      props.event.trainer?.id === account.userData.id
    );
  }, []);

  const elementHeight = (2.5 / 60) * end.diff(start, 'minutes');

  let classes: string[] = ['cal-item-wrapper'];
  classes.push(status.name.toLocaleLowerCase());
  let text =
    getStatus(props.event) === 'approved' ? strings.busy : strings.pending;

  if (isMine(props.event, account.userData)) {
    classes.push('mine');
    text =
      getStatus(props.event) === 'approved'
        ? strings.yourBooking
        : strings.yourBookingPending;
  }

  if (isAdmin || isTrainerForEvent) {
    const customer = forUser?.displayName ?? 'Unknown User';
    text = `${customer}\n${start.format('HH:mm')} - ${end.format('HH:mm')}`;
  }

  return (
    <div
      className={classes.join(' ')}
      style={{
        transform: `translateY(${offSet}em)`,
        height: `${elementHeight}em`,
      }}
      onClick={(ev) => {
        ev.stopPropagation();
        props.onClick?.(props.event);
      }}
    >
      {text}
    </div>
  );
};

export default CalItem;
