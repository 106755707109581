import { useState, useEffect } from 'react';
import PopupMenu from './PopupMenu';
import { ReactComponent as UserIcon } from '../../img/profile3.svg';
import { ReactComponent as MyAppointmentsIcon } from '../../img/my-appointments.svg';
import { ReactComponent as CalendarIcon } from '../../img/calendar.svg';
import { ReactComponent as ReportIcon } from '../../img/report.svg';
import { ReactComponent as AdminIcon } from '../../img/admin.svg';
import { ReactComponent as ProfileIcon } from '../../img/profile.svg';
import { ReactComponent as SettingsIcon } from '../../img/settings.svg';
import { ReactComponent as PassChangeIcon } from '../../img/pass-change.svg';
import { ReactComponent as InstallIcon } from '../../img/install.svg';
import { ReactComponent as LogoutIcon } from '../../img/logout3.svg';
import './styles.scss';
import { isAdmin } from '../../utils/accountHelpers';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { logout } from '../../features/account/accountSlice';
import ChangePassordForm from '../ChangePassordForm';
import Modal from '../Modal/Modal';
import Confirm from '../Modal/Confirm';
import CustomFieldsModal from '../CustomFieldsForm/CustomFieldsModal';
import { pwa } from '../../utils/pwa';
import { useConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';

export default function UserMenu() {
  const [menuShow, setMenuShow] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [changePasswordShow, setChangePasswordShow] = useState(false);
  const [settingsShow, setSettingsShow] = useState(false);
  const [logoutShow, setLogoutShow] = useState(false);
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.account);
  const strings = useAppSelector((state) => state.i18n.strings);
  const isUserAdmin = isAdmin(account.userData);
  const confirm = useConfirmationDialog();

  const confirmLogout = () => confirm.show({ titleText: strings.areYouSure, onConfirm: () => dispatch(logout()) });

  useEffect(() => {
    window.addEventListener('show-settings-modal', () => {
      setSettingsShow(true);
    });
  }, []);

  const menuItems = [
    { caption: strings.myAppointments, link: '/my-appointments', icon: MyAppointmentsIcon },
    { caption: strings.calendar, link: '/calendar', icon: CalendarIcon },
    { caption: strings.admin, link: '/admin', icon: AdminIcon, hide: !isUserAdmin },
    { caption: strings.reporting, link: '/report', icon: ReportIcon, hide: !isUserAdmin },
    { caption: strings.profile, action: () => {}, icon: ProfileIcon, hide: true },
    { caption: strings.settings, action: () => setSettingsShow(true), icon: SettingsIcon },
    { caption: strings.changePassword, action: () => setChangePasswordShow(true), icon: PassChangeIcon },
    { caption: strings.install, action: () => pwa.install(), icon: InstallIcon, hide: !pwa.canInstall() },
    { caption: strings.logout, action: confirmLogout, icon: LogoutIcon, iconViewBox: '0 0 500 500', hide: !mobileView },
  ];

  const hideMenu = () => setMenuShow(false);
  const hidePassChange = () => setChangePasswordShow(false);
  const hideSettings = () => setSettingsShow(false);
  const toggleMenu = (asMobile: boolean = false) => {
    setMenuShow(!menuShow);
    setMobileView(asMobile);
  };

  return (
    <div className="user-menu">
      <button className="icon-btn" onClick={() => toggleMenu(false)}>
        <UserIcon viewBox="0 0 96 96" className="user-menu-icon" />
        <span>{strings['profile']}</span>
      </button>
      <button className="hamburger-button mobile-only" onClick={() => toggleMenu(true)}></button>
      <PopupMenu items={menuItems} show={menuShow} onItemClicked={hideMenu} onOutsideClick={hideMenu} mobileView={mobileView} />

      <Modal onClose={hidePassChange} show={changePasswordShow}>
        <ChangePassordForm onSuccess={hidePassChange} />
      </Modal>

      <Confirm
        titleText={strings.areYouSure}
        buttonConfirmText={strings.yes}
        buttonDeclineText={strings.no}
        show={logoutShow}
        onConfirm={() => dispatch(logout())}
        onDecline={() => setLogoutShow(false)}
      />

      <CustomFieldsModal show={settingsShow} onClose={hideSettings} title={strings.settings} />
    </div>
  );
}
