import React, { createRef, useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { IDefaultProps } from '../../definitions/interfaces/componets';
import './style.scss';
import styled from 'styled-components';
import * as constants from '../../constants/styledConstants';

interface CloseButtonProps {
  content: string;
}

export const CloseButton = styled.button<CloseButtonProps>`
  position: absolute;
  top: 5px;
  left: 0;
  min-width: 25px;
  min-height: 25px;
  border: 1px solid #ffa445;
  color: gray;
  border-radius: 50%;
  background-color: white;
  font-size: 1em;
  font-weight: bold;
  transform: translateX(-50%);
  z-index: 1;
  &::after {
    content: 'X';
  }
  &:active {
    background-color: #ffa445;
  }

  ${constants.mediaSmall} {
    position: relative;
    transform: none;
    width: 100%;
    border-radius: 0;
    border: none;
    top: 0;
    visibility: hidden;
    min-height: 1em;
    padding: 10px 0;
    &::after {
      content: '${(props) => `${props.content} >`}';
      visibility: visible;
      display: block;
      position: absolute;
      background-color: white;
      border-bottom: 1px solid #ffa445;
      top: 0;
      left: 0;
      width: 100%;
      padding: 10px 0;
    }
  }
`;

export declare interface SlideInComponentProps extends IDefaultProps {
  backdrop?: boolean;
  closeButton?: boolean;
  expanded?: boolean;
  onClose?: (expanded: boolean) => void;
}

const defaultProps: SlideInComponentProps = {
  backdrop: true,
  closeButton: true,
  expanded: true,
};

const SlideInComponent: React.FC<SlideInComponentProps> = (
  props: SlideInComponentProps
) => {
  const [isHidden, setIsHidden] = useState(true);
  const slideContainer = createRef<HTMLDivElement>();
  const backdrop = createRef<HTMLDivElement>();
  const [closing, setClosing] = useState(false);

  const { strings } = useAppSelector((state) => state.i18n);

  const closeButtonHandler = (ev: any) => {
    if (closing) return;
    setClosing(true);
    slideContainer.current?.classList.remove('show');
    backdrop.current?.classList.remove('show');
    if (props.onClose) {
      props.onClose(false);
    }
  };

  useEffect(() => {
    const onTransitionStart = () => {
      if (props.expanded && backdrop.current) {
        backdrop.current.style.display = 'block';
      }
    };
    const onTransitionEnd = () => {
      if (!props.expanded && backdrop.current) {
        backdrop.current.style.display = 'none';
        setIsHidden(true);
      }
    };

    const container = slideContainer.current;

    container?.addEventListener('transitionend', onTransitionEnd);
    container?.addEventListener('transitionstart', onTransitionStart);

    return () => {
      container?.removeEventListener('transitionend', onTransitionEnd);
      container?.removeEventListener('transitionstart', onTransitionStart);
    };
  }, [backdrop, props.expanded, slideContainer]);

  useEffect(() => {
    if (props.expanded) {
      slideContainer.current?.classList.add('show');
      backdrop.current?.classList.add('show');
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', closeButtonHandler);
      setIsHidden(false);
      setClosing(false);
    } else {
      slideContainer.current?.classList.remove('show');
      backdrop.current?.classList.remove('show');
      window.removeEventListener('popstate', closeButtonHandler);
    }
  }, [props.expanded]);

  return (
    <>
      {props.backdrop && (
        <div
          className="slide-in-backdrop"
          ref={backdrop}
          onClick={closeButtonHandler}
        ></div>
      )}
      <div className="slide-in-container" ref={slideContainer}>
        {!isHidden && (
          <>
            {props.closeButton && (
              <CloseButton
                onClick={closeButtonHandler}
                content={strings['close']}
              />
            )}
            <div className="slide-in-content">{props.children}</div>
          </>
        )}
      </div>
    </>
  );
};

SlideInComponent.defaultProps = defaultProps;

export default SlideInComponent;
