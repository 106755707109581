import * as constants from '../../constants/styledConstants';

export const YearSelectorStyling = `
  .datepicker-years{
    cell-spacing: 0.1em;
    button {
      font-size: inherit;
      width: 5.4em;
      height: 2.6em;
      background-color: white;
      border: none;
      cursor: pointer;
      border-radius: 3px;

      &.selected-year {
        background-color: ${constants.colorPrimary};
        color: white;
      }

      &:hover {
        background-color: ${constants.colorSecondary};
        color: white;
      }
    }
  }  
`;

export default function YearSelector({ workingYear, onYearSelect }: { workingYear: number; onYearSelect: (year: number) => void }) {
  const startYear = workingYear - 10;

  let yearsPerRow = [];
  const years = [];
  for (let i = 1; i <= 24; i++) {
    yearsPerRow.push(startYear + i);

    if (i % 4 === 0) {
      years.push(yearsPerRow);
      yearsPerRow = [];
    }
  }

  return (
    <table className="datepicker-years">
      <tbody>
        {years.map((row) => {
          return (
            <tr>
              {row.map((year) => {
                return (
                  <td>
                    <button className={year === workingYear ? 'selected-year' : ''} onClick={() => onYearSelect(year)}>
                      {year}
                    </button>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
