import TimePicker from '../components/TimePicker';
import DatePicker from '../components/DatePicker';
import dayjs from 'dayjs';
import StyledTextBox from '../components/StyledTextBox';
import { useState } from 'react';
import UserMenu from '../components/UserMenu';
import { useToastNotifications } from '../components/ToastNotifications';

export default function Playground() {
  const [anchor, setAnchor] = useState<HTMLElement | false>(false);
  const [menuShow, setMenuShow] = useState(false);
  const toasts = useToastNotifications();

  return (
    <>
      <StyledTextBox onFocus={(e: any) => setAnchor(e.target)} />
      <br />
      <br />
      {anchor && <DatePicker date={dayjs()} onDateSelected={() => {}} anchor={anchor} onClose={() => {}} />}
      <TimePicker title="From" time={dayjs()} onTimePick={(pickedTime) => alert(pickedTime.format('hh:mm A'))} />
      <TimePicker title="To" time={dayjs()} onTimePick={(pickedTime) => alert(pickedTime.format('hh:mm A'))} />
      <UserMenu />
      <button onClick={() => toasts.pushNew?.('Something, something... dark side... something, something, complete!', 'normal')}>
        Normal Notification
      </button>
      <button onClick={() => toasts.pushNew?.('User created successfully', 'positive')}>Positive Notification</button>
      <button onClick={() => toasts.pushNew?.('User creation failed', 'negative')}>Nagative Notification</button>
    </>
  );
}
