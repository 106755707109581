import React from 'react';
import styled from 'styled-components';
import * as constants from '../../constants/styledConstants';
import { ReactComponent as DelIcon } from '../../img/delete.svg';
import { ReactComponent as RestoreIcon } from '../../img/restore.svg';
import { ReactComponent as EditIcon } from '../../img/edit.svg';
import { ReactComponent as AddIcon } from '../../img/add.svg';
import { ReactComponent as ApproveIcon } from '../../img/approve.svg';
import { ReactComponent as RejectIcon } from '../../img/reject.svg';
import { ReactComponent as ResetPassIcon } from '../../img/reset-pass.svg';

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset';
  kind?: 'icon-only' | 'with-text';
}

type Actions = 'delete' | 'restore' | 'add' | 'edit' | 'approve' | 'reject' | 'resetPass';
interface Props extends React.HTMLProps<HTMLButtonElement> {
  action: Actions;
}

const viewBox = '0 0 210 220';
const icons: { [A in Actions]: JSX.Element } = {
  delete: <DelIcon viewBox={viewBox} />,
  restore: <RestoreIcon viewBox={viewBox} />,
  edit: <EditIcon viewBox={viewBox} />,
  add: <AddIcon viewBox={viewBox} />,
  approve: <ApproveIcon viewBox={viewBox} />,
  reject: <RejectIcon viewBox={viewBox} />,
  resetPass: <ResetPassIcon viewBox={viewBox} />,
};

const withText = `
    border: 2px solid ${constants.colorSecondary};

    &:not([disabled]):hover {
        border-color: ${constants.colorPrimary};
    }
    `;

const iconOnly = `
    border: none;

    vertical-align: middle;
    span {
        display: none;
    }
`;

export const ActionBtn = styled.button<ButtonProps>`
  box-sizing: border-box;
  border-radius: 0.25em;
  padding: 0.25em 0.3em;
  margin: 5px 5px;
  font-size: 1rem;
  font-weight: 700;
  height: 2.5em;
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
  transition: all 150ms linear;
  background-color: transparent;
  margin: 5px 0;
  color: ${constants.colorSecondary};

  &:disabled {
    color: #0000002b;
  }

  span {
    margin-left: 0.5em;
  }

  svg {
    width: 100%;
    height: 100%;
    margin: 2px 5px;

    path {
      fill: ${constants.colorSecondary};
      stroke: ${constants.colorSecondary};
      transition: stroke, fill 150ms linear;
    }
  }

  &:not([disabled]):hover {
    color: ${constants.colorPrimary};

    svg * {
      fill: ${constants.colorPrimary};
      stroke: ${constants.colorPrimary};
    }
  }

  ${(props) => (props.kind === 'icon-only' ? iconOnly : withText)}
`;

export function ActionButton({ action, children, className, onClick }: Props) {
  return (
    <ActionBtn className={className} onClick={onClick} kind={children ? 'with-text' : 'icon-only'}>
      <span>{children}</span>
      {icons[action]}
    </ActionBtn>
  );
}
