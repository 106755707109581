import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as constants from '../../constants/styledConstants';
import { Button } from '../Button';
import StyledTextBox from '../StyledTextBox';
import SlidePicker from './SlidePicker';
import { useAppSelector } from '../../app/hooks';

const TimePickerBackdrop = styled.div`
  position: fixed;
  background: #00000077;
  visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  overflow: auto;

  ${constants.mediaSmall} {
    visibility: visible;
  }
`;

const TimePickerContainer = styled.div`
  position: absolute;
  visibility: visible;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  z-index: 5;

  border-radius: 0.2em;
  width: 10em;
  font-size: min(6vmin, 1.6rem);
  box-shadow: 0 0 5px 1px #00000070;
  overflow: hidden;

  ${constants.mediaSmall} {
    font-size: 1.5rem;
  }

  ${constants.mediaSmall} {
    font-size: 2rem;
    position: fixed;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
  }
`;
const TimePickerHeader = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.1em 15px;
  height: 1.5em;
  font-size: 0.8em;
  z-index: 1;
  background-color: ${constants.colorPrimary};
  border-bottom: 1px solid ${constants.colorPrimary};
  color: white;
`;
const TimePickerFooter = styled.div`
  height: 3em;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 0.7em;

  button {
    width: 4em;
    min-width: max-content;
    height: 2.5em;

    &:last-child {
      border-color: ${constants.colorPrimary};
      background-color: ${constants.colorPrimary};
    }
  }
`;
const TimePickerContent = styled.div`
  display: flex;
`;

const TimePickerTextBox = styled.div`
  width: 8em;
  position: relative;
  display: inline-block;
  cursor: pointer;

  div:first-of-type {
    display: inline-block;
    min-width: 100%;
    width: 100%;
    cursor: pointer;

    input {
      cursor: pointer;
      width: 100%;
      min-width: 100%;
    }
  }

  .timepicker-icon {
    float: right;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 50%;
    right: 0.2em;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;

interface TimePickerProps {
  time: Dayjs;
  title: string;
  format?: '12h' | '24h';
  disabled?: boolean;
  className?: string;
  onTimePick?: (pickedTime: Dayjs) => void;
}
const getListOfHours = (numberOfHours: number, startingHour: number) => {
  const result = [];
  for (let i = 0; i < numberOfHours; i++) {
    let hour = (i + startingHour) % numberOfHours;
    hour = hour === 0 && numberOfHours === 12 ? 12 : hour;
    result.push(hour.toString().padStart(2, '0'));
  }
  return result;
};
const hours12 = getListOfHours(12, 7);
const hours24 = getListOfHours(24, 7);

export default function TimePicker({ time, title, format = '24h', disabled, className, onTimePick }: TimePickerProps) {
  const timeFormat = format === '12h' ? 'hh:mm A' : 'HH:mm';
  const hourFormat = format === '12h' ? 'hh' : 'HH';
  const [hour, setHour] = useState(time.format(hourFormat));
  const [minutes, setMinutes] = useState(time.format('mm'));
  const [ampm, setAmpm] = useState(time.format('A'));
  const [showPicker, setShowPicker] = useState(false);
  const [selectedTime, setSelectedTime] = useState(time);
  const pickerRef = useRef(null);
  const textBoxRef = useRef(null);
  const strings = useAppSelector((state) => state.i18n.strings);

  useEffect(() => {
    setSelectedTime(time);
  }, [time]);

  const timeSelected = () => {
    const timeString = format === '12h' ? `${hour}:${minutes} ${ampm}` : `${hour}:${minutes}`;
    const timeObj = dayjs(timeString, timeFormat).set('date', time.date()).set('month', time.month()).set('year', time.year());
    setSelectedTime(timeObj);
    setShowPicker(false);
    onTimePick?.(timeObj);
  };

  const pickerContainerStyle = {
    top: ((textBoxRef.current as any)?.getBoundingClientRect().height || 0) + 'px',
    left: 0,
    width: format === '12h' ? '10em' : '9em',
  };

  return (
    <div className={className} style={{ position: 'relative' }}>
      <TimePickerTextBox ref={textBoxRef} onClick={() => !disabled && setShowPicker(!showPicker)}>
        <StyledTextBox type="text" placeholder={title} value={selectedTime.format(timeFormat)} readOnly />
        <span className="timepicker-icon">🕒</span>
      </TimePickerTextBox>
      {showPicker && (
        <>
          <TimePickerBackdrop />
          <TimePickerContainer ref={pickerRef} style={pickerContainerStyle} onBlur={() => !disabled && setShowPicker(false)}>
            <TimePickerHeader>{`${hour}:${minutes} ${format === '12h' ? ampm : ''} `}</TimePickerHeader>
            <TimePickerContent>
              <SlidePicker
                title={strings.tpHour}
                values={format === '12h' ? hours12 : hours24}
                defaultValue={selectedTime.format(hourFormat)}
                onValueChange={(v) => setHour(v)}
              />
              <SlidePicker
                title={strings.tpMinutes}
                values={['00', '30']}
                defaultValue={selectedTime.format('mm')}
                onValueChange={(v) => setMinutes(v)}
              />
              {format === '12h' ? (
                <SlidePicker
                  title="AM/PM"
                  values={['AM', 'PM']}
                  defaultValue={selectedTime.format('A')}
                  onValueChange={(v) => setAmpm(v)}
                />
              ) : null}
            </TimePickerContent>
            <TimePickerFooter>
              <Button kind="outlined" onClick={() => setShowPicker(false)}>
                {strings.cancel}
              </Button>
              <Button onClick={timeSelected}>{strings.ok}</Button>
            </TimePickerFooter>
          </TimePickerContainer>
        </>
      )}
    </div>
  );
}
