import dayjs, { Dayjs } from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

const defaultTTL = dayjs.duration({ minutes: 15 });
class CacheService {
  private static instance: CacheService;
  private cacheStore: Map<string, { expires: Dayjs; data: any }>;

  constructor() {
    this.cacheStore = new Map();
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new CacheService();
    }
    return this.instance;
  }

  setCacheItem(key: string, data: any) {
    this.cacheStore.set(key, {
      expires: dayjs().add(defaultTTL),
      data,
    });
  }

  getCacheItem(key: string): any | undefined {
    const cacheItem = this.cacheStore.get(key);
    if (cacheItem && dayjs().isAfter(cacheItem.expires)) {
      return undefined;
    }
    return cacheItem?.data;
  }

  evictCacheItem(key: string): void {
    this.cacheStore.delete(key);
  }

  flush(): void {
    this.cacheStore = new Map();
  }
}

export default CacheService;
