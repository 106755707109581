import dayjs from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import { STATUS_IDS } from '../constants/globalConstants';
import { UserData } from '../features/account/accountInterfaces';
import Excel from 'exceljs';
import {
  ICalEvent,
  ICalEventResponse,
  ICalEventsReportData,
  IReportData,
  IReportDataUser,
  IReportTotals,
  ITimeRange,
} from '../features/calendar/calendarInterfaces';
import {
  getDurationInDays,
  getDurationInHours,
  getSplitRange,
} from './dateHelpers';
import { LanguageStrings } from '../features/i18n/i18nInterfaces';
import {
  reportEvenRowStyle,
  reportHeaderRowStyle,
  reportOddRowStyle,
} from '../constants/styledConstants';

export const isMine = (event: ICalEvent, userData: UserData): boolean => {
  return event.forUser?.id === userData.id;
};

export const isTrainerFor = (event: ICalEvent, userData: UserData): boolean => {
  return event.trainer?.id === userData.id;
};

export const getStatus = (
  event: ICalEvent | number
): 'approved' | 'canceled' | 'pending' => {
  const id = typeof event === 'number' ? event : event.status.id;
  switch (id) {
    case 1:
      return 'pending';
    case 2:
      return 'approved';
    case 3:
      return 'canceled';
    default:
      return 'pending';
  }
};

export const calculatePrice = (
  pricePerHour: number,
  duration: Duration
): number => {
  // split duration in 30min parts
  const durationParts = Math.ceil(duration.asMinutes() / 30);
  const pricePerPart = pricePerHour / 2;
  // round to second decimal place
  return Math.round(durationParts * pricePerPart * 100) / 100;
};

const dayBreakdownFields = (report: ICalEventsReportData) =>
  Object.keys(report.byDate)
    .sort((a, b) => (dayjs(a).isBefore(b) ? -1 : 1))
    .map((d) => ({
      key: d,
      display: () => dayjs(d).format('DD.MM.YYYY'),
    }));
const weekBreakdownFields = (report: ICalEventsReportData) =>
  Object.keys(report.byWeek)
    .sort((a, b) => (dayjs(a.split('|')[0]).isBefore(b.split('|')[0]) ? -1 : 1))
    .map((w) => ({
      key: w,
      display: () => {
        const splitDates = w.split('|');
        return `${dayjs(splitDates[0]).format('DD.MM.YYYY')} - ${dayjs(
          splitDates[1]
        ).format('DD.MM.YYYY')}`;
      },
    }));

const monthBreakdownFields = (report: ICalEventsReportData) =>
  Object.keys(report.byMonth)
    .sort((a, b) => (dayjs(a.split('|')[0]).isBefore(b.split('|')[0]) ? -1 : 1))
    .map((m) => ({
      key: m,
      display: () => dayjs(m.split('|')[0]).format('MMM.YYYY'),
    }));
const customerBreakdownFields = (report: ICalEventsReportData) =>
  Object.keys(report.byCustomer)
    .map((r) => ({
      key: r,
      display: () => report.byCustomer[r].userData?.displayName || 'unknown',
    }))
    .sort((a, b) => a.display().localeCompare(b.display()));

export const fieldDisplayHelpers = {
  dayBreakdownFields,
  weekBreakdownFields,
  monthBreakdownFields,
  customerBreakdownFields,
};

const getTotals = (
  range: ITimeRange,
  items: ICalEventResponse[]
): IReportTotals => {
  const reqStart = dayjs(range.start);
  const reqEnd = dayjs(range.end);
  const durationInDays = getDurationInDays(reqStart, reqEnd);
  const totals: IReportTotals = {
    totalHours: 0,
    totalAmount: 0,
    avgAmountPerDay: 0,
    avgHoursPerDay: 0,
    totalHoursDisabled: 0,
    totalAmountDisabeled: 0,
    totalHoursPending: 0,
    totalAmountPending: 0,
  };

  items.forEach((calItem, index) => {
    const evStart = dayjs(calItem.start);
    const evEnd = dayjs(calItem.end);
    if (calItem.status.id === STATUS_IDS.active) {
      totals.totalHours += getDurationInHours(evStart, evEnd);
      totals.totalAmount += calItem.price || 0;
    } else if (calItem.status.id === STATUS_IDS.disabled) {
      totals.totalHoursDisabled += getDurationInHours(evStart, evEnd);
      totals.totalAmountDisabeled += calItem.price || 0;
    } else if (calItem.status.id === STATUS_IDS.pending) {
      totals.totalHoursPending += getDurationInHours(evStart, evEnd);
      totals.totalAmountPending += calItem.price || 0;
    }
    totals.avgAmountPerDay = totals.totalAmount / durationInDays;
    totals.avgHoursPerDay = totals.totalHours / durationInDays;
  });

  return {
    totalHours: Math.round(totals.totalHours * 100) / 100,
    totalAmount: Math.round(totals.totalAmount * 100) / 100,
    avgAmountPerDay: Math.round(totals.avgAmountPerDay * 100) / 100,
    avgHoursPerDay: Math.round(totals.avgHoursPerDay * 100) / 100,
    totalHoursDisabled: Math.round(totals.totalHoursDisabled * 100) / 100,
    totalAmountDisabeled: Math.round(totals.totalAmountDisabeled * 100) / 100,
    totalHoursPending: Math.round(totals.totalHoursPending * 100) / 100,
    totalAmountPending: Math.round(totals.totalAmountPending * 100) / 100,
  };
};

const getTotalsByCustomer = (
  range: ITimeRange,
  items: ICalEventResponse[],
  usersData: UserData[]
): { [userId: string]: IReportDataUser } => {
  const customerMap = items.reduce<{ [userId: string]: ICalEventResponse[] }>(
    (map, item) => {
      const userId = item.forUser?.id || 'invalid';
      if (!map[userId]) {
        map[userId] = [];
      }
      map[userId].push(item);
      return map;
    },
    {}
  );

  const output: {
    [userId: string]: { userData: UserData | undefined } & IReportData;
  } = {};

  for (const userId in customerMap) {
    const items = customerMap[userId];
    output[userId] = {
      userData: usersData.find((data) => data.id === userId),
      rawData: items,
      totals: getTotals(range, items),
    };
  }

  return output;
};

const getTotalsByDate = (
  range: ITimeRange,
  items: ICalEventResponse[]
): { [dateKey: string]: IReportData } => {
  const dateMap = items.reduce<{ [dateKey: string]: ICalEventResponse[] }>(
    (map, item) => {
      const dateKey = dayjs(item.start).startOf('date').toISOString();
      if (!map[dateKey]) {
        map[dateKey] = [];
      }
      map[dateKey].push(item);
      return map;
    },
    {}
  );

  const output: { [dateKey: string]: IReportData } = {};

  for (const dateKey in dateMap) {
    const items = dateMap[dateKey];
    output[dateKey] = {
      rawData: items,
      totals: getTotals(range, items),
    };
  }

  return output;
};

const getTotalsByWeek = (
  range: ITimeRange,
  items: ICalEventResponse[]
): { [dateKey: string]: IReportData } => {
  const output: { [dateKey: string]: IReportData } = {};
  // split the range in week time ranges
  const weekRanges = getSplitRange(range, 'isoWeek');

  weekRanges.forEach((week) => {
    const key = `${week.start.toISOString()}|${week.end.toISOString()}`;
    const rawData = items.filter((item) =>
      dayjs(item.start).isBetween(week.start, week.end)
    );
    const totals = getTotals(week, rawData);
    output[key] = { rawData, totals };
  });

  return output;
};

const getTotalsByMonth = (
  range: ITimeRange,
  items: ICalEventResponse[]
): { [dateKey: string]: IReportData } => {
  const output: { [dateKey: string]: IReportData } = {};
  // split the range in month time ranges
  const monthRanges = getSplitRange(range, 'month');

  monthRanges.forEach((month) => {
    const key = `${month.start.toISOString()}|${month.end.toISOString()}`;
    const rawData = items.filter((item) =>
      dayjs(item.start).isBetween(month.start, month.end)
    );
    const totals = getTotals(month, rawData);
    output[key] = { rawData, totals };
  });

  return output;
};

export const prepareDataReporting = (
  reqPeriod: ITimeRange,
  rawData: ICalEventResponse[],
  usersData: UserData[]
): ICalEventsReportData => {
  return {
    rawData,
    byCustomer: getTotalsByCustomer(reqPeriod, rawData, usersData),
    byDate: getTotalsByDate(reqPeriod, rawData),
    byWeek: getTotalsByWeek(reqPeriod, rawData),
    byMonth: getTotalsByMonth(reqPeriod, rawData),
    totals: getTotals(reqPeriod, rawData),
    period: {
      start: reqPeriod.start.toISOString(),
      end: reqPeriod.end.toISOString(),
    },
  };
};

export interface ExcelBuffer extends Excel.Buffer {}

export const prepareExcelData = (
  data: ICalEventsReportData,
  strings: LanguageStrings
): Promise<Excel.Buffer> => {
  const wb = new Excel.Workbook();
  createSheet(
    wb,
    strings.totals,
    {
      totals: {
        totals: data.totals,
        rawData: data.rawData,
      },
    },
    [{ key: 'totals', display: () => strings.totals }],
    strings
  );
  createSheet(
    wb,
    strings.perUserBreakdown,
    data.byCustomer,
    fieldDisplayHelpers.customerBreakdownFields(data),
    strings
  );
  createSheet(
    wb,
    strings.perDateBreakdown,
    data.byDate,
    fieldDisplayHelpers.dayBreakdownFields(data),
    strings
  );
  createSheet(
    wb,
    strings.perWeekBreakdown,
    data.byWeek,
    fieldDisplayHelpers.weekBreakdownFields(data),
    strings
  );
  createSheet(
    wb,
    strings.perMonthBreakdown,
    data.byMonth,
    fieldDisplayHelpers.monthBreakdownFields(data),
    strings
  );
  return wb.xlsx.writeBuffer();
};

const createSheet = (
  workbook: Excel.Workbook,
  name: string,
  data: {
    [dateKey: string]: IReportData;
  },
  captions: { key: string; display: () => string }[],
  strings: LanguageStrings
) => {
  const colKeys: (keyof LanguageStrings)[] = [
    'totalHours',
    'totalAmount',
    'avgHoursPerDay',
    'avgAmountPerDay',
    'totalHoursDisabled',
    'totalAmountDisabeled',
    'totalHoursPending',
    'totalAmountPending',
  ];
  const sheet = workbook.addWorksheet(name);
  sheet.columns = [
    { key: 'caption', width: 32 },
    ...colKeys.map((k) => ({
      key: k,
      header: strings[k],
      width: 20,
    })),
  ];

  const firstRow = sheet.getRow(1);
  firstRow.fill = reportHeaderRowStyle.fill;
  firstRow.alignment = reportHeaderRowStyle.alignment;
  firstRow.border = reportHeaderRowStyle.border;
  firstRow.height = reportHeaderRowStyle.height;

  captions.forEach((item, index) => {
    const row = sheet.addRow({
      ...data[item.key].totals,
      caption: item.display(),
    });
    const rowNumber = row.number;

    if (rowNumber % 2 === 0) {
      row.model = { ...row.model, ...reportEvenRowStyle };
    } else {
      row.model = { ...row.model, ...reportOddRowStyle };
    }
  });

  sheet.autoFilter = {
    from: 'A1',
    to: 'A1000',
  };
};
