import { UserData } from '../features/account/accountInterfaces';

export const isAdmin = (userData: UserData): boolean => {
  return !!userData?.roles?.find((role) => role.id === 1);
};

export const isChangePasswordAvailable = (userData: UserData): boolean => {
  return userData?.authType === 'local';
};

export const getStatus = (
  info: UserData | number
): 'active' | 'disabled' | 'pending' => {
  const id = typeof info === 'number' ? info : info.status?.id;
  switch (id) {
    case 1:
      return 'pending';
    case 2:
      return 'active';
    case 3:
      return 'disabled';
    default:
      return 'pending';
  }
};

export function isTrainer(userData: UserData): boolean {
  return !!userData?.roles?.find((role) => role.name === 'TRAINER');
}
