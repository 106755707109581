import React, { useCallback, useState } from 'react';
import './styles.scss';
import {
  WorkingDay,
  WorkingRange,
  workingDays,
} from '../../features/account/accountInterfaces';
import Checkbox from '../Checkbox';
import TimePicker from '../TimePicker';
import {
  defaultWorkingRange,
  getTimeFromString,
  isDefaultWorkingRange,
} from '../../utils/dateHelpers';
import { useAppSelector } from '../../app/hooks';

const initialWorkingRange: WorkingRange = {
  startTime: '08:00:00',
  endTime: '17:00:00',
  timezone: 'Europe/Sofia',
};

export type WorkingTimeComponentProps = {
  workingHours: Partial<Record<WorkingDay, WorkingRange>>;
  onChange: (workingHours: WorkingTimeComponentProps['workingHours']) => void;
};

export default function WorkingTimeComponent(props: WorkingTimeComponentProps) {
  const [workingHours, setWorkingHours] = useState(props.workingHours);
  const strings = useAppSelector((state) => state.i18n.strings);

  const changeHandler = (day: WorkingDay, range: WorkingRange) => {
    setWorkingHours({ ...workingHours, [day]: range });
    props.onChange({ ...workingHours, [day]: range });
  };

  const isDefaultRange = useCallback(isDefaultWorkingRange, []);

  return (
    <div className="working-time-component">
      <h4>{strings.working_hours_title}</h4>
      <div className="working-time-component-working-days">
        <ul className="working-time-component-days-list">
          {workingDays.map((day) => {
            let wh = workingHours[day];
            if (!wh) {
              wh = defaultWorkingRange;
            }
            const enabled = !!wh && !isDefaultRange(wh);
            let start = enabled
              ? getTimeFromString(initialWorkingRange.startTime)
              : getTimeFromString(defaultWorkingRange.startTime);
            if (wh.startTime) {
              start = getTimeFromString(wh.startTime);
            }
            let end = enabled
              ? getTimeFromString(initialWorkingRange.endTime)
              : getTimeFromString(defaultWorkingRange.endTime);
            if (wh.endTime) {
              end = getTimeFromString(wh.endTime);
            }

            return (
              <li className="working-time-component__working-day" key={day}>
                <span>{strings[day]}</span>
                <Checkbox
                  checked={enabled}
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      changeHandler(day, initialWorkingRange);
                    } else {
                      changeHandler(day, defaultWorkingRange);
                    }
                  }}
                />
                <TimePicker
                  time={start}
                  title={strings.from}
                  onTimePick={(time) => {
                    const timeStr = `${time.format('HH:mm:ss')}`;
                    setWorkingHours({
                      ...workingHours,
                      [day]: { ...wh, startTime: timeStr },
                    });
                    changeHandler(day, {
                      ...(wh ?? defaultWorkingRange),
                      startTime: timeStr,
                    });
                  }}
                  disabled={!enabled}
                  className={enabled ? '' : 'disabled'}
                />{' '}
                -{' '}
                <TimePicker
                  time={end}
                  title={strings.to}
                  onTimePick={(time) => {
                    const timeStr = `${time.format('HH:mm:ss')}`;
                    setWorkingHours({
                      ...workingHours,
                      [day]: { ...wh, endTime: timeStr },
                    });
                    changeHandler(day, {
                      ...(wh ?? defaultWorkingRange),
                      endTime: timeStr,
                    });
                  }}
                  className={enabled ? '' : 'disabled'}
                  disabled={!enabled}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
