import { Row, RowModel } from "exceljs";
export const colorPrimary = "#0fd8d2";
export const colorSecondary = "#ffa445";
export const colorDanger = "rgb(173, 76, 76)";

export const colorApproved = "#dfedd6";
export const colorPending = "#fefac0";
export const colorCanceled = "#ffe2d8";

export const mediaLarge = "@media(max-width: 768px)";
export const mediaSmall = "@media(max-width: 500px)";

export const reportHeaderRowStyle: Pick<
  Row,
  "height" | "fill" | "border" | "alignment"
> = {
  height: 30,
  fill: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FF0FD8D2" },
  },
  border: {
    top: { style: "thin", color: { argb: "FFF0F0F0" } },
    left: { style: "thin", color: { argb: "FFF0F0F0" } },
    bottom: { style: "thin", color: { argb: "FF828282" } },
    right: { style: "thin", color: { argb: "FFF0F0F0" } },
  },
  alignment: { horizontal: "center", vertical: "middle" },
};

export const reportEvenRowStyle: Pick<RowModel, "style"> = {
  style: {
    alignment: { horizontal: "center", vertical: "middle" },
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFFFFFF" },
    },
    border: {
      left: { style: "thin", color: { argb: "FF828282" } },
      right: { style: "thin", color: { argb: "FF828282" } },
    },
  },
};

export const reportOddRowStyle: Pick<RowModel, "style"> = {
  style: {
    alignment: { horizontal: "center", vertical: "middle" },
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFE4E4E4" },
    },
    border: {
      left: { style: "thin", color: { argb: "FF828282" } },
      right: { style: "thin", color: { argb: "FF828282" } },
    },
  },
};
