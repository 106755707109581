import platform from 'platform';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

interface InstallDetail {
  status?: null | 'initiated' | 'installed';
}

declare global {
  interface PWAInstallEvent extends CustomEvent {
    detail: InstallDetail;
  }
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
    install: PWAInstallEvent;
  }
}

type Environment = 'Unsupported' | 'Android' | 'iOS';

let deferredInstall: BeforeInstallPromptEvent | null = null;

const environment = (): Environment => {
  if (platform.os?.family === 'iOS' && platform.name === 'Safari') {
    return 'iOS';
  }
  if (platform.os?.family === 'Android') {
    return 'Android';
  }
  return 'Unsupported';
};

const isInstalled = () => {
  if (environment() === 'iOS') {
    return (navigator as any)?.standalone; //Could extend the navigator type, but standalone only exists in Safari, so we'll go with "any"
  }

  //Just to make sure we don't do prompts and checks in Chromium browsers on iOS.
  //Could change in the future to prompt users to use Safari to install
  //Also this disables desktop prompts, which is what we agreed on for now.
  if (environment() === 'Android') {
    return !deferredInstall;
  }

  return false;
};

const canInstall = () => {
  if (environment() === 'Unsupported' || isInstalled()) return false;

  return true;
};

const onInstall: PWAInstallEvent = new CustomEvent('install', { detail: { status: null } });

const init = (cb: Function) => {
  window.addEventListener('beforeinstallprompt', (event) => {
    event.preventDefault();
    deferredInstall = event;

    cb();
  });
};

const install = () => {
  onInstall.detail.status = 'initiated';
  window.dispatchEvent(onInstall);

  if (environment() === 'iOS') {
    return;
  }
  deferredInstall?.userChoice.then((choice) => {
    //making sure the app knows it's been installed so it can remove the Install Menu Item and/or prompts
    if (choice.outcome === 'accepted') {
      deferredInstall = null;
    }
    onInstall.detail.status = 'installed';
    window.dispatchEvent(onInstall);
  });
  deferredInstall?.prompt();
};

export const pwa = {
  init,
  canInstall,
  install,
  environment,
};
