import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import {
  ITimeRange,
  IWorkingHoursData,
} from '../features/calendar/calendarInterfaces';
import bg from 'dayjs/locale/bg';
import en from 'dayjs/locale/en';
import {
  WorkingHours,
  WorkingRange,
} from '../features/account/accountInterfaces';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);
dayjs.extend(duration);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(localeData);

//Making sure that whenever we use the format function we output in the correct locale.
//This is needed because dayjs' global locale does not affect the already created instances
dayjs.prototype.baseFormat = dayjs.prototype.format;
dayjs.prototype.format = function (template?: string | undefined) {
  return this.locale(dayjs.locale()).baseFormat(template);
};

export const setDatesLanguage = async (language: string) => {
  switch (language) {
    case 'bg':
      dayjs.locale({ ...bg });
      break;
    case 'en':
      dayjs.locale({ ...en });
      break;
    default:
      break;
  }
};

export const getCurrentYear = () => {
  return dayjs().year();
};

export const getCurrentWeek = () => {
  return dayjs().isoWeek();
};

export const getWeekRange = (
  date: Dayjs
): ITimeRange => {
  let isoWeek = date;
  let firstDayStart = isoWeek.isoWeekday(1);
  let lastDayEnd = isoWeek.isoWeekday(7);
  firstDayStart = firstDayStart.hour(0).minute(0).second(0).millisecond(0);
  lastDayEnd = lastDayEnd.hour(23).minute(59).second(59).millisecond(0);

  return {
    start: firstDayStart,
    end: lastDayEnd,
  };
};

export const getWorkingHoursForDate = (
  date: Dayjs,
  workingHours: IWorkingHoursData
): ITimeRange | null => {
  const workRange = workingHours[date.format('YYYY-MM-DD')];
  if (workRange) {
    return {
      start: dayjs(workRange.start),
      end: dayjs(workRange.end).subtract(1, 'minute'),
    };
  }
  return null;
};

export const getCurrentWeekDay = (day?: number): number => {
  let output = typeof day !== 'undefined' ? day - 1 : dayjs().isoWeekday() - 1;
  if (output < 0) {
    output = 5;
  }
  return output;
};

export const getPossibleEmptyRange = (
  start: Dayjs,
  end: Dayjs,
  eventsCollection: ITimeRange[],
  dayRange: ITimeRange
): ITimeRange => {
  let output: { start: Dayjs; end: Dayjs } = { start, end };
  // sort the events to be sure
  let events = eventsCollection.slice(0);
  events.sort((a: ITimeRange, b: ITimeRange) => {
    return a.start.diff(b.start);
  });
  // find the nearest event after ours start
  let eventAfterIndex = events.findIndex((ev) => ev.start.isAfter(start));
  if (eventAfterIndex < 0) {
    eventAfterIndex = events.length;
  }
  // we take the two events - the one After and the one before that
  const eventAfter = events[eventAfterIndex];
  const eventBefore = events[eventAfterIndex - 1];

  //handle end
  if (!eventAfter || !eventAfter.start.isSame(start, 'date')) {
    // there is NO next event or it's somewhere in the future
    output.end = dayRange.end;
  } else {
    // we return it's start time - 1 minute
    output.end = eventAfter.start.subtract(dayjs.duration({ minutes: 1 }));
  }

  // handle start
  if (
    eventBefore &&
    eventBefore.end.isAfter(start) &&
    eventBefore.end.isBefore(end)
  ) {
    // the event is overlaping our start
    // we return it's end + 1 minute
    output.start = eventBefore.end.add(dayjs.duration({ minutes: 1 }));
  }

  // this should't happen but just in case
  if (!IsValidRange(output)) {
    return { start, end };
  }

  return output as ITimeRange;
};

export const IsValidRange = (range: ITimeRange): boolean => {
  return range.start.isBefore(range.end);
};

export const copyTimePart = (from: Dayjs, to: Dayjs): Dayjs => {
  return to.hour(from.hour()).minute(from.minute()).second(from.second());
};

export const copyDatePart = (from: Dayjs, to: Dayjs): Dayjs => {
  return to.date(from.date()).month(from.month()).year(from.year());
};

export const isDateInThePast = (date: Dayjs) => {
  return date.isBefore(dayjs());
};

export const getDurationInHours = (start: Dayjs, end: Dayjs): number => {
  const durationInMinutes = dayjs
    .duration(Math.abs(start.diff(end)))
    .asMinutes();
  // get number of rounded 0.5 hour parts
  const durationInParts = Math.ceil(durationInMinutes / 30);
  return durationInParts / 2;
};

export const getDurationInDays = (start: Dayjs, end: Dayjs): number => {
  const durationInDays = dayjs.duration(Math.abs(start.diff(end))).asDays();
  return Math.ceil(durationInDays);
};

export const getSplitRange = (
  range: ITimeRange,
  splitType: 'isoWeek' | 'month'
): ITimeRange[] => {
  const output: ITimeRange[] = [];
  let currentDate = range.start;

  while (currentDate.isBefore(range.end)) {
    let weekStart = currentDate.startOf(splitType);
    let weekEnd = currentDate.endOf(splitType);
    if (weekStart.isBefore(range.start)) {
      weekStart = range.start;
    }
    if (weekEnd.isAfter(range.end)) {
      weekEnd = range.end;
    }
    output.push({ start: weekStart, end: weekEnd });
    currentDate = weekEnd.add(dayjs.duration({ minutes: 5 }));
  }

  return output;
};

export const getWeekDays = (startsFrom: 'monday' | 'sunday') => {
  let weekdays = [];
  const offset = startsFrom === 'sunday' ? 0 : 1;
  for (let i = offset; i < 7 + offset; i++) {
    weekdays.push(dayjs.weekdaysMin()[i % 7]);
  }
  return weekdays;
};

export const getTimeFromString = (time: string): Dayjs => {
  const timeParts = time.split(':');
  return dayjs()
    .hour(+timeParts[0])
    .minute(+timeParts[1])
    .second(+timeParts[2]);
};

export const getTimeStringFromDayjs = (time: Dayjs): string => {
  return time.format('HH:mm:ss');
};

export const defaultWorkingRange: WorkingRange = {
  startTime: '08:00:00',
  endTime: '07:59:00',
  timezone: 'Europe/Sofia',
};

export const isDefaultWorkingRange = (range: WorkingRange) => {
  return (
    range.startTime === defaultWorkingRange.startTime &&
    range.endTime === defaultWorkingRange.endTime &&
    range.timezone === defaultWorkingRange.timezone
  );
};

export const workScheduleValid = (schedule: Partial<WorkingHours>): boolean => {
  for (const key in schedule) {
    if (schedule.hasOwnProperty(key)) {
      const element = schedule[key as keyof WorkingHours];
      if (element && element.startTime && element.endTime) {
        if (isDefaultWorkingRange(element)) {
          continue;
        }
        const start = getTimeFromString(element.startTime);
        const end = getTimeFromString(element.endTime);
        if (start.isAfter(end)) {
          return false;
        }
      }
    }
  }
  return true;
};
