import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import bgStrings from '../../constants/bg.json';
import enStrings from '../../constants/en.json';
import { setDatesLanguage } from '../../utils/dateHelpers';
import { I18nState, AllLanguageStrings, SupportedLanguages } from './i18nInterfaces';

export const possibleLanguages: SupportedLanguages[] = ['en', 'bg'];
export const getStoredLanguage = () => {
  const chosenLang = localStorage.getItem('chosenLang') || 'en';
  return possibleLanguages.includes(chosenLang) ? chosenLang : 'en';
};

const userStoredLanguage = getStoredLanguage();

const allLanguagesStrings: AllLanguageStrings = {
  en: enStrings,
  bg: bgStrings,
};

const currentLanguageStrings = allLanguagesStrings[userStoredLanguage];

const initialState: I18nState = {
  languages: possibleLanguages,
  chosenLang: userStoredLanguage,
  strings: currentLanguageStrings,
};

export const i18nSlice = createSlice({
  name: 'i18n',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<SupportedLanguages>) => {
      const newLanguage = action.payload;
      state.chosenLang = newLanguage;
      state.strings = allLanguagesStrings[newLanguage];
      setDatesLanguage(newLanguage);
      localStorage.setItem('chosenLang', newLanguage);
    },
  },
});

export const { setLanguage } = i18nSlice.actions;

export default i18nSlice.reducer;
