import React from 'react';
import { validators, validationRules } from '../../utils/validations';
import { Button } from '../Button';

import './CustomFieldsForm.scss';
import LanguagePicker from '../LanguagePickerFormField';

/*
 * @param customFields - summary of user fields - and if user is admin - together with admin fields ..
 */
export default function CustomFieldsForm({customFields, onSubmit, strings}: { customFields: {[key:string]: string}, onSubmit: Function, strings: {[key:string]: string} }) {

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form: HTMLFormElement = e.currentTarget;

    let formIsValid = true;
    let inputValues: { [key:string]: string } = {};

    // try to validate the form inputs ..
    for (let i = 0, l = form.elements.length; i < l; i++) {
      const input = form.elements[i] as HTMLInputElement | HTMLButtonElement;
      if (input.tagName !== 'INPUT' || ((input.type === 'radio' || input.type === 'checkbox') && !(input as HTMLInputElement).checked)) {
        continue;
      }

      // check if we have validation rule(s) for this input ..
      var validationRule = (validationRules[input.name] || '').split('\t');
      if (validationRule[0] && validationRule[0] in validators) {

        let inputIsValid = validators[validationRule[0]](input.value.trim(), validationRule[1]);
        const parentParagraph = input.parentElement as HTMLElement;
        const errParagraph = parentParagraph.nextElementSibling as HTMLElement;

        if (!inputIsValid) {
          formIsValid = false;
          errParagraph.style.display = 'block';
        } else {
          errParagraph.style.display = 'none';
        }
      }
      inputValues[input.name] = input.value;
    }

    if (formIsValid) {
      onSubmit(inputValues);
    }

    return false;
  };

  return <form onSubmit={handleFormSubmit} className='custom-fields-form'>
    {Object.keys(customFields).map(oneFieldName => {
      return <React.Fragment  key={'key-' + oneFieldName}>
        <p>
          <label htmlFor={"custom-field-" + oneFieldName}>{strings[oneFieldName]}</label>
          {oneFieldName === 'lang' && 
            <LanguagePicker 
              id={oneFieldName}
              selectedLang={customFields[oneFieldName]}
            />
          }
          {oneFieldName !== 'lang' && <>
          <input name={oneFieldName} type="text" defaultValue={customFields[oneFieldName]} id={"custom-field-" + oneFieldName} />
          </>}
        </p>
        <p className="err">{strings.pleaseEnterValidValue}</p>
      </React.Fragment>;
    })}
    <p>
      <Button type="submit">{strings.save}</Button>
    </p>
  </form>;
}
