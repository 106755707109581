import { useRef, useEffect } from 'react';
import { Title } from '../Title';
import './Modal.scss';

export default function Modal({
  children,
  onClose,
  titleText,
  referenceToCloseModal,
  show,
  styles,
  cssClass,
  noCloseIcon,
}: {
  children?: React.ReactNode;
  onClose: Function;
  titleText?: string;
  referenceToCloseModal?: React.MutableRefObject<Function | null>;
  show?: boolean;
  styles?: Object;
  cssClass?: string;
  noCloseIcon?: boolean;
}) {
  const modalElement = useRef<HTMLDivElement>(null);

  const closeModal = () => {
    if (modalElement.current) {
      modalElement.current.classList.add('hide-modal');
    }
    setTimeout(onClose, 350);
  };

  // we want to give the parent ability to close the modal - i.e. from outside ..
  useEffect(() => {
    if (referenceToCloseModal) {
      referenceToCloseModal.current = closeModal;
    }
  }, [referenceToCloseModal]);

  // we want the user to have ability to close it by pressing ESC ..
  useEffect(() => {

    const listenOnKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && document.activeElement?.tagName !== 'INPUT') {
        closeModal();
      }
    }

    if (show) {
      document.addEventListener('keyup', listenOnKeyPress);
    }
    return () => {
      document.removeEventListener('keyup', listenOnKeyPress);
    }
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <>
      <div className="modal-bg" onClick={closeModal}></div>
      <div className={"modal" + (cssClass ? ' ' + cssClass : '')} ref={modalElement} style={styles}>
        {titleText && <Title size="big">{titleText || 'Modal'}</Title>}
        <div className="modal-content">{children}</div>
        {!noCloseIcon && (
        <button className="close-icon" onClick={closeModal}>
          ✖️
        </button>
        )}
      </div>
    </>
  );
}
