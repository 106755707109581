import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
import { RootState, store } from '../../app/store';
import axios from '../../utils/axios';
import CacheService from '../cache/cacheService';
import {
  AccountState,
  LoginResponse,
  LoginData,
  UserData,
  UserList,
  Fields,
  OAuthProvider,
} from './accountInterfaces';

const initialState: AccountState = {
  authToken: localStorage.getItem('authToken') || '',
  userList: [],
  loginStatus: 'idle',
  addUserStatus: 'idle',
  updateFieldsStatus: 'idle',
  activateStatus: 'idle',
  getUsersStatus: 'idle',
  errorMessage: '',
  selectedUser: null,
  //Now that we decode the token for the use data, we probably don't need to store that data in local storage
  userData: JSON.parse(localStorage.getItem('userData') || '{}'),
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    logout: (state) => {
      state.userData = {};
      state.authToken = '';
      localStorage.removeItem('userData');
      localStorage.removeItem('userFields');
      localStorage.removeItem('adminFields');
      localStorage.removeItem('authToken');
    },
    addUserStatusReset: (state) => {
      state.addUserStatus = 'idle';
    },
    updateFieldsStatusReset: (state) => {
      state.updateFieldsStatus = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loginStatus = 'pending';
        state.errorMessage = '';
      })
      .addCase(
        login.fulfilled,
        (state, action: PayloadAction<LoginResponse>) => {
          state.loginStatus = 'success';
          state.authToken = action.payload.token;
          state.errorMessage = '';
          state.userData = jwtDecode(state.authToken);
          state.userData.userFields = action.payload.userFields;
          state.userData.adminFields = action.payload.adminFields;

          // if language is setting of the user - set it in local storage, so the app starts to use it ..
          if (action.payload.userFields?.lang) {
            localStorage.setItem('chosenLang', action.payload.userFields.lang);
          }

          localStorage.setItem('authToken', state.authToken);
          localStorage.setItem('userData', JSON.stringify(state.userData));
          localStorage.setItem(
            'userFields',
            JSON.stringify(state.userData.userFields)
          );
          localStorage.setItem(
            'adminFields',
            JSON.stringify(state.userData.adminFields)
          );
        }
      )
      .addCase(login.rejected, (state, action: PayloadAction<any>) => {
        state.loginStatus = 'failed';
        // state.error = action.payload;
      })
      .addCase(getUsers.fulfilled, (state, action: PayloadAction<UserList>) => {
        state.getUsersStatus = 'success';
        state.userList = action.payload || [];
      })
      .addCase(getUsers.rejected, (state, action: PayloadAction<any>) => {
        state.getUsersStatus = 'failed';
        //   state.error = action.payload;
      })
      .addCase(getUsers.pending, (state) => {
        state.getUsersStatus = 'pending';
      })
      .addCase(updateFields.pending, (state) => {
        state.updateFieldsStatus = 'pending';
        state.errorMessage = '';
      })
      .addCase(
        updateFields.fulfilled,
        (state, action: PayloadAction<UserData>) => {
          state.updateFieldsStatus = 'success';
          if (action.payload.id !== state.userData.id) return;

          const { userFields } = action.payload;

          state.userData.userFields = userFields;

          // set the language in local storage, so the app remembers it ..
          if (userFields?.lang) {
            localStorage.setItem('chosenLang', userFields.lang);
          }

          localStorage.setItem('userFields', JSON.stringify(userFields));

          // also - update the user data in local storage - so that it contains the newest user data ..
          localStorage.setItem('userData', JSON.stringify(action.payload));
        }
      )
      .addCase(updateFields.rejected, (state, action) => {
        state.updateFieldsStatus = 'failed';
        // state.errorMessage = action.error.message || '';
      })
      .addCase(addUser.fulfilled, (state, action: PayloadAction<UserList>) => {
        state.addUserStatus = 'success';
        state.errorMessage = '';
      })
      .addCase(addUser.pending, (state) => {
        state.addUserStatus = 'pending';
        state.errorMessage = '';
      })
      .addCase(addUser.rejected, (state, action) => {
        state.addUserStatus = 'failed';
        // state.errorMessage = action.error.message || 'Unknown Error!';
      })
      .addCase(activateUser.pending, (state) => {
        state.activateStatus = 'pending';
        state.errorMessage = '';
      })
      .addCase(
        activateUser.fulfilled,
        (state, action: PayloadAction<UserList>) => {
          state.activateStatus = 'success';
          state.errorMessage = '';
        }
      )
      .addCase(activateUser.rejected, (state, action) => {
        state.activateStatus = 'failed';
        // state.errorMessage = action.error.message || 'Unknown Error!';
      })
      .addCase(
        refreshToken.fulfilled,
        (state, action: PayloadAction<LoginResponse>) => {
          if (action.payload) {
            state.authToken = action.payload.token;
          }
        }
      )
      .addCase(getUser.fulfilled, (state, action: PayloadAction<UserData>) => {
        if (action.payload) {
          state.selectedUser = action.payload;
        }
      })
      .addCase(getUser.pending, (state) => {
        state.selectedUser = null;
      })
      .addCase(updateUserStatus.pending, (state) => {
        state.updateFieldsStatus = 'pending';
        state.errorMessage = '';
      })
      .addCase(
        updateUserStatus.fulfilled,
        (state, action: PayloadAction<UserData>) => {
          state.updateFieldsStatus = 'success';
          const index = state.userList.findIndex(
            (u) => u.id === action.payload.id
          );
          const newList = [...state.userList];
          newList[index] = action.payload;
          state.userList = newList;
        }
      )
      .addCase(updateUserStatus.rejected, (state, action) => {
        state.updateFieldsStatus = 'failed';
      });
  },
});

export const selectAccount = (state: RootState) => state.account;

const login = createAsyncThunk(
  'account/login',
  async (loginData: LoginData, { rejectWithValue }) => {
    try {
      CacheService.getInstance().flush();
      const endpoint = '/login';
      const response = await axios.post(endpoint, loginData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Needs testing!
const refreshToken = createAsyncThunk(
  'account/refreshToken',
  async (_, { getState }) => {
    const { account } = getState() as RootState;
    const { iat, exp }: { iat: number; exp: number } = jwtDecode(
      account.authToken
    );
    const totalValidity = dayjs.unix(exp).diff(dayjs.unix(iat), 'days');
    const leftValidity = dayjs.unix(exp).diff(dayjs(), 'days');

    if (leftValidity <= 0) {
      store.dispatch(logout());
    } else if (leftValidity <= totalValidity / 2) {
      const response = await axios.get(`/refreshtoken`);
      return response.data;
    }
  }
);

const updateFields = createAsyncThunk(
  'account/updateFields',
  async ({
    userFields,
    adminFields,
    idOfUser,
  }: {
    userFields: Fields;
    adminFields?: Fields;
    idOfUser: string | undefined;
  }) => {
    const response = await axios.put('/users/' + idOfUser, {
      userFields: userFields,
      ...(adminFields ? { adminFields } : {}),
    });

    return response.data;
  }
);

const updateUser = createAsyncThunk(
  'account/updateFields',
  async (userData: UserData, { rejectWithValue }) => {
    try {
      const response = await axios.put('/users/' + userData.id, {
        ...userData,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const addUser = createAsyncThunk(
  'account/addUser',
  async (userData: UserData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/users', {
        ...userData,
        status: {
          id: 1,
          name: 'PENDING',
        },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const activateUser = createAsyncThunk(
  'account/activate',
  async (
    {
      userId,
      password,
      type,
    }: {
      userId: string | undefined;
      password: string;
      type: 'local' | OAuthProvider;
    },
    { rejectWithValue }
  ) => {
    try {
      if (!userId) {
        throw new Error('Missing userId');
      }
      const response = await axios.post(`/users/${userId}/setpass`, {
        type,
        password,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getUsers = createAsyncThunk(
  'account/getUsers',
  async (_, { rejectWithValue }) => {
    try {
      const cacheService = CacheService.getInstance();
      const path = '/users';
      let promise = cacheService.getCacheItem(path);
      if (!promise) {
        promise = axios.get(path);
        cacheService.setCacheItem(path, promise);
      }
      const response = await promise;
      cacheService.evictCacheItem(path);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getUser = createAsyncThunk(
  'account/getUser',
  async ({ userId }: { userId: string }, { rejectWithValue }) => {
    try {
      const cacheService = CacheService.getInstance();
      const path = `/users/${userId}`;
      let promise = cacheService.getCacheItem(path);
      if (!promise) {
        promise = axios.get(path);
        cacheService.setCacheItem(path, promise);
      }
      const response = await promise;
      cacheService.evictCacheItem(path);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const changePassword = createAsyncThunk(
  'account/changepass',
  async (
    {
      userId,
      oldPassword,
      password,
    }: {
      userId: string;
      oldPassword: string;
      password: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const endpoint = `/users/${userId}/changepass`;
      const response = await axios.post(endpoint, { oldPassword, password });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const updateUserStatus = createAsyncThunk(
  'account/updateStatus',
  async (
    { userId, statusId }: { userId: string; statusId: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put('/users/' + userId, {
        status: {
          id: statusId,
        },
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const resetPassword = createAsyncThunk(
  'account/resetPassword',
  async ({ userId }: { userId: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/users/' + userId + '/resetpass', {});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export {
  login,
  addUser,
  updateUser,
  updateFields,
  getUser,
  getUsers,
  activateUser,
  refreshToken,
  changePassword,
  resetPassword,
  updateUserStatus,
};
export const { logout, addUserStatusReset, updateFieldsStatusReset } =
  accountSlice.actions;

export default accountSlice.reducer;
