import styled from 'styled-components';
import * as constants from '../../constants/styledConstants';

const CheckboxContainer = styled.label`
  display: inline-flex;
  vertical-align: middle;
  height: 2.5em;
  align-items: center;
  font-weight: 500;
`;

const InvisibleCheckBox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  border: none;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  visibility: 'hidden';

  ${InvisibleCheckBox}:checked + & {
    visibility: 'visible';
  }
`;

type iconSizes = 'xs' | 's' | 'm' | 'l' | 'xl';

interface IIconProps {
  $iconSize?: iconSizes;
}

const iconSizeMap: { [key in iconSizes]: string } = {
  xs: '8px',
  s: '16px',
  m: '24px',
  l: '32px',
  xl: '40px',
};

const CheckboxBody = styled.div<IIconProps>`
  display: inline-block;
  border: 2px solid ${constants.colorSecondary};
  border-radius: 3px;
  background-color: white;
  width: ${(props) =>
    props?.$iconSize ? iconSizeMap[props.$iconSize] : iconSizeMap['m']};
  height: ${(props) =>
    props?.$iconSize ? iconSizeMap[props.$iconSize] : iconSizeMap['m']};
  transition: background-color 150ms linear;

  ${InvisibleCheckBox}:checked + & {
    background-color: ${constants.colorSecondary};
    border: none;
  }
`;

export default function Checkbox(
  props: React.HTMLProps<HTMLInputElement> & IIconProps
) {
  const { onChange, className, id, style, $iconSize } = props;
  return (
    <CheckboxContainer className={className} style={style}>
      {/* {label} */}
      <InvisibleCheckBox onChange={onChange} id={id} {...(props as any)} />
      <CheckboxBody $iconSize={$iconSize}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </CheckboxBody>
    </CheckboxContainer>
  );
}
