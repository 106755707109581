import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import axios from '../../utils/axios';
import CacheService from '../cache/cacheService';
import { MetadataState, UserRole } from './metadataInterfaces';

const initialState: MetadataState = {
  userRolesAvailable: [],
  getRolesStatus: 'idle',
  errorMessage: '',
};

export const metadataSlice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.pending, (state) => {
        state.getRolesStatus = 'pending';
        state.errorMessage = '';
      })
      .addCase(
        getRoles.fulfilled,
        (state, action: PayloadAction<UserRole[]>) => {
          state.getRolesStatus = 'success';
          state.errorMessage = '';
          state.userRolesAvailable = action.payload;
        }
      )
      .addCase(getRoles.rejected, (state, action: PayloadAction<any>) => {
        state.getRolesStatus = 'failed';
        // state.error = action.payload;
      });
  },
});

export const selectMetadata = (state: RootState) => state.metadata;

const getRoles = createAsyncThunk(
  'metadata/getRoles',
  async (_: void, { rejectWithValue }) => {
    try {
      CacheService.getInstance().flush();
      const endpoint = '/metadata/roles';
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export { getRoles };

export default metadataSlice.reducer;
