import { createSlice, PayloadAction, isRejected } from '@reduxjs/toolkit';
import { ErrorResponse } from './errorInterfaces';

const UNKNOWN_ERROR = { statusCode: -1, errorCode: 'ERR-0000', errorMessage: 'Unknown error' };

interface ErrorState {
  data: ErrorResponse | null;
}
const initialState: ErrorState = {
  data: null,
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    clearError: (state) => {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isRejected, (state, action: PayloadAction<any>) => {
      if (action.payload && action.payload.errorCode) {
        state.data = action.payload;
      } else {
        state.data = UNKNOWN_ERROR;
      }
    });
  },
});

export const { clearError } = errorSlice.actions;
export default errorSlice.reducer;
