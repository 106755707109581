import { useEffect, useRef } from 'react';

import { Notification } from './NotificationTypes';

interface ToastProps {
  notification: Notification;
  onFaded: (id: string) => void;
}
export default function Toast({ notification, onFaded }: ToastProps) {
  const liRef = useRef<HTMLLIElement>(null);
  const { id, message, type } = notification;

  useEffect(() => {
    const fadeEvent = (e: AnimationEvent) => {
      if (e.animationName === 'fadeOut') {
        onFaded(id);
      }
    };

    const li = liRef.current;

    li?.addEventListener('animationend', fadeEvent);

    return () => li?.removeEventListener('animationend', fadeEvent);
  }, [id, onFaded]);

  const clearToast = () => onFaded(id);

  return (
    <li ref={liRef} className={`toast ${type}`} onClick={clearToast}>
      {message}
    </li>
  );
}
