import dayjs, { Dayjs } from 'dayjs';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import CalendarComponent from '../../components/CalendarComponent';
import CalendarCreateItem from '../../components/CalendarCreateItem';
import CalendarEditItem from '../../components/CalendarEventEditItem';
import CalendarItemView from '../../components/CalendarItemView';
import Modal from '../../components/Modal/Modal';
import SlideInComponent from '../../components/SlideInComponent';
import { useAppSelector } from '../../app/hooks';
import {
  ICalEvent,
  ITimeRange,
} from '../../features/calendar/calendarInterfaces';
import './Calendar.scss';
import Spinner from '../../components/Spinner';

export default function Calendar() {
  const [createData, setCreateData] = useState<ITimeRange | boolean>();
  const [viewData, setViewData] =
    useState<SetStateAction<ICalEvent | boolean>>();
  const [editData, setEditData] = useState<ICalEvent | boolean>(false);
  const [calendarDate, setCalendarDate] = useState<Dayjs>();
  const [searchParams, setSearchParams] = useSearchParams();
  const strings = useAppSelector((state) => state.i18n.strings);
  const loading = useAppSelector(
    (state) => state.calendar.calEventsStatus === 'pending'
  );

  useEffect(() => {
    const date = searchParams.get('date')?.split('T')[0];
    if (!date) {
      setSearchParams({ date: (calendarDate || dayjs()).format('YYYY-MM-DD') });
    } else if (date) {
      setCalendarDate(dayjs(date, 'YYYY-MM-DD'));
    }
  }, [searchParams]);

  const handleCreateData = (range: ITimeRange | boolean) => {
    if (typeof range === 'boolean') {
      onSuccessHandler(createData as ITimeRange, setCreateData);
      return;
    }
    setCreateData(range);
  };

  const handleViewData = useCallback((calEvent: ICalEvent | boolean) => {
    setViewData(calEvent);
  }, []);

  const handleEditData = (event: ICalEvent | boolean) => {
    if (typeof event === 'boolean') {
      onSuccessHandler(editData as ITimeRange, setEditData);
      setViewData(false);
    } else {
      setEditData(event);
    }
    setViewData(false);
  };

  const onSuccessHandler = (
    range: ITimeRange,
    stateFunc: (args: boolean) => void
  ) => {
    stateFunc(false);
    setCalendarDate(range.start);
    setSearchParams({ date: range.start.format('YYYY-MM-DD') });
  };

  if (!calendarDate) {
    return null;
  }

  return (
    <div className="calendar flex-col">
      <div className="calendar-page-wrapper">
        <Spinner show={loading} asOverlay={true}>
          {calendarDate && (
            <CalendarComponent
              onEmptyClick={handleCreateData}
              onEventClick={handleViewData}
              onDayChange={(date) =>
                setSearchParams({ date: date.format('YYYY-MM-DD') })
              }
              date={calendarDate}
            ></CalendarComponent>
          )}
        </Spinner>
      </div>

      <SlideInComponent onClose={handleCreateData} expanded={!!createData}>
        <CalendarCreateItem
          timeRange={createData as ITimeRange}
          onSuccess={(range) => onSuccessHandler(range, setCreateData)}
        />
      </SlideInComponent>

      <SlideInComponent onClose={handleEditData} expanded={!!editData}>
        <CalendarEditItem
          item={editData as ICalEvent}
          onSuccess={(range) => onSuccessHandler(range, setEditData)}
        />
      </SlideInComponent>

      <Modal
        onClose={handleViewData}
        show={!!viewData}
        noCloseIcon={true}
        cssClass="view-cal-item-modal"
      >
        {!!viewData && (
          <CalendarItemView
            item={viewData as ICalEvent}
            onReschedule={handleEditData}
            onReject={(range: ITimeRange) =>
              onSuccessHandler(range, handleViewData)
            }
            onApprove={(range: ITimeRange) =>
              onSuccessHandler(range, handleViewData)
            }
            onCancel={(range: ITimeRange) =>
              onSuccessHandler(range, handleViewData)
            }
          />
        )}
        <Button
          type="button"
          kind="outlined"
          onClick={() => handleViewData(false)}
        >
          {strings.close}
        </Button>
      </Modal>
    </div>
  );
}
