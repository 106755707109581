import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  addUser,
  addUserStatusReset,
  getUsers,
} from '../../features/account/accountSlice';
import './styles.scss';
import { Button } from '../Button';
import { UserData } from '../../features/account/accountInterfaces';
import { flattenObject } from '../../utils/common';
import {
  validationRules,
  validators,
  validationRulesErrorIds,
} from '../../utils/validations';
import { LanguageStrings } from '../../features/i18n/i18nInterfaces';
import Spinner from '../Spinner';
import { useToastNotifications } from '../ToastNotifications';
import BasicInfoTabFragment from './BasicInfoTabFragment';
import TabControls, { Tab } from './TabControls';
import LoginInfoTabFragment from './LoginInfoTabFragment';
import { workScheduleValid } from '../../utils/dateHelpers';

interface CreateUserProps {
  onSuccess: () => void;
}

const tabIds = ['basic', 'login'] as const;
export type TabId = typeof tabIds[number];
const tabs: Tab[] = tabIds.map((id) => ({ id }));

export default function CreateUser({ onSuccess }: CreateUserProps) {
  const strings = useAppSelector((state) => state.i18n.strings);
  const state = useAppSelector((state) => state.account);
  const roles = useAppSelector((state) => state.metadata.userRolesAvailable);
  const newUserForm = useRef<HTMLFormElement>(null);
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState('');
  const [currentTab, setCurrentTab] = useState<TabId>('basic');
  const toasts = useToastNotifications();

  const validatePayload = useCallback((payload: UserData) => {
    if (!workScheduleValid(payload.workingHours || {})) {
      return strings.working_hours_invalid;
    }
    const flat = flattenObject(payload);

    for (const key in flat) {
      var validationRule = (validationRules[key] || '').split('\t');
      if (validationRule[0] && validationRule[0] in validators) {
        let inputIsValid = validators[validationRule[0]](
          flat[key].trim(),
          validationRule[1]
        );
        if (!inputIsValid) {
          const stringId = validationRulesErrorIds[
            key
          ] as keyof LanguageStrings;
          return strings[stringId];
        }
      }
    }
    return '';
  }, []);

  const newUserSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!(e.target instanceof HTMLFormElement)) {
      return;
    }

    const form = e.target;

    let lang: HTMLInputElement | null = null;
    (form.lang as unknown as RadioNodeList).forEach((node) => {
      if ((node as HTMLInputElement).checked) {
        lang = node as HTMLInputElement;
      }
    });

    const selectedRoles = [...roles].filter((role) => {
      const roleInput = form[
        `create-user-role-${role.name}`
      ] as HTMLInputElement;
      return roleInput.checked;
    });

    const payload = {
      email: form.email.value,
      displayName: form.displayName.value,
      adminFields: {
        price: form.price.value,
      },
      userFields: {
        lang: lang ? (lang as HTMLInputElement).value : 'en',
        phone: form.phone.value,
        address: form.address.value,
      },
      roles: selectedRoles,
      trainerId: form.trainerId.value,
    };

    if (selectedRoles.find((role) => role.name === 'TRAINER')) {
      (payload as any)['workingHours'] = JSON.parse(form.workingHours.value);
    }

    const error = validatePayload(payload);

    if (error) {
      setStatus(error);
      return;
    }
    setStatus('');

    dispatch(addUser(payload));
  };

  useEffect(() => {
    if (state.addUserStatus === 'failed') {
      setStatus(state.errorMessage);
    } else if (state.addUserStatus === 'success') {
      newUserForm.current?.reset();
      toasts.pushNew(strings.userCreatedSuccessfully, 'positive');
      setStatus('');
      dispatch(getUsers());
      dispatch(addUserStatusReset());
      onSuccess();
    }
  }, [state.addUserStatus, state.errorMessage]);

  return (
    <Spinner asOverlay={true} show={state.addUserStatus === 'pending'}>
      <form
        className="create-user-container"
        onSubmit={newUserSubmit}
        ref={newUserForm}
      >
        <TabControls
          tabs={tabs}
          onTabClick={(tab) => setCurrentTab(tab.id as TabId)}
          selected={currentTab}
        />
        <div
          style={{ display: currentTab !== 'basic' ? 'none' : 'grid' }}
          className="tab"
        >
          <BasicInfoTabFragment />
        </div>
        <div
          style={{ display: currentTab !== 'login' ? 'none' : 'grid' }}
          className="tab"
        >
          <LoginInfoTabFragment />
        </div>
        <div className="create-user-footer">
          <small className={`${state.addUserStatus}`}>{status}</small>
          <Button type="submit" kind="standard">
            {strings.add}
          </Button>
        </div>
      </form>
    </Spinner>
  );
}
