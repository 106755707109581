import React, { useRef } from 'react';
import './styles.scss';
import Logo from '../../img/Logo_MEDA_bold.svg';
import { login } from '../../features/account/accountSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import StyledTextBox from '../../components/StyledTextBox';
import { Button } from '../../components/Button';
import { Title } from '../../components/Title';
import Spinner from '../../components/Spinner';
import LoginProviders from './LoginProviders';
import LanguagePicker from '../../components/LanguagePickerFormField';
import { setLanguage, getStoredLanguage } from '../../features/i18n/i18nSlice';

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const form = useRef<HTMLFormElement>(null);
  const errorMessage = useAppSelector((state) => state.account.errorMessage);
  const loading = useAppSelector((state) => state.account.loginStatus === 'pending');
  const strings = useAppSelector((state) => state.i18n.strings);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const email = form.current?.email.value;
    const password = form.current?.password.value;
    dispatch(login({ username: email, password, loginType: 'local' }));
  };

  return (
    <div className="login-page-wrapper">
      <Spinner show={loading} asOverlay={true}>
        <div className="login-wrapper">
          <div className="language-picker">
            <LanguagePicker selectedLang={getStoredLanguage()} onChanged={(lang) => dispatch(setLanguage(lang))} />
          </div>
          <div className="login-logo-wrapper">
            <img className="logo" src={Logo} alt=""/>
          </div>
          <div className="login-form-wrapper">
            <form className="form" ref={form} onSubmit={handleSubmit}>
              <div className="form-header">
                <Title size="small">{strings.login.toUpperCase()}</Title>
              </div>
              <div className="form-content">
                <StyledTextBox type="text" placeholder={strings.email} name="email" />
                <StyledTextBox type="password" placeholder={strings.password} name="password" />
                <br />
                <span className="error-box">{errorMessage}</span>
                <Button kind="standard" type="submit">
                  {strings.login}
                </Button>
                <br />
                <Title size="small">{strings.or.toUpperCase()}</Title>
                <br />
                <div className="login-providers">
                  <LoginProviders
                    providers={['google', 'facebook']}
                    page="login"
                    onSuccess={(loginType, token) => dispatch(login({ loginType, token }))}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Spinner>
    </div>
  );
};

export default LoginPage;
