import { Status } from '../../definitions/interfaces/common';
import { UserRole } from '../metadata/metadataInterfaces';
import { SupportedLanguages } from '../i18n/i18nInterfaces';

export interface Fields {
  [key: string]: string;
}

export interface AccountState {
  authToken: string;
  userData: UserData;
  loginStatus: Status;
  addUserStatus: Status;
  updateFieldsStatus: Status;
  activateStatus: Status;
  getUsersStatus: Status;
  errorMessage: string;
  userList: UserList;
  selectedUser: UserData | null;
}

export type OAuthProvider = 'google' | 'facebook';

interface LoginDataLocal {
  loginType: 'local';
  username: string;
  password: string;
}

interface LoginDataOAuth {
  loginType: OAuthProvider;
  token: string;
}

export type LoginData = LoginDataLocal | LoginDataOAuth;

export interface LoginResponse {
  userId: string;
  token: string;
  userFields: UserFields;
  adminFields: Fields;
}

interface AdminFields {
  price?: string;
}

export interface UserFields {
  lang: SupportedLanguages;
  phone: string;
  address?: string;
}

export type WorkingRange = {
  startTime: string;
  endTime: string;
  timezone: string;
};

export const workingDays = [
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
  'sun',
] as const;
export type WorkingDay = typeof workingDays[number];

export type WorkingHours = {
  [key in WorkingDay]: WorkingRange;
};

export interface UserData {
  id?: string;
  authType?: 'null' | 'local' | 'google' | 'facebook';
  displayName?: string;
  email?: string;
  roles?: Array<UserRole>;
  status?: UserStatus;
  adminFields?: AdminFields;
  userFields?: UserFields;
  trainerId?: string;
  trainerDisplayName?: string;
  workingHours?: WorkingHours;
}

export interface UserStatus {
  id: number;
  name: 'PENDING' | 'ACTIVE' | 'DISABLED';
}

export interface UserList extends Array<UserData> {}
