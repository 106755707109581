import React from 'react';
import { possibleLanguages } from '../../features/i18n/i18nSlice';
import './styles.scss';

export type LanguagePickerProps = {
  selectedLang: string;
  id?: string;
  onChanged?: (language: string) => void;
};

const LanguagePicker = function (props: LanguagePickerProps) {
  return (
    <>
      {possibleLanguages.map((onePossibleLanguage) => {
        const radioButtonId = `custom-field-${props.id || 'lang'}-${onePossibleLanguage}`;
        return (
          <React.Fragment key={'key-' + onePossibleLanguage}>
            <input
              key={radioButtonId}
              type="radio"
              name="lang"
              value={onePossibleLanguage}
              defaultChecked={props.selectedLang === onePossibleLanguage}
              id={radioButtonId}
              onChange={() => props.onChanged?.(onePossibleLanguage)}
            />
            <label className={'lang-flag ' + onePossibleLanguage} htmlFor={radioButtonId}>
              {onePossibleLanguage}
            </label>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default LanguagePicker;
